import imagesLoaded from 'imagesloaded';
import FileField from './file-field';
import {isString} from '../utils/types';


class ImagefileField extends FileField {

	constructor({
		root,
		element,
		eventsEnabled = true,
		asyncEvents = true,
		invalidClass = 'invalid',
		progressClass = 'progress',
		emptyClass = 'empty',
		errorsSelector,
		name,
		type,
		errorTpl,
		baseThumbUrl,
		baseMediaUrl = '',
		thumbOptions = [640, 0, 90],
	}) {
		super({
			root: root, element: element,
			eventsEnabled: eventsEnabled, asyncEvents: asyncEvents, invalidClass: invalidClass,
			errorsSelector: errorsSelector, name: name, type: type, errorTpl: errorTpl, baseMediaUrl: baseMediaUrl
		});
		this.baseThumbUrl = baseThumbUrl;
		this.thumbOptions = thumbOptions;
		this.progressClass = progressClass;
		this.emptyClass = emptyClass;
		this.value = {};
	}


	prepare() {
		this.img = this.element.querySelector(this.dataSelector('imagefileImg'));
		this.progressImg = this.element.querySelector(this.dataSelector('imagefileImgProgress'));
		super.prepare();
	}


	processChange(file) {
		const reader = new FileReader();
		reader.onload = (e) => {
			this.img.src = e.target.result;
			this.progressImg.src = e.target.result;
			this.classList(this.element).add(this.progressClass);
		};
		reader.readAsDataURL(file);
	}


	onProgress(event) {
		super.onProgress(event);
		const percent = event.total > 0 ? Math.round(event.loaded / event.total * 100) : 0;
		const width = this.img.getBoundingClientRect().width;
		if (percent > 0 && width > 0) {
			this.progressImg.style.clip = 'rect(auto, ' + width * (percent / 100) + 'px, auto, auto)';
		}
	}


	getThumbUrl(path) {
		const dotPos = path.lastIndexOf('.');
		return this.baseThumbUrl + '/' + path.substr(0, dotPos) + '.' + this.thumbOptions.join('-') + path.substr(dotPos);
	}


	update(value) {
		if (isString(value)) {
			if (!value.length) {
				value = '{}';
			}
			value = JSON.parse(value);
		}
		this.updateInfo(value);
		if (value && 'path' in value) {
			const rect = this.img.getBoundingClientRect();
			this.img.style.width = rect.width + 'px';
			this.img.style.height = rect.height + 'px';
			this.img.src = this.getThumbUrl(value.path);
			imagesLoaded(this.img, () => {
				this.classList(this.element).remove(this.progressClass);
				this.img.style.removeProperty('width');
				this.img.style.removeProperty('height');
				this.progressImg.style.removeProperty('clip');
			});
			this.classList(this.element).remove(this.emptyClass);
		} else {
			this.classList(this.element).add(this.emptyClass);
			this.classList(this.element).remove(this.progressClass);
		}
		this.getInput().disabled = false;
		this.value = value;
	}


}


export default ImagefileField;
