export default function (entry) {
	return `
		<fieldset>
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper',
			this.loop(entry.options, (option) => {
				const label = option.label;
				const value = option.value;
				const optionId = this.id.create(entry.id + '_');
				const attributes = {};
				if (String(entry.default) === String(value)) {
					attributes.checked = 'checked';
				}
				return `
					${this.html.element({
						tag: 'input',
						attributes: Object.assign({}, entry.attributes, attributes, {
							type: 'radio',
							name: entry.inputName,
							value: value,
							id: optionId
						}),
						classes: ['field__input', 'field__input--radio'],
					})}
					${this.html.element({
						tag: 'label',
						classes: ['field__label', 'field__label--inputLabel'],
						attributes: {
							for: optionId,
						},
						content: `
							<span ${this.html.classAttr('field__inputIcon', 'field__inputIcon--unchecked')}>${this.html.svgIcon('radio')}</span>
							<span ${this.html.classAttr('field__inputIcon', 'field__inputIcon--checked')}>${this.html.svgIcon('radio-checked')}</span>
							${this.html.element({tag: 'span', classes: ['field__labelValue'], content: this.escape(label)})}
						`
					})}
				`;
			})
		)}
		</fieldset>
	`;
}
