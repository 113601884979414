import Factory from '../di/factory';


class SwapTransitionFactory extends Factory {

	constructor({container, prefix = '', suffix = 'Transition'}) {
		super({container: container, prefix: prefix, suffix: suffix});
	}

}


export default SwapTransitionFactory;
