import TemplateHelper from './template-helper';


class AccountHelper extends TemplateHelper {

	constructor({account}) {
		super();
		this.account = account;
    }

	isLoggedIn() {
		return this.account.isLoggedIn();
	}


	get(name = null) {
		return this.account.get(name);
	}


	isAllowedTo(permissions, requireAll = true) {
		return this.account.isAllowedTo(permissions, requireAll);
	}

}

export default AccountHelper;
