import DialogContext from './dialog-context';
import dialogMixin from './dialog-mixin';


export default (di) => {
	di
		.setType({
			type: DialogContext, name: 'DialogContext', parent: 'Context', params: {
				template: di.lazyGet('template/template'),
			},
			initCall: 'init'
		})
		.setMixin({mixin: dialogMixin, name: 'dialogMixin', mixins: ['contextsMixin']})
		;
};
