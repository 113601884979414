import NavigationTracker from './navigation-tracker';


class PiwikNavigationTracker extends NavigationTracker {


	init() {
		// eslint-disable-next-line piggyback/no-restricted-global-extend
		this.tracker = ('_paq' in window ? window._paq : null);
	}


	doTrack(url, title) {
		if (this.tracker) {
			this.tracker.push(['setDocumentTitle', title]);
		    this.tracker.push(['setCustomUrl', url]);
		    this.tracker.push(['trackPageView']);
		} else {
			console.log('page change:', url, title);
		}
	}


	doTrackEvent(category, action, name = null, value = null) {
		if (this.tracker) {
			const params = ['trackEvent', category, action];
			if (name !== null) {
				params.push(name);
			}
			if (value !== null) {
				params.push(value);
			}
			this.tracker.push(params);
		} else {
			console.log('tracking event', category, action, name, value);
		}
	}

}


export default PiwikNavigationTracker;
