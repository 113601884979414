import {trim} from '../utils/string';
import pageConstants from './_constants';


class Pages {

	constructor({baseUrl, events, navigation, pageTransitionFactory, pageLoaderFactory, pageTransitionRouter, pageLoaderRouter}) {
		this.baseUrl = baseUrl;
		this.events = events;
		this.navigation = navigation;
		this.pageTransitionFactory = pageTransitionFactory;
		this.pageLoaderFactory = pageLoaderFactory;
		this.pageTransitionRouter = pageTransitionRouter;
		this.pageLoaderRouter = pageLoaderRouter;

		this.callback = null;

		this.pendingRequest = null;
		this.busy = false;
		if (this.navigation.isHistoryApiSupported()) {
			this.events.on(document, 'history:navigate', this.onNavigate.bind(this));
		}
	}


	onNavigate(event) {
		const request = this.createRequest(event);
		if (!this.busy || ('force' in request && request.force)) {
			this.processRequest(request).then(() => {
				if (this.callback !== null) {
					const callback = this.callback;
					this.callback = null;
					callback();
				}
			});
		} else {
			if (event.detail.navigationType === 'link' && event.detail.originalEvent) {
				event.detail.originalEvent.preventDefault();
			}
			this.pendingRequest = request;
		}
	}


	processRequest(request) {
		this.busy = true;
		let promise = Promise.resolve();
		request.requestUri = this.pageTransitionRouter.route(request.requestUri, [request], request.params);
		request.requestUri = this.pageLoaderRouter.route(request.requestUri, [request], request.params);
		if (request.loader !== null && request.transition !== null) {
			request.event.preventDefault();
			if (request.event.detail.navigationType === 'link' && request.event.detail.originalEvent) {
				request.event.detail.originalEvent.preventDefault();
			}
			const loaderName = this.pageLoaderFactory.has(request.loader) ? request.loader : pageConstants.defaultLoaderName;
			const loader = this.pageLoaderFactory.newInstance(loaderName);
			const transitionName = this.pageTransitionFactory.has(request.transition) ? request.transition : pageConstants.defaultTransitionName;
			const transition = this.pageTransitionFactory.newInstance(transitionName, {pageLoader: loader});
			promise = promise.then(() => transition.execute(request));
		}
		promise = promise.then(() => {
			let p = Promise.resolve();
			if (this.pendingRequest) {
				p = p.then(() => {
					const pendingRequest = this.pendingRequest;
					this.pendingRequest = null;
					this.busy = false;
					return this.processRequest(pendingRequest);
				});
			}
			this.busy = false;
			return p;
		});
		return promise;
	}


	releaseLock() {
		this.busy = false;
		return this;
	}


	initCurrent() {
		setTimeout(() => this.navigation.triggerCurrentPageNavigationEvent(), 0);
		return this;
	}


	then(callback) {
		this.callback = callback;
		return this;
	}


	createRequest(event) {
		const request = Object.assign({}, event.detail);
		request.params = {};
		request.requestUri = this.getRequestUri(request.url);
		request.event = event;
		request.transition = null;
		request.loader = null;
		return request;
	}


	getRequestUri(url) {
		if (this.baseUrl === null) {
			throw new Error('baseUrl not defined');
		}
		return trim(url.substr(this.baseUrl.length), '/');
	}

}


export default Pages;
