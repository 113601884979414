import queryString from 'query-string';
import AccountManager from '../../common/account/account-manager';


class LdiAccountManager extends AccountManager {

	constructor({element, root,
		currentClass = 'current',
		loggedInClass = 'loggedIn',
		formDefs = [
			{name: 'login', def: 'account/login'},
			{name: 'activate', def: 'account/activate'},
			{name: 'forgotPassword', def: 'account/forgotPassword'},
			{name: 'changePassword', def: 'account/changePassword'}
		],
		defaultUrl = '',
		loggedDefaultUrl = '',
		registerUrl
	} = {}) {
		super({element: element, root: root, formDefs: formDefs, defaultUrl: defaultUrl, loggedDefaultUrl: loggedDefaultUrl});
		this.registerUrl = registerUrl;
		this.loggedInClass = loggedInClass;
	}



	prepare(element, data) {
		this.listeners.authentication = this.events.on(this.root, 'account:login account:logout', this.onAccountAuthenticationChange.bind(this));
		this.appLoader = this.components.getComponent(this.root, 'appLoader');
		super.prepare(element, data);
		this.on('ready').then(() => {
			this.events.on(element, this.dataSelector('action', 'register'), 'click', this.onRegisterClick.bind(this));
			this.events.on(element, this.dataSelector('action', 'activate'), 'click', this.onActivateClick.bind(this));
			this.events.on(element, this.dataSelector('action', 'sendAgainActivationCode'), 'click', this.onSendAgainActivationCodeClick.bind(this));
		});
	}


	prepareInitRequests() {
		const requests = super.prepareInitRequests();
		const qs = queryString.parse(location.search);
		if ('takeover' in qs && qs.takeover.length) {
			for (const request of requests) {
				if (request.action === 'account/boot') {
					request.params.takeover = qs.takeover;
					break;
				}
			}
		}
		return requests;
	}


	start() {
		//setTimeout(() => this.switchTo('activate'), 1000);
	}


	onAccountAuthenticationChange(event) {
		this.classList(this.root).toggle(this.loggedInClass, (event.type === 'account:login'));
	}


	onActivateClick(event) {
		event.preventDefault();
		this.switchTo('activate');
	}


	onRegisterClick(event) {
		event.preventDefault();
		this.close().then(() => this.navigation.redirect(this.registerUrl));
	}


	onSendAgainActivationCodeClick(event) {
		event.preventDefault();
		const form = this.forms.activate.form;
		const values = form.getValue();
		form.disableSubmit();
		this.api.execute('account/sendActivationCode', {element: values}).then((response) => {
			if (response.isSuccess()) {
				form.resetErrors();
				this.updateMessage('activate', this.locale.get('account/newActivationCodeSent'));
			} else {
				if (response.isNotValid()) {
					form.setValueAndErrors(response.output.element);
				}
			}
		}).catch((error) => {
			console.error(error);
		}).then(() => {
			form.enableSubmit();
		});
	}


	onLoginNotValid(form, response) {
		if (response.isNotValid()) {
			form.setValueAndErrors(response.output.element);
		} else if (response.isNotAuthenticated()) {
			if ('requireActivation' in response.output && response.output.requireActivation) {
				const values = form.getValue();
				this.switchTo('activate', {email: values.email, password: values.password}, this.getPendingRequest(), this.locale.get('account/requireActivation'));
			} else {
				form.setValueAndErrors({fields: {password: {errors: [this.locale.get('account/invalidLogin')]}}});
			}
		}
	}


	onActivateFormSubmit(event) {
		const form = this.forms.activate.form;
		const values = form.getValue();
		form.disableSubmit();
		this.api.execute('account/activate', {element: values}).then((response) => {
			if (response.isSuccess()) {
				this.account.update(response.output.account);
				this.triggerLogin();
				this.contexts.pop();
				this.processPendingRequest();
			} else {
				if (response.isNotValid()) {
					form.setValueAndErrors(response.output.element);
				} else if (response.isNotAuthenticated()) {
					form.setValueAndErrors({fields: {activationCode: {errors: [this.locale.get('account/invalidActivation')]}}});
				}
			}
		}).catch((error) => {
			console.error(error);
		}).then(() => {
			form.enableSubmit();
		});
	}


	onLogoutClick(event) {
		super.onLogoutClick(event);
		this.navigation.redirect('');
	}

}


export default LdiAccountManager;
