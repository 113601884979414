import ApiPage from '../api-page';


class RegisterPage extends ApiPage {

	onLoad() {
		return super.onLoad().then(() => {
			this.accountManager = this.components.getComponent(this.root, 'accountManager');
			if (this.accountManager.isLoggedIn()) {
				this.navigation.redirect(this.params.projects);
			} else {
				this.form = this.getComponent('form');
				this.listeners.submit = this.events.on(this.element, 'form:submit', this.onFormSubmit.bind(this));
			}
		});
	}


	onFormSubmit(event) {
		this.form.disableSubmit();
		const values = this.form.getValue();
		this.api.execute('account/register', {element: values}).then((response) => {
			if (response.isSuccess()) {
				this.accountManager.switchTo('activate', {email: values.email}, () => {
					this.navigation.redirect(this.params.projects);
				}, this.locale.get('account/activationCodeSent'));
			} else {
				if (response.isNotValid()) {
					this.form.setValueAndErrors(response.output.element);
					console.log('not valid', response);
				} else if (response.isNotCreated() && 'duplicated' in response.output) {
					this.form.getField('email').setErrors([this.getText('account/duplicated')]);
				}
			}
			return response;
		}).catch((error) => {
			console.log('submit error', error);
		}).then(() => {
			this.form.enableSubmit();
		});
	}

}


export default RegisterPage;
