import PageComponent from '../component/page-component';


class IntersectionAnimationController extends PageComponent {

	constructor({
		root,
		element,
		intersectionMargin = '-10px',
		intersectionThreshold = 0.2
	}) {
		super({root: root, element: element});
		this.defaults.intersectionMargin = intersectionMargin;
		this.defaults.intersectionThreshold = intersectionThreshold;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.intersectionMargin = data.intersectionMargin;
		this.intersectionThreshold = data.intersectionThreshold;
		this.intersectionObserver = null;
		if ('for' in data) {
			this.animation = this.components.queryComponent(this.root, this.dataSelector('id', data.for));
			if (this.animation) {
				this.animation.on('load').then(() => {
					this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), {
						rootMargin: `${this.intersectionMargin} 0px ${this.intersectionMargin} 0px`,
						threshold: [this.intersectionThreshold]
					});
					this.intersectionObserver.observe(this.animation.getElement());
				});
			}
		}
	}


	clear() {
		if (this.intersectionObserver) {
			this.intersectionObserver.disconnect();
		}
		if (this.animation) {
			this.animation = null;
		}
	}


	onIntersection(entries) {
		for (const entry of entries) {
			if (entry.intersectionRatio >= this.intersectionThreshold) {
				this.animation.play();
			} else {
				this.animation.pause();
			}
		}
	}

}


export default IntersectionAnimationController;
