module.exports={
	"notValid": "Invalid input",

    "required": "The field is required",
    "invalidEmail": "Invalid E-Mail address",
    "invalidUrl": "Invalid URL",
    "invalidIds": "Invalid ID values",
    "outOfSet": "The value is not allowed",
    "notInt": "The value must be an int number",
    "notPosInt": "The value must be an int number higher than zero",
    "notZeroPosInt": "The value must be an int number equal or higher than zero",
    "minLen": "The value can contains a minimum of {min} characters",
    "maxLen": "The value can contains a maximum of {max} characters",
    "minMaxLen": "The value length must be between {min} and {max}",
    "min": "The value can be equal or higher than {min}",
    "max": "The value can be equal or lower than {max}",
	"minMax": "The value must be between {min} and {max}",
	"minDate": "The date must be on or after {min}",
	"maxDate": "The date must be on or before {max}",
    "invalidFormat": "Invalid format",
    "notSameValue": "You should retype exactly the previous value",
	"elementDuplicated": "An element with the same value already exists",

    "checkRequired": "Please confirm the information",
    "imageNotValid": "The image appears to be broken",
    "uploadNotDetected": "An error occurred while uploading",
    "uploadTypeNotAllowed": "The file format is not allowed",
    "uploadContentLengthNotSupported": "This file cannot be uploaded",
    "uploadFileEmpty": "The file appears to be broken",
    "uploadFileTooBig": "The file is too big",
    "uploadFileSizeMismatch": "An error occurred while uploading",
    "uploadServerError": "An error occurred",

    "forbidden": "Access denied!",
    "generic": "An unexpected error occurred.<br />\n Please try again later."
}
