export default function (form) {
	return `
		${this.render(
			'form/form',
			form,
			'account/login',
			['form--login'],
			{}
		)}
	`;
}
