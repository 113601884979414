import PageComponent from '../component/page-component';


class ClassToggler extends PageComponent {

	constructor({
		root,
		element,
		classAttribute = 'class',
		toggledClass = 'toggled'
	}) {
		super({root: root, element: element});
		this.classAttribute = classAttribute;
		this.toggledClass = toggledClass;
		this.target = null;
	}


	prepare() {
		const id = this.dataAttr().get('for');
		this.target = this.root.querySelector(this.dataSelector('id', id));
		if (this.target) {
			this.className = this.dataAttr().get(this.classAttribute);
			this.listeners.toggle = this.events.on(this.element, 'click', this.onToggle.bind(this));
		}
	}


	onToggle(event) {
		event.preventDefault();
		this.element.blur();
		this.classList(this.target).toggle(this.className);
		this.classList().toggle(this.toggledClass);
	}


}

export default ClassToggler;
