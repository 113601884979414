export default function (entry) {
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper', `
			${this.html.open({
				tag: 'select',
				attributes: Object.assign({}, entry.attributes, {
					name: entry.inputName,
					id: entry.id
				}),
				classes: ['field__input', 'field__input--select']
			})}
			${this.loop(entry.options, (option) => {
				const label = option.label;
				const value = option.value;
				const attributes = {};
				if (String(entry.default) === String(value)) {
					attributes.selected = 'selected';
				}
				return this.html.element({
					tag: 'option',
					attributes: Object.assign({}, attributes, {value: value}),
					classes: ['field__selectOption'],
					content: this.escape(label)
				});
			})}
			${this.html.close()}
			<span ${this.html.classAttr('field__selectIcon')}>${this.html.svgIcon('down')}</span>
		`)}
	`;
}
