import RadioField from './radio-field';
import langSelectorMixin from './lang-selector-mixin';


class LangSelectorRadioField extends langSelectorMixin(RadioField) {

	resetValue() {
		this.setValue(this.locale.getDefaultLanguage());
		return this;
	}

}


export default LangSelectorRadioField;
