export default function (action, params = {}) {
	action = Object.assign({}, action);
	const attributes = Object.assign({}, ('attributes' in action ? action.attributes : {}));
	let data = Object.assign({}, ('data' in action ? action.data : {}));
	const labelData = Object.assign({}, ('labelData' in action ? action.labelData : {}));
	if ('href' in attributes) {
		attributes.href = this.url.getUrl(attributes.href, params);
	}
	data = this.map(data, (value) => this.text.format(value, params));
	return this.render('button', action, attributes, data, labelData);
}
