import PageTransition from '../page/page-transition';

class SearchPageTransition extends PageTransition {

	execute(request) {
		return Promise.resolve();
	}

}


export default SearchPageTransition;
