import HistoryApi from './history-api';
import Navigation from './navigation';
import navigationMixin from './navigation-mixin';
import HashScroller from './hash-scroller';
import CurrentLinkManager from './current-link-manager';

import NavigationTracker from './navigation-tracker';
import PiwikNavigationTracker from './piwik-navigation-tracker';
// import GoogleTagManagerNavigationTracker from './google-tag-manager-navigation-tracker';
// import FbPixelNavigationTracker from './fb-pixel-navigation-tracker';
/// import GaGtagNavigationTracker from './ga-gtag-navigation-tracker';



export default (di) => {
	di
		.setType({type: HistoryApi, name: 'HistoryApi', params: {
				events: di.lazyGet('events/events')
			}
		})
		.set('navigation/history', di.lazyNew('HistoryApi'))

		.setType({type: Navigation, name: 'Navigation', mixins: ['domMixin'], initCall: 'init', params: {
				history: di.lazyGet('navigation/history'),
				baseUrl: di.lazyValue('baseUrl', '')
			}
		})
		.set('navigation/navigation', di.lazyNew('Navigation'))

		.setMixin({mixin: navigationMixin, name: 'navigationMixin', setters: {
				injectNavigation: di.lazyGet('navigation/navigation')
			}
		})

		.setType({type: HashScroller, name: 'HashScroller', mixins: ['domMixin'], params: {
				events: di.lazyGet('events/events')
			}
		})

		.setType({type: CurrentLinkManager, name: 'CurrentLinkManager', mixins: ['domMixin'], initCall: 'init'})
		.set('navigation/currentLinkManager', di.lazyNew('CurrentLinkManager'))

		.setType({type: NavigationTracker, name: 'NavigationTracker', params: {
				events: di.lazyGet('events/events')
			}
		})

		// .setType({type: GaGtagNavigationTracker, name: 'GaGtagNavigationTracker', parent: 'NavigationTracker'})
		.setType({type: PiwikNavigationTracker, name: 'PiwikNavigationTracker', parent: 'NavigationTracker'})

		// .setType({type: GoogleTagManagerNavigationTracker, name: 'GoogleTagManagerNavigationTracker', parent: 'NavigationTracker'})

		// .setType({type: FbPixelNavigationTracker, name: 'FbPixelNavigationTracker', parent: 'NavigationTracker', params: {
		// 		trackEventEnabled: true,
		// 		clickTrackEnabled: true,
		// 		clickTrackAttribute: di.lazyCall(() => di.getValue('dom/dataAttrPrefix') + 'fb-click-track')
		// 	}
		// })

	;
};
