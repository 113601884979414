import InteractiveField from './interactive-field';


class DatetimeField extends InteractiveField {

	getInput() {
		if (!this.input) {
			this.input = {
				date: this.element.querySelector('input[type="date"]'),
				time: this.element.querySelector('input[type="time"]')
			};
		}
		return this.input;
	}


	writeValue(value) {
		const input = this.getInput();
		const values = value.split(' ');
		input.date.value = values[0];
		input.time.value = values[1];
	}


	readValue() {
		const input = this.getInput();
		return input.date.value + ' ' + input.time.value + (input.time.value.length === 5 ? ':00' : '');
	}


	setFocus() {
		this.getInput().date.focus();
	}


	setBlur() {
		this.getInput().date.blur();
		this.getInput().time.blur();
	}


	enableInput() {
		this.getInput().date.disabled = false;
		this.getInput().time.disabled = false;
	}


	disableInput() {
		this.getInput().date.disabled = true;
		this.getInput().time.disabled = true;
	}


}


export default DatetimeField;
