import DefaultPage from './default-page';
import templateMixin from '../../common/template/template-mixin';
import localeMixin from '../../common/locale/locale-mixin';
import apiMixin from '../../common/api/api-mixin';
import accountMixin from '../../common/account/account-mixin';
import dialogMixin from '../../common/dialog/dialog-mixin';
import notificationsMixin from '../../common/notifications/notifications-mixin';


class ApiPage extends notificationsMixin(dialogMixin(apiMixin(accountMixin(localeMixin(templateMixin(DefaultPage)))))) {

	onLoad() {
		return super.onLoad().then(() => {
			if (this.dataAttr(this.element).get('page').toLowerCase() !== 'register') {
				this.classList(this.root).add('account');
			}
			return this;
		});
	}

}

export default ApiPage;
