export default function (entry) {
	const attributes = {};
	if (parseInt(entry.default, 10)) {
		attributes.checked = 'checked';
	}
	return `
		${this.html.element({
			tag: 'input',
			attributes: Object.assign({}, entry.attributes, attributes, {
				type: 'checkbox',
				name: entry.inputName,
				value: 1,
				id: entry.id
			}),
			classes: ['field__input', 'field__input--checkbox']
		})}
		${this.html.element({
			tag: 'label',
			classes: ['field__label'],
			attributes: {
				for: entry.id,
			},
			content: `
				<span ${this.html.classAttr('field__inputIcon', 'field__inputIcon--unchecked')}>${this.html.svgIcon('checkbox')}</span>
				<span ${this.html.classAttr('field__inputIcon', 'field__inputIcon--checked')}>${this.html.svgIcon('checkbox-checked')}</span>
				${this.html.element({tag: 'span', classes: ['field__labelValue', 'ugc'], content: 'label' in entry ? entry.label : ''})}
			`
		})}
	`;
}
