import actionActionLink from '../../templates/frontend/action/action-link';
import actionActionSubmit from '../../templates/frontend/action/action-submit';

import dialogButtons from '../../templates/frontend/dialog/buttons';
import dialogDialog from '../../templates/frontend/dialog/dialog';

import formFieldArray from '../../templates/frontend/form/field/array';
import formFieldCharsCounter from '../../templates/frontend/form/field/chars-counter';
import formFieldCheckbox from '../../templates/frontend/form/field/checkbox';
import formFieldDatetime from '../../templates/frontend/form/field/datetime';
import formFieldError from '../../templates/frontend/form/field/error';
import formFieldErrors from '../../templates/frontend/form/field/errors';
import formFieldFile from '../../templates/frontend/form/field/file';
import formFieldGroupImage from '../../templates/frontend/form/field/group-image';
import formFieldGroup from '../../templates/frontend/form/field/group';
import formFieldHidden from '../../templates/frontend/form/field/hidden';
import formFieldImagefile from '../../templates/frontend/form/field/imagefile';
import formFieldInputWrapper from '../../templates/frontend/form/field/input-wrapper';
import formFieldLabel from '../../templates/frontend/form/field/label';
import formFieldLangSelectorRadio from '../../templates/frontend/form/field/lang-selector-radio';
import formFieldPassword from '../../templates/frontend/form/field/password';
import formFieldPostField from '../../templates/frontend/form/field/post-field';
import formFieldPreField from '../../templates/frontend/form/field/pre-field';
import formFieldRadio from '../../templates/frontend/form/field/radio';
import formFieldSelect from '../../templates/frontend/form/field/select';
import formFieldTags from '../../templates/frontend/form/field/tags';
import formFieldText from '../../templates/frontend/form/field/text';
import formFieldTextarea from '../../templates/frontend/form/field/textarea';
import formActions from '../../templates/frontend/form/actions';
import formFieldActions from '../../templates/frontend/form/field-actions';
import formField from '../../templates/frontend/form/field';
import formFields from '../../templates/frontend/form/fields';
import formFormClose from '../../templates/frontend/form/form-close';
import formFormContent from '../../templates/frontend/form/form-content';
import formFormOpen from '../../templates/frontend/form/form-open';
import formForm from '../../templates/frontend/form/form';
import formSortableHandle from '../../templates/frontend/form/sortable-handle';

import pageAccountProfile from '../../templates/frontend/page/account/profile';
import pageAccountRegister from '../../templates/frontend/page/account/register';
import pageProjectEdit from '../../templates/frontend/page/project/edit';
import pageProjectPreview from '../../templates/frontend/page/project/preview';
import pageProjectProjects from '../../templates/frontend/page/project/projects';
import pageWrapper from '../../templates/frontend/page/wrapper';

import projectFieldValue from '../../templates/frontend/project/field-value';
import projectTab from '../../templates/frontend/project/tab';

import searchNoResults from '../../templates/frontend/search/no-results';
import searchPagination from '../../templates/frontend/search/pagination';
import searchResultsItem from '../../templates/frontend/search/results-item';
import searchResults from '../../templates/frontend/search/results';
import searchTagsItems from '../../templates/frontend/search/tags-items';
import searchTags from '../../templates/frontend/search/tags';

import action from '../../templates/frontend/action';
import activate from '../../templates/frontend/activate';
import button from '../../templates/frontend/button';
import changePassword from '../../templates/frontend/change-password';
import forgotPassword from '../../templates/frontend/forgot-password';
import login from '../../templates/frontend/login';
import notification from '../../templates/frontend/notification';
import tag from '../../templates/frontend/tag';


export default {
	'action/actionLink': actionActionLink,
	'action/actionSubmit': actionActionSubmit,

	'dialog/buttons': dialogButtons,
	'dialog/dialog': dialogDialog,

	'form/field/array': formFieldArray,
	'form/field/charsCounter': formFieldCharsCounter,
	'form/field/checkbox': formFieldCheckbox,
	'form/field/datetime': formFieldDatetime,
	'form/field/error': formFieldError,
	'form/field/errors': formFieldErrors,
	'form/field/file': formFieldFile,
	'form/field/groupImage': formFieldGroupImage,
	'form/field/group': formFieldGroup,
	'form/field/hidden': formFieldHidden,
	'form/field/imagefile': formFieldImagefile,
	'form/field/inputWrapper': formFieldInputWrapper,
	'form/field/label': formFieldLabel,
	'form/field/langSelectorRadio': formFieldLangSelectorRadio,
	'form/field/password': formFieldPassword,
	'form/field/postField': formFieldPostField,
	'form/field/preField': formFieldPreField,
	'form/field/radio': formFieldRadio,
	'form/field/select': formFieldSelect,
	'form/field/tags': formFieldTags,
	'form/field/text': formFieldText,
	'form/field/textarea': formFieldTextarea,
	'form/actions': formActions,
	'form/fieldActions': formFieldActions,
	'form/field': formField,
	'form/fields': formFields,
	'form/formClose': formFormClose,
	'form/formContent': formFormContent,
	'form/formOpen': formFormOpen,
	'form/form': formForm,
	'form/sortableHandle': formSortableHandle,

	'page/account/profile': pageAccountProfile,
	'page/account/register': pageAccountRegister,
	'page/project/edit': pageProjectEdit,
	'page/project/preview': pageProjectPreview,
	'page/project/projects': pageProjectProjects,
	'page/wrapper': pageWrapper,

	'project/fieldValue': projectFieldValue,
	'project/tab': projectTab,

	'search/noResults': searchNoResults,
	'search/pagination': searchPagination,
	'search/resultsItem': searchResultsItem,
	'search/results': searchResults,
	'search/tagsItems': searchTagsItems,
	'search/tags': searchTags,

	action: action,
	activate: activate,
	button: button,
	changePassword: changePassword,
	forgotPassword: forgotPassword,
	login: login,
	notification: notification,
	tag: tag,

};
