import {iframeResize} from 'iframe-resizer';

import PageComponent from '../component/page-component';

class PankowForm extends PageComponent {
	// constructor({
	// 	root,
	// 	element,
	// }) {
	// 	super({root: root, element: element});
	// }

	prepare() {
		console.log('prepare pankow form');
		this.iframe = this.element.querySelector(this.dataSelector('iframe'));
		if (this.iframe) {
			console.log('iframeResize', iframeResize);
			this.resizer = iframeResize(
				{
					// log: true,
					heightCalculationMethod: 'bodyOffset', //'taggedElement',
				},
				this.iframe
			);
		}
	}
}

export default PankowForm;
