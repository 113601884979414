module.exports={
	"loginTitle": "Login",
	"activationTitle": "Activation of your account",
	"forgotPasswordTitle": "Forgot password?",
	"changePasswordTitle": "Change password",
	"registerTitle": "Register",
	"registerDescription": "To submit an application, please register first:",
	"profileTitle": "Profile",

	"duplicated": "A user with the same e-mail address already exists",

	"duplicatedEmailError": "An account with the same e-mail address already exists",

    "invalidLogin": "Wrong e-mail address and/or password",

    "activationEmailSubject": "Activation code for your registration on www.land-der-ideen.de",

	"activationEmailBody": "Dear applicant,\nThank you for registering on www.land-der-ideen.de. To unlock your account and complete the registration process, please enter the following activation code:\n\n{activationCode}\n\nPlease note that this code will be invalidated as soon as you use the function \"Resend activation code\".\n\nIf you have questions about the application process, we are happy to help. Write us an e-mail: orte@land-der-ideen.de or call at +49 (0)30 / 23 60 78 490 (Mon-Fri 9am-6pm).\n\nSincerely\nYour Project Team at \"Land der Ideen\"",

	"changePasswordEmailSubject": "Change password",

	"changePasswordEmailBody": "Dear applicant,\nyour password for your account on www.land-der-ideen.de has been reset.\nPlease use the following code to change your password:\n\n{changePasswordCode}\n\nIf you have questions concerning the application process, we are happy to help. Scrite us an e-mail: orte@land-der-ideen.de or call at +49 (0)30 / 23 60 78 490 (Mon-Fri 9am-6pm).\n\nSincerely\nYour Project Team at \"Land der Ideen\"",

    "profileEdited": "The changes have been saved.",

    "profile": "My profile",

	"register": "Register",
	"newRegistration": "New registration",

    "activate": "Activate",

	"login": "Login",
	"toLogin": "Login",

	"name": "Name",
	"email": "E-mail address",
	"password": "Password",
	"confirmPassword": "Confirm password",
	"newPassword": "New password",
	"confirmNewPassword": "Confirm new password",
	"favouriteLang": "Preferred Language",

	"activationCode": "Activation code",
	"changePasswordCode": "Code",

	"activation": "Activate account",

	"forgotPassword": "Forgot password?",

	"changePassword": "Change password",

	"submitForgotPassword": "Reset password",

	"sendAgainActivationCode": "Resend activation code",

	"passwordChanged": "",
	"invalidChangePassword": "Wrong e-mail address and / or code",
	"changePasswordCodeSent": "We have sent you a code by e-mail. Please enter this code below to change your password.",
	"activationCodeSent": "We have sent you an activation code by e-mail. Please enter your login details and this code below to unlock your account.",
	"newActivationCodeSent": "We have sent you a new activation code by e-mail."

}
