export default function (pageName, pageParams, pageClasses, projects, form, contestDescriptions, introText) {
	return `
		<div ${this.html.classAttr(pageClasses)} ${this.html.dataAttr({page: pageName, params: pageParams})}>
			<div ${this.html.classAttr('blocks')}>
				<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeDefault')}>
					<div ${this.html.classAttr('block__content')}>

						<div ${this.html.classAttr('block__pageTitle')}>
							<h2>${this.text.get('project/projectsPageTitle')}</h2>
						</div>

						<div ${this.html.classAttr('block__text', 'ugc')} data-ldi-intro-text>
							${introText}
						</div>
					</div>
				</section>

				${this.cond(form, () => `
					<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeAlternative')}>
						<div ${this.html.classAttr('block__content')}>

							<div ${this.html.classAttr('page__form')}>
								${this.render('form/formOpen', form, 'project/add',	['form--add'], {})}
									${this.render('form/fields', form.fields)}
									<div ${this.html.classAttr('projects__contestDescriptions')}>
										${this.loop(contestDescriptions, (description, id) => this.html.element({
											tag: 'div',
											classes: ['projects__contestDescription'],
											data: {contestDescription: id},
											content: this.escape(description)
										}))}
									</div>
									${this.cond('actions' in form, () => this.render('form/actions', form.actions))}
								${this.render('form/formClose')}
							</div>

						</div>
					</section>
				`)}

				<section ${this.html.classAttr('blocks__block', 'block', 'block--colorSchemeDefault')}>
					<div ${this.html.classAttr('block__content')}>
						${projects.length ? `
							<div ${this.html.classAttr('page__projects', 'projects')}>
								<ul ${this.html.classAttr('projects__items')}>
									${this.loop(projects, (project) => {
										project.test = +project.test;
										project.takenover = +project.takenover;
										project.openingDiff = +project.openingDiff;
										project.closingDiff = +project.closingDiff;
										project.submitted = +project.submitted;
										return `
										<li ${this.html.classAttr('projects__item')} ${this.html.dataAttr({project: project})}>
											<div ${this.html.classAttr('projects__itemTitle')}>
												<span ${this.html.classAttr('projects__itemName')}>
													${this.escape(project.title)}
												</span>
												<span ${this.html.classAttr('projects__itemContest')}>
													${this.escape(project.competitionTitle)} - ${this.escape(project.contestName)}
												</span>
											</div>
											<div ${this.html.classAttr('projects__itemProgress')}>
												${project.submitted ? `
													<span ${this.html.classAttr('projects__submittedIcon')}>${this.html.svgIcon('check')}</span>
												` : `
													${Math.round(project.fieldsFilledCount / project.fieldsRequiredCount * 100)}%
												`}

											</div>
											<div ${this.html.classAttr('projects__itemActions')}>
												${this.cond(project.test || project.takenover || (!project.submitted && project.openingDiff > 0 && project.closingDiff > 0), () => `
													<a ${this.html.classAttr('projects__itemAction', 'button', 'button--seamless')} href="${this.url.getUrl(pageParams.editUrl, project)}" ${this.html.dataAttr({projectClosing: project.test ? 0 : project.closingDiff})}>
														<span ${this.html.classAttr('button__label')}>${this.text.get('project/edit')}</span>
													</a>
												`)}
												<a ${this.html.classAttr('projects__itemAction', 'button', 'button--seamless')} href="${this.url.getUrl(pageParams.previewUrl, project)}">
													<span ${this.html.classAttr('button__label')}>${this.text.get('project/preview')}</span>
												</a>
											</div>
										</li>
										`;
									})}
								</ul>
							</div>
							` : `
							<div ${this.html.classAttr('block__text')}>
								<p>${this.text.get('project/noProjects')}</p>
							</div>
						`}
					</div>
				</section>
			</div>
		</div>
	`;
}
