export default function(value) {
	if (value === '' || value === 0) {
		return '0 Bytes';
	}
	value = parseInt(value, 10);
	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
	const e = Math.floor(Math.log(value) / Math.log(1024));
	value = value / Math.pow(1024, Math.floor(e));
	value = Math.round(value * 100) / 100;
	return value + units[e];
}
