import PageComponent from '../component/page-component';
import baseFieldMixin from './base-field-mixin';
import formConstants from './_constants';


class BaseField extends baseFieldMixin(PageComponent) {

	constructor({
		root,
		element,
		invalidClass = 'invalid',
		errorTpl
	}) {
		super({root: root, element: element});
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}


	prepare() {
		const data = this.dataAttr(this.element);
		this.name = data.get(formConstants.nameAttribute);
		this.type = data.get(formConstants.typeAttribute);
		this.prepareBaseField({
			name: this.name,
			type: this.type,
			invalidClass: this.invalidClass,
			errorTpl: this.errorTpl
		});
	}


	clear() {
		this.clearBaseField();
	}

}


export default BaseField;
