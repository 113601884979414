import LdiSearch from './ldi-search';


const config = (di) => {
	di
		.setType({type: LdiSearch, name: 'LdiSearch', parent: 'Search', params: {
				relPath: di.lazyValue('relPath'),
				projectUrls: di.lazyValue('projectUrls')
			}
		})
	;
};


export default config;
