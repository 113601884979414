import Template from './template';
import templateMixin from './template-mixin';
import TemplateHelper from './template-helper';
import LocaleHelper from './locale-helper';
import DateHelper from './date-helper';
import HtmlHelper from './html-helper';
import IdHelper from './id-helper';
import StringHelper from './string-helper';
import UrlHelper from './url-helper';
import ThumbHelper from './thumb-helper';
import PaginationHelper from './pagination-helper';
import AccountHelper from './account-helper';



export default (di) => {
	di
		.setType({type: TemplateHelper, name: 'TemplateHelper'})
		.setType({type: DateHelper, name: 'DateHelper', parent: 'TemplateHelper'})
		.setType({type: HtmlHelper, name: 'HtmlHelper', parent: 'TemplateHelper', params: {
				classListParser: di.lazyGet('dom/classListParser'),
				dataAttrParser: di.lazyGet('dom/dataAttrParser'),
				iconFilesLoader: di.lazyGet('preload/iconFilesLoader')
			}
		})
		.setType({type: LocaleHelper, name: 'LocaleHelper', parent: 'TemplateHelper', params: {
				localeText: di.lazyGet('locale/localeText')
			}
		})
		.setType({type: UrlHelper, name: 'UrlHelper', parent: 'TemplateHelper', params: {
				baseUrl: di.lazyValue('baseUrl'),
				baseMediaUrl: di.lazyValue('baseMediaUrl', ''),
				localeText: di.lazyGet('locale/localeText')
			}
		})
		.setType({type: ThumbHelper, name: 'ThumbHelper', parent: 'TemplateHelper', params: {
				baseMediaUrl: di.lazyValue('baseMediaUrl', ''),
				baseThumbUrl: di.lazyValue('baseThumbUrl', '')
			}
		})
		.setType({type: IdHelper, name: 'IdHelper', parent: 'TemplateHelper'})
		.setType({type: StringHelper, name: 'StringHelper', parent: 'TemplateHelper'})
		.setType({type: PaginationHelper, name: 'PaginationHelper', parent: 'TemplateHelper', params: {
			itemsFactory: di.lazyGet('pagination/itemsFactory')
		}})
		.setType({type: AccountHelper, name: 'AccountHelper', parent: 'TemplateHelper', params: {
				account: di.lazyGet('account/account')
			}
		})
		.setType({type: Template, name: 'Template', params: {
				tpls: di.lazyValue('template/tpls', {})
			}
		})
		.set('template/template', di.lazyNew('Template'))

		.setMixin({mixin: templateMixin, name: 'templateMixin', setters: {
				injectTemplate: di.lazyGet('template/template')
			}
		})
	;
};
