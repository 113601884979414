import TextField from './text-field';

const ENTER_KEYCODE = 13;


class SearchField extends TextField {

	initEvents() {
		this.listeners.input = this.events.on(this.element, 'input', this.onInput.bind(this));
		this.listeners.click = this.events.on(this.element, this.dataSelector('searchIcon'), 'click', this.onIconClick.bind(this));
		return super.initEvents();
	}


	onInput(event) {
		if (event.which === ENTER_KEYCODE && this.eventsEnabled) {
			this.triggerEvent('change', event);
		}
	}


	onIconClick(event) {
		if (this.eventsEnabled) {
			this.triggerEvent('change', event);
		}
	}

}


export default SearchField;
