import {trim} from '../utils/string';
import TemplateHelper from './template-helper';


class UrlHelper extends TemplateHelper {

	constructor({baseUrl, baseMediaUrl, localeText}) {
		super();
		this.baseUrl = baseUrl;
		this.baseMediaUrl = baseMediaUrl;
		this.localeText = localeText;
	}


	getBaseUrl() {
		return this.baseUrl;
	}


	getUrl(path, params = {}) {
		if (path.indexOf('http') !== 0 && path.indexOf('/') !== 0) {
			path = this.baseUrl + (path.length ? '/' + trim(path, '/') : '');
		}
		return this.localeText.format(path, params);
	}

	getMediaUrl(path) {
		return this.baseMediaUrl + (path.length ? '/' + trim(path, '/') : '');
	}

}

export default UrlHelper;
