import LdiApp from './ldi-app';


export default (di) => {
	// TODO: make this configurable
	const dynamicBaseUrls = {
		projects: 'bewerbung',
		editProject: 'bewerbung/bearbeiten-{id}',
		previewProject: 'bewerbung/vorschau-{id}',
		profile: 'profil',
		register: 'anmeldung'
	};

	di
		.setType({
			type: LdiApp, name: 'LdiApp', parent: 'App', params: {
				requiredStyles: ['default'],
				requiredFonts: [
					{
						name: 'Futura LT W01',
						options: {weight: 'normal'}
					},
					{
						name: 'Futura LT W01',
						options: {weight: 'bold'}
					}
				]
			}, setters: {
				injectErrorListener: di.lazyGet('api/errorListener'),
				injectPageSlots: di.lazyGet('page/slots'),
				injectNavigationTracker: di.lazyNew('PiwikNavigationTracker'),
				injectCurrentLinkManager: di.lazyGet('navigation/currentLinkManager'),
				injectHashScroller: di.lazyNew('HashScroller'),
				injectHighContrast: di.lazyGet('accessibility/highContrast'),
				injectLocaleDynamicTranslator: di.lazyGet('locale/dynamicTranslator')
			}, postCreateCalls: [(app) => {
				const transitionRouter = di.get('router/pageTransitionRouter');
				const loaderRouter = di.get('router/pageLoaderRouter');
				const dataAttrFactory = di.get('dom/dataAttrFactory');
				const locale = di.get('locale/localeText');

				transitionRouter
					// search > search
					.append(transitionRouter.newRule().ifTrue((subject, request) => {
						const event = di.get('events/events').trigger(document.body, 'search:navigate', {request: request});
						return event.defaultPrevented;
					}).then((subject, matches, request) => {
						// console.log('match search page transition');
						request.transition = 'search';
						return subject;
					}).setLast()
					)

					// overlay
					.append(transitionRouter.newRule().ifTrue((subject, request) =>
						(request.navigationType === 'link' && dataAttrFactory({element: request.originalTarget}).get('slotTarget') === 'overlay') ||
						(request.navigationType === 'pop' && 'slotTarget' in request.state && request.state.slotTarget === 'overlay')
					).then((subject, matches, request) => {
						// console.log('match overlay page transition');
						request.transition = 'overlay';
						return subject;
					}).setLast()
					)

					// fallback to default transition
					.append(transitionRouter.newRule().always().then((subject, matches, request) => {
						// console.log('match default page transition');
						request.transition = 'default';
						return subject;
					}).setLast()
					)
					;

				loaderRouter
					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.projects + '(\\?.+)?$', 'g').then((subject, matches, request) => {
						// console.log('match register');
						request.loader = 'projects';
						request.params.title = locale.get('project/projectsPageTitle');
						request.params.editUrl = dynamicBaseUrls.editProject;
						request.params.previewUrl = dynamicBaseUrls.previewProject;
						return subject;
					}).setLast()
					)
					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.editProject.replace('{id}', '(?<id>[0-9]+)$'), 'g').then((subject, matches, request) => {
						// console.log('match editProject');
						request.loader = 'editProject';
						request.params.title = locale.get('project/editPageTitle');
						request.params.backUrl = dynamicBaseUrls.projects;
						request.params.previewUrl = dynamicBaseUrls.previewProject;
						return subject;
					}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.previewProject.replace('{id}', '(?<id>[0-9]+)$'), 'g').then((subject, matches, request) => {
						// console.log('match previewProject');
						request.loader = 'previewProject';
						request.params.title = locale.get('project/previewPageTitle');
						request.params.backUrl = dynamicBaseUrls.projects;
						return subject;
					}).setLast()
					)

					.append(loaderRouter.newRule().ifMatch('^' + dynamicBaseUrls.register + '$', 'g').then((subject, matches, request) => {
						// console.log('match register');
						request.loader = 'register';
						request.params.title = locale.get('account/registerTitle');
						request.params.projects = dynamicBaseUrls.projects;
						return subject;
					}).setLast()
					)

					// fallback to server side rendered page
					.append(loaderRouter.newRule().always().then((subject, matches, request) => {
						// console.log('match default page loader');
						request.loader = 'serverRendered';
						return subject;
					}).setLast()
					)
					;
			}]
		})

		.set('app', di.lazyNew('LdiApp'))
		;
};
