import TemplateHelper from './template-helper';


class PaginationHelper extends TemplateHelper {

	constructor({itemsFactory}) {
		super();
		this.itemsFactory = itemsFactory;
	}


	getItems(current, total, url, options = {}) {
		return this.itemsFactory({current: current, total: total, url: url, options: options}).getItems();
	}

}

export default PaginationHelper;
