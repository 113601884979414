import PageTransition from '../../common/page/page-transition';


class OverlayPageTransition extends PageTransition {

	updateHistory(request, response) {
		super.updateHistory(request, response);
		// this.backUrl = this.dataAttr(request.originalTarget).get('backUrl') || '';
		// this.backTitle = this.dataAttr(request.originalTarget).get('backTitle') || '';
		// this.history.mergeState({backUrl: this.backUrl, title: this.backTitle});
		this.history.mergeState({slotTarget: 'overlay'});
		return this;
	}


	// updateCurrentHistory(request, response) {
	// 	super.updateCurrentHistory(request, response);
	//     if (request.state.backUrl) {
	//         this.backUrl = request.state.backUrl;
	// 		this.backTitle = request.state.title;
	//     }
	// 	return this;
	// }


	commit(request, response) {
		this.updateDocumentTitle(request, response);
		// const overlay = this.contexts.getContext('overlay').getElement();
		// this.dataAttr(overlay).set('backUrl', this.backUrl);
		// this.dataAttr(overlay).set('backTitle', this.backTitle);
		this
			.raisePageChangeEvent(request, response)
			.raiseHashChangeEvent(request, response)
			;
	}


	endAnimation(request, response) {
		return this.contexts.push('overlay').then(() => response);
	}

}


export default OverlayPageTransition;
