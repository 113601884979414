import InteractiveField from './interactive-field';


class HiddenField extends InteractiveField {

	getInput() {
		return this.element;
	}


	writeValue(value) {
		this.getInput().value = value;
	}


	readValue() {
		return this.getInput().value;
	}


	canFocus() {
		return false;
	}


	setFocus() { }

	setBlur() {	}

	initEvents() { }

	enableEvents() { }

	disableEvents() { }

	deinitEvents() { }

	focus(value, suppressEvent = false) { }

	blur(value, suppressEvent = false) { }

	setErrors(errors = []) { }

}


export default HiddenField;
