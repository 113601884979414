import PageComponent from '../../common/component/page-component';


class ProjectNav extends PageComponent {

	constructor({element, root, currentClass = 'current'}) {
		super({element: element, root: root});
		this.currentClass = currentClass;
	}


	prepare(element, data) {
		const sectionElements = element.querySelectorAll(this.dataSelector('projectNavItem'));
		this.currentId = 0;
		this.sections = new Map();
		for (const sectionElement of sectionElements) {
			const id = String(this.dataAttr(sectionElement).get('projectNavItem'));
			this.sections.set(id, sectionElement);
			if (this.classList(sectionElement).contains(this.currentClass)) {
				this.currentId = id;
			}
		}
	}


	clear() {
		this.sections.clear();
	}


	switchTo(newId) {
		return new Promise((resolve) => {
			const current = this.sections.get(this.currentId);
			const next = this.sections.get(newId);
			const height = Math.max(current.getBoundingClientRect().height, next.getBoundingClientRect().height);
			this.element.style.height = height + 'px';
			this.classList(current).remove(this.currentClass);
			this.classList(next).add(this.currentClass);
			this.onTransitionEnd(next).then(() => {
				this.element.style.removeProperty('height');
				this.currentId = newId;
				resolve();
			});
		});
	}

}


export default ProjectNav;
