import deFormError from '../../../bin/json/lang/ldi/de/form/error.json';
import deProjectFrontend from '../../../bin/json/lang/ldi/de/project/frontend.json';
import deAccount from '../../../bin/json/lang/ldi/de/account.json';
import deProject from '../../../bin/json/lang/ldi/de/project.json';

import enFormError from '../../../bin/json/lang/ldi/en/form/error.json';
import enProjectFrontend from '../../../bin/json/lang/ldi/en/project/frontend.json';
import enAccount from '../../../bin/json/lang/ldi/en/account.json';
import enProject from '../../../bin/json/lang/ldi/en/project.json';


export default {
	'de/form/error': () => deFormError,
	'de/project/frontend': () => deProjectFrontend,
	'de/account': () => deAccount,
	'de/project': () => deProject,

	'en/form/error': () => enFormError,
	'en/project/frontend': () => enProjectFrontend,
	'en/account': () => enAccount,
	'en/project': () => enProject,
};
