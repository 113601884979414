export default function tpl(tag) {
	//const selected = parseInt(tag.selected, 10);
	return `
		<button ${this.html.classAttr('tag', (tag.selected ? 'tag--selected' : ''), ...(tag.classes ? tag.classes : []))} ${this.html.dataAttr('data' in tag ? tag.data : {})}>
			${'preIcon' in tag ? `
				<span ${this.html.classAttr('tag__icon')}>
					${this.html.svgIcon(tag.preIcon)}
				</span>
			` : ''}
			<span ${this.html.classAttr('tag__label')}>
				<span ${this.html.classAttr('tag__name')}>${this.escape(tag.label)}</span>
				${this.cond('amount' in tag, `<span ${this.html.classAttr('tag__amount')}>${this.escape(tag.amount)}</span>`)}
			</span>
			${'postIcon' in tag ? `
				<span ${this.html.classAttr('tag__icon')}>
					${this.html.svgIcon(tag.postIcon)}
				</span>
			` : ''}
	</button>
	`;
}
