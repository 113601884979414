import Context from '../../common/context/context';


class AccountContext extends Context {

	constructor({name, root, popOnKey = 'esc', inClass = 'active', closeOnClickOutside = true}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.inClass = inClass;
		this.closeOnClickOutside = closeOnClickOutside;
	}


	setEvents(events) {
		if (this.closeOnClickOutside) {
			this.events.on(this.getElement(), this.dataSelector('overlayClose'), 'click', this.onCloseClick.bind(this));
			this.events.on(this.getElement(), 'click', this.onClick.bind(this));
		}
	}


	onCloseClick(event) {
		event.stopPropagation();
		this.contexts.pop();
	}


	onClick(event) {
		if (!event.target.closest(this.dataSelector('accountBlock'))) {
			this.contexts.pop();
		}
	}

	switchIn(otherContext) {
		return this.threeStateTransition(this.getElement()).add(this.inClass);
	}


	switchOut(otherContext) {
		return this.threeStateTransition(this.getElement()).remove(this.inClass);
	}

}


export default AccountContext;
