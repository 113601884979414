class ErrorListener {

	constructor({events}) {
		this.events = events;
		// listen to api calls, looking for errors
		this.apiListener = this.events.on(document, 'api:complete', this.onApiCallComplete.bind(this));
	}


	onApiCallComplete(event) {
		if (event.defaultPrevented) {
			return;
		}
		const request = event.detail.request;
		const catching = request.response.isFailure() || request.response.isForbidden();
		if (catching) {
			event.preventDefault();
			console.log('catching error', request.response);
			request.forceResponseToError();
		}
	}

}

export default ErrorListener;
