import SearchEngine from './search-engine';


class ApiSearchEngine extends SearchEngine {

	constructor({api, action = 'search'}) {
		super();
		this.api = api;
		this.action = action;
	}


	search(query, tags = [], page = 1) {
		const params = Object.assign({search: query, tags: tags, page: page}, ('additionalParams' in this && this.additionalParams ? this.additionalParams : {}));
		return this.api.execute('search/search', {params: params}).then((response) => {
			if (response.isSuccess()) {
				return response.output;
			} else {
				console.error('search not valid', response);
			}
			return {};
		}).catch((error) => {
			console.error('search error', error);
		});
	}


	track(search, tags, page) {
		const params = Object.assign({search: search, tags: tags, page: page, source: location.pathname}, ('additionalParams' in this && this.additionalParams ? this.additionalParams : {}));
		return this.api.execute('search/track', {params: params});
	}


}


export default ApiSearchEngine;
