import DefaultPage from './default-page';
import OverlayPage from './overlay-page';
import ApiPage from './api-page';
import HomePage from './home/home-page';
import ErrorPage from './error/error-page';
import RegisterPage from './account/register-page';
import ProjectsPage from './project/projects-page';
import EditProjectPage from './project/edit-project-page';
import PreviewProjectPage from './project/preview-project-page';

import RegisterPageLoader from './account/register-page-loader';
import ProjectsPageLoader from './project/projects-page-loader';
import EditProjectPageLoader from './project/edit-project-page-loader';
import PreviewProjectPageLoader from './project/preview-project-page-loader';

import DefaultPageTransition from './default-page-transition';
import OverlayPageTransition from './overlay-page-transition';


export default (di) => {
	di
		.setType({
			type: DefaultPage, name: 'DefaultPage', parent: 'Page', setters: {
				injectIntersectionTriggers: di.lazyNew('IntersectionTriggers'),
				injectParallaxBackgrounds: di.lazyNew('ParallaxBackgrounds')
			}
		})
		.setType({type: OverlayPage, name: 'OverlayPage', parent: 'DefaultPage'})
		.setType({
			type: ApiPage, name: 'ApiPage', parent: 'DefaultPage', mixins: [
				'templateMixin',
				'localeMixin',
				'accountMixin',
				'apiMixin',
				'dialogMixin',
				'notificationsMixin'
			]
		})

		.setType({type: HomePage, name: 'HomePage', parent: 'DefaultPage'})
		.setType({type: RegisterPage, name: 'RegisterPage', parent: 'ApiPage'})
		.setType({type: ErrorPage, name: 'ErrorPage', parent: 'DefaultPage'})

		.setType({type: ProjectsPage, name: 'ProjectsPage', parent: 'ApiPage'})
		.setType({type: EditProjectPage, name: 'EditProjectPage', parent: 'ApiPage'})
		.setType({type: PreviewProjectPage, name: 'PreviewProjectPage', parent: 'ApiPage'})

		.setType({type: RegisterPageLoader, name: 'RegisterPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: ProjectsPageLoader, name: 'ProjectsPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: EditProjectPageLoader, name: 'EditProjectPageLoader', parent: 'ClientRenderedPageLoader'})
		.setType({type: PreviewProjectPageLoader, name: 'PreviewProjectPageLoader', parent: 'ClientRenderedPageLoader'})

		.setType({type: DefaultPageTransition, name: 'DefaultPageTransition', parent: 'PageTransition'})
		.setType({type: OverlayPageTransition, name: 'OverlayPageTransition', parent: 'PageTransition'})
		;
};
