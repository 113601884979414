export default function tpl(tags, params) {
	const selectedTags = tags.filter((tag) => parseInt(tag.selected, 10));
	const unselectedTags = tags.filter((tag) => !parseInt(tag.selected, 10));
	return `
		<div ${this.html.classAttr('search__tags', 'tagsList')}>
			${this.cond(selectedTags.length, this.render('search/tagsItems', selectedTags, params, null, true))}
			${this.cond(unselectedTags.length, this.render('search/tagsItems', unselectedTags, params, params.tagsLimit, false))}
		</div>
	`;
}
