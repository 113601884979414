import {TweenMax} from 'gsap';
import PageComponent from '../../common/component/page-component';


class ProjectTabs extends PageComponent {

	constructor({element, root, currentClass = 'current', progressAnimationDuration = 0.4}) {
		super({element: element, root: root});
		this.currentClass = currentClass;
		this.progressAnimationDuration = progressAnimationDuration;
	}

	prepare(element, data) {
		this.tabs = new Map();
		this.currentId = null;
		const tabElements = element.querySelectorAll(this.dataSelector('projectTab'));
		for (const tabElement of tabElements) {
			const id = String(this.dataAttr(tabElement).get('projectTab'));
			if (this.classList(tabElement).contains(this.currentClass)) {
				this.currentId = id;
			}
			this.tabs.set(id, {element: tabElement, progressElement: tabElement.querySelector(this.dataSelector('projectTabProgress'))});
		}
	}


	clear() {
		this.tabs.clear();
	}


	updateProgress(id, percent) {
		if (this.tabs.has(id)) {
			TweenMax.to(this.tabs.get(id).progressElement, this.progressAnimationDuration, {scaleX: percent});
		}
	}


	switchTo(newId) {
		this.classList(this.tabs.get(this.currentId).element).remove(this.currentClass);
		this.classList(this.tabs.get(newId).element).add(this.currentClass);
		this.currentId = newId;
		return Promise.resolve();
	}

}


export default ProjectTabs;
