export default function (section, index, current) {
	return `
		<li ${this.html.classAttr('projectTabs__item', 'projectTab', current ? 'js-current' : '')} ${this.html.dataAttr({projectTab: String(section.id)})}>
			<button ${this.html.classAttr('projectTab__action')} type="button" ${this.html.dataAttr({goTo: String(section.id)})}>
				<span ${this.html.classAttr('projectTab__label')}>
					<span ${this.html.classAttr('projectTab__index')}>${index + 1}.</span>
					<span ${this.html.classAttr('projectTab__name')}>${this.escape(section.title)}</span>
				</span>
				<span ${this.html.classAttr('projectTab__progress')} ${this.html.dataAttr({projectTabProgress: String(section.id)})}></span>
			</button>
		</li>
	`;
}
