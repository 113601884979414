import Project from './project';
import ProjectTabs from './project-tabs';
import ProjectSections from './project-sections';
import ProjectNav from './project-nav';
import CharsCounters from './chars-counters';


export default (di) => {
	di
		.setType({
			type: Project, name: 'Project', parent: 'PageComponent', mixins: ['apiMixin', 'notificationsMixin', 'localeMixin'], setters: {
				injectNavigation: di.lazyGet('navigation/navigation'),
				injectCharsCounters: di.lazyNew('CharsCounters')
			}
		})
		.setType({type: ProjectTabs, name: 'ProjectTabs', parent: 'PageComponent'})
		.setType({type: ProjectSections, name: 'ProjectSections', parent: 'PageComponent'})
		.setType({type: ProjectNav, name: 'ProjectNav', parent: 'PageComponent'})
		.setType({type: CharsCounters, name: 'CharsCounters', mixins: ['domMixin']})
		;
};
