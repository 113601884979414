const SUCCESS = 'success';
const ERROR = 'error';

const AUTHENTICATED = 'authenticated';
const AUTHORIZED = 'authorized';
const FOUND = 'found';
const CREATED = 'created';
const DELETED = 'deleted';
const UPDATED = 'updated';
const VALID = 'valid';

const NOT_AUTHENTICATED = 'notAuthenticated';
const UNAUTHORIZED = 'unauthorized';
const FORBIDDEN = 'forbidden';
const NOT_FOUND = 'notFound';
const NOT_CREATED = 'notCreated';
const NOT_DELETED = 'notDeleted';
const NOT_UPDATED = 'notUpdated';
const NOT_VALID = 'notValid';
const FAILURE = 'failure';


const responseMethods = {
	isSuccess: function() {
		return this.status === SUCCESS;
	},

	isError: function() {
		return this.status === ERROR;
	},

	isAuthenticated: function() {
		return this.statusDetail === AUTHENTICATED;
	},

	isAuthorized: function() {
		return this.statusDetail === AUTHORIZED;
	},

	isFound: function() {
		return this.statusDetail === FOUND;
	},

	isCreated: function() {
		return this.statusDetail === CREATED;
	},

	isUpdated: function() {
		return this.statusDetail === UPDATED;
	},

	isDeleted: function() {
		return this.statusDetail === DELETED;
	},

	isValid: function() {
		return this.statusDetail === VALID;
	},

	isNotAuthenticated: function() {
		return this.statusDetail === NOT_AUTHENTICATED;
	},

	isUnauthorized: function() {
		return this.statusDetail === UNAUTHORIZED;
	},

	isForbidden: function() {
		return this.statusDetail === FORBIDDEN;
	},

	isNotFound: function() {
		return this.statusDetail === NOT_FOUND;
	},

	isNotCreated: function() {
		return this.statusDetail === NOT_CREATED;
	},

	isNotDeleted: function() {
		return this.statusDetail === NOT_DELETED;
	},

	isNotUpdated: function() {
		return this.statusDetail === NOT_UPDATED;
	},

	isNotValid: function() {
		return this.statusDetail === NOT_VALID;
	},

	isFailure: function() {
		return this.statusDetail === FAILURE;
	}

};



const responsePayloadMixin = (Base = null) => class extends Base {

	prepareResponse(response) {
		response = super.prepareResponse(response);
        return Object.assign({}, responseMethods, response);
    }

};


export default responsePayloadMixin;

