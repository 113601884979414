import ClientRenderedPageLoader from '../../../common/page/client-rendered-page-loader';


class RegisterPageLoader extends ClientRenderedPageLoader {


	requestPage(request) {
		const requestParams = this.prepareRequestParams(request);
		return new Promise((resolve) => {
			this.api.execute('getForm', {name: 'account/register'}).then((response) => {
				const content = this.template.render(
					'page/account/register',
					'register',
					requestParams,
					['page', 'page--register'],
					response.output.form
				);
				resolve({content: content, title: requestParams.title});
			}).catch((error) => {
				resolve(false);
			});
		});
	}


	prepareRequestParams(request) {
		const requestParams = {};
		for (const name in request.params) {
			if (request.params.hasOwnProperty(name) && request.params[name] !== undefined) {
				requestParams[name] = request.params[name];
			}
		}
		return requestParams;
	}

}


export default RegisterPageLoader;
