import Page from '../../common/page/page';


class DefaultPage extends Page {


	injectIntersectionTriggers(intersectionTriggers) {
		this.intersectionTriggers = intersectionTriggers;
	}


	injectParallaxBackgrounds(parallaxBackgrounds) {
		this.parallaxBackgrounds = parallaxBackgrounds;
	}


	onLoad() {
		if (!this.dataAttr(this.element).get('isAccountPage', false)) {
			this.classList(this.root).remove('account');
		}

		this.params = this.dataAttr(this.element).get('params', {});

		const parallaxBgSelector = this.dataSelector('blockBgImg');
		const parallaxPrefix = 'html:not(.ms) ';
		const parallaxSuffix = this.dataSelector('blockHasBgImg', true);
		const parallaxSelectors = [
			[parallaxPrefix + this.dataSelector('blockType', 'teaser') + parallaxSuffix, parallaxBgSelector],
			[parallaxPrefix + this.dataSelector('blockType', 'search') + parallaxSuffix, parallaxBgSelector]
		];
		this.parallaxBackgrounds.init(this.element, parallaxSelectors);

		const intersectionSelectors = [
			this.dataSelector('blockTile'),
			this.dataSelector('animateOnIntersection')
		];
		this.intersectionTriggers.init(this.element, intersectionSelectors);

		return super.onLoad();
	}


	onUnload() {
		this.parallaxBackgrounds.destroy();
		this.intersectionTriggers.destroy();
		return super.onUnload();
	}

}

export default DefaultPage;
