export default function (form) {
	return `
	<p>Profile Page</p>

	${this.render(
		'form/wrapper',
		'user/profile',
		['form--profile'],
		{},
		this.render('form/fields', form.fields) + `
		<div class="${this.html.classes('form__buttons')}">
			<button class="${this.html.classes('form__button', 'button', 'button--submit')}" type="submit">
				${this.text.get('account/profileSubmit')}
			</button>
		</div>
		`
	)}
	`;
}
