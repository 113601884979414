import FrontendApi from './frontend-api';
import ErrorListener from './error-listener';


export default (di) => {
	di
		.setType({type: FrontendApi, name: 'FrontendApi', parent: 'Api', mixins: ['responsePayloadMixin']})
		.setType({type: ErrorListener, name: 'ErrorListener', params: {
				events: di.lazyGet('events/events')
			}
		})

		.set('api/errorListener', di.lazyNew('ErrorListener'))
		.set('api/api', di.lazyNew('FrontendApi'))
	;
};
