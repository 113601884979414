export default function tpl(element, params) {
	return `
		<div ${this.html.classAttr('preview')}>
			${this.cond(element.category.length, `<p ${this.html.classAttr('preview__category')}>${this.escape(element.category)}</p>`)}
			<h3 ${this.html.classAttr('preview__title')}>${this.escape(element.title)}</h3>
			${this.cond(element.img.length, `
				<div ${this.html.classAttr('preview__thumb')}>
					<a href="${this.escape(this.url.getUrl(element.uri))}" ${element.linkData ? this.html.dataAttr(JSON.parse(element.linkData)) : ''}>
						<figure>
							<img src="${this.escape(element.img)}" alt="${this.escape(element.img_alt)}" />
							${element.img_caption.length ? `
								<figcaption>${this.escape(element.img_caption)}</figcaption>
							` : ''}
						</figure>
					</a>
				</div>
			`)}

			<div ${this.html.classAttr('preview__description')}>
				${this.cond(element.description.length, `
					<p>${this.escape(element.description)}</p>
				`)}
				<p ${this.html.classAttr('preview__link', 'ugc')}><a href="${this.escape(this.url.getUrl(element.uri))}" ${element.linkData ? this.html.dataAttr(JSON.parse(element.linkData)) : ''}>${this.escape(params.texts.linkLabel)}</a></p>
			</div>
		</div>
	`;
}
