export default function (entry) {
	entry.default = this.date.format('Y-m-d H:i', entry.default);
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper', `
			${this.html.open({tag: 'div', classes: ['field__datetimeWrapper']})}
				${this.html.open({tag: 'div', classes: ['field__datetimeDate']})}
					${this.html.element({
						tag: 'input',
						attributes: Object.assign({}, entry.attributes, {
							type: 'date',
							name: entry.inputName,
							value: entry.default.split(' ')[0],
							id: entry.id
						}),
						classes: ['field__input', 'field__input--date']
					})}
				${this.html.close()}
				${this.html.open({tag: 'div', classes: ['field__datetimeTime']})}
					${this.html.element({
						tag: 'input',
						attributes: Object.assign({}, entry.attributes, {
							type: 'time',
							name: entry.inputName + '_time',
							value: entry.default.split(' ')[1],
							id: entry.id + '_time'
						}),
						classes: ['field__input', 'field__input--time']
					})}
				${this.html.close()}
			${this.html.close()}
		`)}
	`;
}
