export default function (entry) {
	if ('default' in entry) {
		for (const name in entry.default) {
			if (entry.default.hasOwnProperty(name) && name in entry.fields) {
				entry.fields[name].default = entry.default[name];
			}
		}
	}
	return `
		${this.render('form/field/label', entry)}
		<div ${this.html.classAttr('field__fields')} ${this.html.dataAttr({fields: null})}>
			<div ${this.html.classAttr('field__imagefileFieldWrapper')}>
				${this.loop(entry.fields, (field, name) => (field.type === 'imagefile' ? this.render('form/field', name, field) : ''))}
			</div>
			<div ${this.html.classAttr('field__imageFieldsWrapper')}>
				${this.loop(entry.fields, (field, name) => (field.type !== 'imagefile' ? this.render('form/field', name, field) : ''))}
			</div>
		</div>
	`;
}
