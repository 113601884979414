import {TweenMax} from 'gsap';
import keymaster from 'keymaster';
import {getScrollTop} from 'get-scroll';
import getOffsetTop from '../../common/utils/offset-top';
import onTransitionEnd from '../../common/dom/transition-end';
import Search from '../../common/search/search';
import componentConstants from '../../common/component/_constants';


class LdiSearch extends Search {

	constructor({
		element,
		root,
		relPath,
		projectUrls,
		resultsTpl = 'search/results',
		waitTime = 400, // ms
		qsChar = '?',
		qsSearchName = 's',
		qsPageName = 'p',
		qsTagsName = 't',
		bodySearchModeClass = 'searchMode',
		searchReadyClass = 'searchReady',
		searchPreparingClass = 'searchPreparing',
		placeholderClass = 'placeholder',
		hiddenClass = 'hidden',
		overlayClass = 'overlay',
		closeOnKey = 'esc',
		thumbOptions = [400]
	}) {
		super({
			element: element,
			root: root,
			resultsTpl: resultsTpl,
			waitTime: waitTime,
			qsChar: qsChar,
			qsSearchName: qsSearchName,
			qsPageName: qsPageName,
			qsTagsName: qsTagsName
		});
		this.bodySearchModeClass = bodySearchModeClass;
		this.placeholderClass = placeholderClass;
		this.hiddenClass = hiddenClass;
		this.overlayClass = overlayClass;
		this.searchPreparingClass = searchPreparingClass;
		this.searchReadyClass = searchReadyClass;
		this.tracking = false;
		this.trackingFrame = null;
		this.lastPosition = null;
		this.closeOnKey = closeOnKey;
		this.relPath = relPath;
		this.thumbOptions = thumbOptions;
		this.projectUrls = projectUrls;
	}


	prepare(element, data) {
		this.searchEngine.setAdditionalParams({
			relPath: this.relPath,
			thumbOptions: this.thumbOptions,
			projectUrls: this.projectUrls,
			lang: data.get('lang'),
			type: data.get('type')
		});
		super.prepare(element, data);
		this.clone = element.cloneNode(true);
		this.dataAttr(this.clone).remove(componentConstants.componentAttribute);
		this.cloneInput = this.clone.querySelector(this.dataSelector('searchInput'));
		const cloneId = this.cloneInput.getAttribute('id');
		this.cloneInput.setAttribute('id', cloneId + '-clone');
		const cloneLabel = this.clone.querySelector('label[for="' + cloneId + '"]');
		if (cloneLabel) {
			cloneLabel.setAttribute('for', cloneId + '-clone');
		}
		this.results = this.clone.querySelector(this.dataSelector('searchResults'));
		this.inputBox = this.clone.querySelector(this.dataSelector('searchInputBox'));
		this.listeners.blur.destroy();
		this.listeners.blur = this.events.on(this.cloneInput, 'focusout', this.onBlur.bind(this), {capture: true});
		this.listeners.change.destroy();
		this.listeners.change = this.events.on(this.cloneInput, 'input', this.onChange.bind(this));
		this.listeners.click.destroy();
		this.listeners.click = this.events.on(this.clone, this.dataSelector('searchResults') + ' a', 'click', this.onResultClick.bind(this), {capture: true});
		this.listeners.pageClick.destroy();
		this.listeners.pageClick = this.events.on(this.clone, this.dataSelector('toPage'), 'click', this.onPageClick.bind(this));
		this.listeners.tagClick.destroy();
		this.listeners.tagClick = this.events.on(this.clone, this.dataSelector('tagId'), 'click', this.onTagClick.bind(this));

		this.contentBody = document.querySelector(this.dataSelector('context', 'default'));
		this.classList(this.clone).add(this.overlayClass, this.hiddenClass);
		this.cloneInput.disabled = true;
		this.body = this.root;
		this.body.appendChild(this.clone);
	}

	getInput() {
		return this.cloneInput;
	}


	clear() {
		super.clear();
		this.disableSearch(true)
			.then(() => {
				this.body.removeChild(this.clone);
				return Promise.resolve();
			})
			;
	}


	canStart() {
		return true;
	}


	canStop() {
		return true;
		// return !this.searchActive;
	}


	focus(event) {
		this.startPositionTracking();
		this.cloneInput.disabled = false;
		this.cloneInput.focus();
		this.input.disabled = true;
	}


	blur(event) {
		if (!this.searchActive) {
			this.stopPositionTracking(true);
		}
	}


	startPositionTracking() {
		if (!this.tracking) {
			this.tracking = true;
			this.track(true);
		}
	}


	stopPositionTracking(hideElement = true) {
		if (this.tracking) {
			this.tracking = false;
			this.lastPosition = null;
			if (this.trackingFrame) {
				cancelAnimationFrame(this.trackingFrame);
				this.trackingFrame = null;
				if (hideElement) {
					this.classList(this.clone).add(this.hiddenClass);
					this.classList(this.element).remove(this.hiddenClass);
					this.cloneInput.disabled = true;
					this.input.disabled = false;
				}
			}
		}
	}


	track(showElement = false) {
		if (!this.tracking) {
			return;
		}
		const rect = this.element.getBoundingClientRect();
		const scrollTop = getScrollTop();
		const top = rect.top + scrollTop;
		if (top !== this.lastPosition) {
			this.lastPosition = top;
			TweenMax.set(this.clone, {top: top + 'px'});
		}
		if (showElement) {
			this.classList(this.clone).remove(this.hiddenClass);
			this.classList(this.element).add(this.hiddenClass);
		}
		this.trackingFrame = requestAnimationFrame(this.track.bind(this));
	}


	setSearchActive(active = true, immediate = false) {
		return super.setSearchActive(active, immediate).then(() =>
			new Promise((resolve) => {
				if (active) {
					const currentContext = this.contexts.getCurrentContext();
					currentContext.deactivate();
					keymaster.setScope('search');
					if (this.closeOnKey !== false) {
						keymaster(this.closeOnKey, 'search', () => this.onClose());
					}
					this.stopPositionTracking(false);
					const currentOffset = getOffsetTop(this.contentBody);
					const scrollPos = getScrollTop();
					this.lastScrollPos = scrollPos;
					this.classList(this.body).add(this.bodySearchModeClass);
					this.contentBody.style.top = (currentOffset - scrollPos) + 'px';
					window.scrollTo(0, 0);
					const rect = this.element.getBoundingClientRect();
					if (immediate) {
						this.classList(this.clone).add(this.searchPreparingClass, this.searchReadyClass);
						this.classList(this.clone).remove(this.hiddenClass);
						this.classList(this.element).add(this.hiddenClass);
						this.cloneInput.disabled = false;
						this.input.disabled = true;
						resolve();
					} else {
						TweenMax.set(this.clone, {top: 0, y: rect.top + 'px'});
						requestAnimationFrame(() => {
							this.classList(this.clone).add(this.searchPreparingClass);
							TweenMax.set(this.clone, {y: 0});
							onTransitionEnd(this.clone).then(() => {
								this.classList(this.clone).add(this.searchReadyClass);
								resolve();
							});
						});
					}
				} else {
					this.cloneInput.blur();
					this.classList(this.clone).remove(this.searchReadyClass, this.searchPreparingClass);
					this.classList(this.clone).add(this.hiddenClass);
					if (this.element) {
						this.classList(this.element).remove(this.hiddenClass);
					}
					this.cloneInput.disabled = true;
					this.input.disabled = false;
					requestAnimationFrame(() => {
						this.input.value = '';
						this.cloneInput.value = '';
						this.classList(this.body).remove(this.bodySearchModeClass);
						this.contentBody.style.removeProperty('top');
						window.scrollTo(0, this.lastScrollPos);

						const currentContext = this.contexts.getCurrentContext();
						keymaster.deleteScope('search');
						keymaster.setScope(currentContext.getName());
						currentContext.activate();
						resolve();
					});
				}
			})
		);
	}

}


export default LdiSearch;
