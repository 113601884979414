import Sortable from 'sortablejs';
import GroupField from './group-field';
import {ucFirst} from '../utils/string';
import formConstants from './_constants';
import componentConstants from '../component/_constants';


class ArrayField extends GroupField {


	constructor({
		root,
		element,
		name,
		type,
		autoAddFields = true,
		fieldTpl = 'form/field',
		invalidClass = 'invalid',
		errorTpl
	}) {
		super({
			root: root,
			element: element,
			name: name,
			type: type,
			autoAddFields: autoAddFields,
		});
		this.fieldTpl = fieldTpl;
		this.invalidClass = invalidClass;
		this.errorTpl = errorTpl;
	}


	isArray() {
		return true;
	}


	prepare(element, data) {
		super.prepare(element, data);
		this.fieldsContainer = element.querySelector(this.dataSelector(formConstants.fieldsSelectorAttribute));
		this.itemTemplate = this.data.get('itemTemplate');
		this.listeners.actions = this.events.on(element, this.dataSelector('action'), 'click', this.onActionClick.bind(this));

		this.isSortable = this.data.get('sortable', false);
		if (this.isSortable) {
			this.itemTemplate.sortableItem = true;
			this.sortable = Sortable.create(this.fieldsContainer, {
				// group: "name",  // or { name: "...", pull: [true, false, clone], put: [true, false, array] }
				// sort: true,  // sorting inside list
				// delay: 0, // time in milliseconds to define when the sorting should start
				// disabled: false, // Disables the sortable if set to true.
				// store: null,  // @see Store
				// animation: 150,  // ms, animation speed moving items when sorting, `0` — without animation
				handle: this.classListParser.getBaseSelector('field__sortableHandle'), //this.dataSelector('sortableHandle'),  // Drag handle selector within list items
				// filter: ".ignore-elements",  // Selectors that do not lead to dragging (String or Function)
				// preventOnFilter: true, // Call `event.preventDefault()` when triggered `filter`
				// draggable: this.fieldSelector,  // Specifies which items inside the element should be draggable
				// ghostClass: "sortable-ghost",  // Class name for the drop placeholder
				// chosenClass: "sortable-chosen",  // Class name for the chosen item
				// dragClass: "sortable-drag",  // Class name for the dragging item
				// dataIdAttr: 'data-id',

				// forceFallback: false,  // ignore the HTML5 DnD behaviour and force the fallback to kick in

				// fallbackClass: "sortable-fallback",  // Class name for the cloned DOM Element when using forceFallback
				// fallbackOnBody: false,  // Appends the cloned DOM Element into the Document's Body
				// fallbackTolerance: 0, // Specify in pixels how far the mouse should move before it's considered as a drag.

				// scroll: true, // or HTMLElement
				// scrollFn: function(offsetX, offsetY, originalEvent) { ... }, // if you have custom scrollbar scrollFn may be used for autoscrolling
				// scrollSensitivity: 30, // px, how near the mouse must be to an edge to start scrolling.
				// scrollSpeed: 10, // px

				// setData: function (/** DataTransfer */dataTransfer, /** HTMLElement*/dragEl) {
				// 	dataTransfer.setData('Text', dragEl.textContent); // `dataTransfer` object of HTML5 DragEvent
				// },

				// Element is chosen
				// onChoose: function (/**Event*/evt) {
				// 	evt.oldIndex;  // element index within parent
				// },

				// Element dragging started
				onStart: this.onSortStart.bind(this),

				// Element dragging ended
				onEnd: this.onSortEnd.bind(this),

				// Element is dropped into the list from another list
				// onAdd: function (/**Event*/evt) {
				// 	// same properties as onEnd
				// },

				// Changed sorting within list
				// onUpdate: function (/**Event*/evt) {
				// 	// same properties as onEnd
				// },

				// Called by any change to the list (add / update / remove)
				// onSort: function (/**Event*/evt) {
				// 	// same properties as onEnd
				// },

				// Element is removed from the list into another list
				// onRemove: function (/**Event*/evt) {
				// 	// same properties as onEnd
				// },

				// Attempt to drag a filtered element
				// onFilter: function (/**Event*/evt) {
				// 	var itemEl = evt.item;  // HTMLElement receiving the `mousedown|tapstart` event.
				// },

				// Event when you move an item in the list or between lists
				// onMove: function (/**Event*/evt, /**Event*/originalEvent) {
				// 	// Example: http://jsbin.com/tuyafe/1/edit?js,output
				// 	evt.dragged; // dragged HTMLElement
				// 	evt.draggedRect; // TextRectangle {left, top, right и bottom}
				// 	evt.related; // HTMLElement on which have guided
				// 	evt.relatedRect; // TextRectangle
				// 	originalEvent.clientY; // mouse position
				// 	// return false; — for cancel
				// },

				// Called when creating a clone of element
				onClone: this.onSortClone.bind(this)
			});
		} else {
			this.sortable = null;
		}
	}


	onActionClick(event, target) {
		// prevent other groups up in the tree to handle the same action
		event.stopPropagation();
		const action = this.dataAttr(target).get('action');
		const method = 'on' + ucFirst(action);
		if (method in this) {
			this[method](event, target);
		}
		target.blur();
	}


	onAdd(event, target) {
		const name = this.count();
		const element = this.addNewItem(name);
		const field = this.addField(this.getComponent(element));
		field.init(element);
	}


	onRemove(event, target) {
		const fieldElement = target.closest(this.dataSelector(formConstants.fieldSelectorAttribute));
		for (let i = 0, end = this.fields.length; i < end; i++) {
			const field = this.fields[i];
			if (fieldElement === field.getElement()) {
				this.removeField(field);
				break;
			}
		}
	}


	onSortStart(event) {
		const components = this.queryComponents(this.dataSelector(componentConstants.componentAttribute));
		for (const component of components) {
			component.setSurviveDetached(true);
		}
	}


	onSortClone(event) {
		this.dataAttr(event.clone).remove(componentConstants.componentAttribute);
		const subComponents = event.clone.querySelectorAll(this.dataSelector(componentConstants.componentAttribute));
		for (const subComponent of subComponents) {
			this.dataAttr(subComponent).remove(componentConstants.componentAttribute);
		}
	}


	onSortEnd(event) {
		const oldIndex = event.oldIndex;
		const newIndex = event.newIndex;
		if (oldIndex !== newIndex) {
			this.moveItem(oldIndex, newIndex);
		}
		const components = this.queryComponents(this.dataSelector(componentConstants.componentAttribute));
		for (const component of components) {
			component.setSurviveDetached(false);
		}
	}


	moveItem(oldIndex, newIndex) {
		const item = this.fields[oldIndex];
		const dir = newIndex > oldIndex ? 1 : -1;
		const start = oldIndex + dir;
		const end = newIndex + dir;
		const offset = -dir;
		for (let i = start; i !== end; i += dir) {
			const newPos = i + offset;
			this.fields[newPos] = this.fields[i];
			this.fields[newPos].setName(newPos);
		}
		this.fields[newIndex] = item;
		item.setName(newIndex);
	}


	addNewItem(name) {
		const item = this.getNewItem(name);
		this.fieldsContainer.appendChild(item);
		return item;
	}


	getNewItem(name) {
		let container = document.createElement('div');
		container.innerHTML = this.template.render(this.fieldTpl, name, Object.assign({}, this.itemTemplate));
		const item = container.querySelector('*');
		container.removeChild(item);
		container = null;
		return item;
	}

}


export default ArrayField;
