export default function tpl(elements, tags, params) {
	return `
		${this.cond(!params.count && params.inputSearch.length, this.render('search/noResults', params), `
			${this.cond(tags && tags.length, () => this.render('search/tags', tags, params))}
			<ul ${this.html.classAttr('previewList__items', 'search__resultsItems')}>
				${this.loop(elements, (element) => `
					<li ${this.html.classAttr('previewList__item', 'search__resultsItem')}>
						${this.render('search/resultsItem', element, params)}
					</li>
				`)}
			</ul>
			${this.render('search/pagination', params.paginationItems)}
		`)}
	`;
}
