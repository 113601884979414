import App from './app';

export default (di) => {
	di.setType({type: App, name: 'App', mixins: ['domMixin', 'navigationMixin', 'componentsMixin', 'contextsMixin'], setters: {
			injectFontChecker: di.lazyGet('preload/fontChecker'),
			injectStyleChecker: di.lazyGet('preload/styleChecker'),
			injectIconFilesLoader: di.lazyGet('preload/iconFilesLoader'),
			injectPages: di.lazyGet('page/pages')
		}
	});
};
