import lottie from 'lottie-web';
import PageComponent from '../component/page-component';


class LottieAnimation extends PageComponent {

	constructor({root, element, loadedClass = 'loaded', renderer = 'svg', autoload = true, autoplay = false, loop = false, direction = 1, speed = 1, unit = 'frame'}) {
		super({root: root, element: element});
		this.defaults.loadedClass = loadedClass;
		this.defaults.renderer = renderer;
		this.defaults.autoload = autoload;
		this.defaults.autoplay = autoplay;
		this.defaults.loop = loop;
		this.defaults.direction = direction;
		this.defaults.speed = speed;
		this.defaults.unit = unit;
		this.animation = null;
		this.loading = false;
		this.loaded = false;
		this.wasPaused = null;
		this.timeout = null;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.src = data.src;
		this.loadedClass = data.loadedClass;
		this.renderer = data.renderer;
		this.autoload = !!data.autoload;
		this.autoplay = !!data.autoplay;
		this.loop = (data.loop === -1 ? true : data.loop);
		this.direction = (data.direction === -1 ? -1 : 1);
		this.speed = Number(data.speed);
		this.unit = (data.unit.toLowerCase() === 'frame' ? 'frame' : 'time');

		this.openAsyncEvent('load');
		if (this.autoload) {
			this.load();
		}
	}


	clear() {
		if (this.animation) {
			this.animation.destroy();
		}
	}


	start(first) {
		if (!first && this.animation && !this.wasPaused) {
			this.animation.play();
		}
	}


	stop() {
		if (this.animation) {
			this.wasPaused = this.animation.isPaused;
			if (!this.wasPaused) {
				this.animation.pause();
			}
		}
	}


	load() {
		if (!this.loading) {
			this.loading = true;

			this.animation = lottie.loadAnimation({
				container: this.element, // the dom element that will contain the animation
				renderer: this.renderer,
				loop: this.loop,
				autoplay: this.autoplay,
				path: this.src // the path to the animation json
			});

			this.animation.setSpeed(this.speed);
			this.animation.setDirection(this.direction);

			this.animation.addEventListener('DOMLoaded', () => this.onAnimationEvent('DOMLoaded'));
			this.animation.addEventListener('complete', () => this.onAnimationEvent('complete'));
			this.animation.addEventListener('loopComplete', () => this.onAnimationEvent('loopcomplete'));
		}
		return this.on('load');
	}


	onAnimationEvent(eventName) {
		if (eventName === 'DOMLoaded') {
			// fix IE issue: is unable to properly evaluate the height, falling back on 150px
			const svg = this.element.querySelector('svg');
			if (svg) {
				const width = svg.getAttribute('width');
				const height = svg.getAttribute('height');
				const canvas = document.createElement('canvas');
				canvas.setAttribute('width', width);
				canvas.setAttribute('height', height);
				canvas.style.position = 'relative';
				canvas.style.width = '100%';
				canvas.style.height = 'auto';
				canvas.style.zIndex = 2;
				this.element.insertBefore(canvas, svg);
				svg.style.position = 'absolute';
				svg.style.top = 0;
				svg.style.left = 0;
				svg.style.zIndex = 1;
			}

			this.animation.setSpeed(this.speed);
			this.animation.setDirection(this.direction);
			this.loaded = true;
			this.closeAsyncEvent('load');
			this.classList(this.element).add(this.loadedClass);
			this.events.trigger(this.element, 'animation:load', {component: this});
		} else {
			this.events.trigger(this.element, 'animation:' + eventName, {component: this});
		}
	}


	setUnit(unit) {
		this.unit = (unit.toLowerCase() === 'frame' ? 'frame' : 'time');
		return this;
	}


	getUnit() {
		return this.unit;
	}


	setSpeed(speed) {
		this.speed = speed;
		if (this.animation) {
			this.animation.setSpeed(speed);
		}
		return this;
	}


	goToAndStop(value) {
		if (this.animation) {
			this.animation.goToAndStop(value, this.unit === 'frame');
			return this;
		}
		throw new Error('Animation not loaded yet');
	}


	goToAndPlay(value) {
		if (this.animation) {
			this.animation.goToAndPlay(value, this.unit === 'frame');
			return this;
		}
		throw new Error('Animation not loaded yet');
	}


	setDirection(direction) {
		this.direction = direction;
		if (this.animation) {
			this.animation.setDirection(direction);
		}
		return this;
	}


	getDirection() {
		return this.direction;
	}


	getSpeed() {
		return this.speed;
	}


	getDuration() {
		if (this.loaded) {
			return this.animation.getDuration(this.unit === 'frame');
		}

		throw new Error('Animation not loaded yet');
	}


	play() {
		if (this.animation) {
			this.animation.play();
			return this;
		}
		throw new Error('Animation not loaded yet');
	}


	pause() {
		if (this.animation) {
			this.animation.pause();
			return this;
		}
		throw new Error('Animation not loaded yet');
	}


	setProgress(value) {
		// time based goToAndStop seems to not work properly, it works using frames
		let position = Math.max(0, Math.min(1, value)) * this.getDuration();
		const method = this.animation.isPaused ? 'goToAndStop' : 'goToAndPlay';
		if (this.unit === 'frame') {
			position = Math.round(position);
		}
		this[method](position);
		return this;
	}

}


export default LottieAnimation;
