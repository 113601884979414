import {TweenMax, TimelineMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import PageComponent from '../../common/component/page-component';
import apiMixin from '../../common/api/api-mixin';
import notificationsMixin from '../../common/notifications/notifications-mixin';
import localeMixin from '../../common/locale/locale-mixin';


class Project extends localeMixin(notificationsMixin(apiMixin(PageComponent))) {

	constructor({element, root, bodyClass = 'editProject', invalidClass = 'invalid', sectionSelector = 'section', scrollDuration = 0.4, checkDuration = 1.5, checkAnimationDuration = 0.4}) {
		super({element: element, root: root});
		this.bodyClass = bodyClass;
		this.invalidClass = invalidClass;
		this.sectionSelector = sectionSelector;
		this.scrollDuration = scrollDuration;
		this.checkDuration = checkDuration;
		this.checkAnimationDuration = checkAnimationDuration;
	}


	injectNavigation(navigation) {
		this.navigation = navigation;
	}


	injectCharsCounters(charsCounters) {
		this.charsCounters = charsCounters;
	}


	prepare(element, data) {
		if (data.get('projectClosing') > 0) {
			this.beginTime = Date.now() / 1000;
			this.expireInterval = setInterval(this.onExpireInterval.bind(this), 500);
		} else {
			this.expireInterval = null;
		}

		// add target blank to links
		const links = element.querySelectorAll(this.dataSelector('section') + ' a[href]');
		for (const link of links) {
			link.setAttribute('target', '_blank');
			link.setAttribute('rel', 'noopener');
		}

		this.id = this.dataAttr(element).get('project');
		this.previewUrl = data.get('previewUrl');
		this.requiredCount = 0;
		this.filledCount = 0;
		this.fields = new Map();
		this.sections = new Map();
		this.infoElement = element.querySelector(this.dataSelector('projectInfo'));
		const fields = this.dataAttr(element).get('fields');
		for (const entry of fields) {
			if (String(entry.sectionId) !== '0') {
				const field = {id: entry.fieldId, sectionId: entry.sectionId, required: !!parseInt(entry.required, 10), filled: !!parseInt(entry.filled, 10)};
				this.fields.set(entry.fieldId, field);
				const sectionId = entry.sectionId;
				if (!this.sections.has(sectionId)) {
					this.sections.set(sectionId, {id: sectionId, requiredCount: 0, filledCount: 0});
				}
				const section = this.sections.get(sectionId);
				if (field.required) {
					section.requiredCount++;
					this.requiredCount++;
					if (field.filled) {
						section.filledCount++;
						this.filledCount++;
					}
				}
			}
		}
		this.progressValue = {value: 0};
		this.progressAnimation = null;
		this.projectProgress = element.querySelector(this.dataSelector('projectProgress'));
		this.projectSections = this.getComponent('projectSections');
		this.projectTabs = this.getComponent('projectTabs');
		this.projectNav = this.getComponent('projectNav');
		this.form = this.getComponent('form');
		this.charsCounters.init(element);
		this.classList(this.root).add(this.bodyClass);
		this.listeners.fieldChange = this.events.on(element, 'field:change', this.onFieldChange.bind(this));
		this.listeners.navigation = this.events.on(element, this.dataSelector('goTo'), 'click', this.onNavigation.bind(this));
		this.listeners.submit = this.events.on(element.querySelector(this.dataSelector('projectSubmit')), 'click', this.onSubmit.bind(this));
		this.currentId = String(this.dataAttr(element.querySelector(this.classSelector('current') + this.dataSelector('projectNavItem'))).get('projectNavItem'));
		this.changingSection = false;
	}


	clear() {
		if (this.expireInterval !== null) {
			clearInterval(this.expireInterval);
		}
		this.classList(document.body).remove(this.bodyClass);
		this.charsCounters.destroy();
		this.fields.clear();
		this.sections.clear();
	}


	onExpireInterval() {
		const diff = (Date.now() / 1000) - this.beginTime;
		if (diff >= this.data.get('projectClosing')) {
			clearInterval(this.expireInterval);
			this.expireInterval = null;
			this.expire();
		}
	}


	expire() {
		this.notify({type: 'error', text: this.data.get('expireMessage'), duration: 10});
		this.navigation.redirect(this.data.get('backUrl'));
	}


	start() {
		for (const id of this.sections.keys()) {
			this.updateTabProgress(id);
		}
		this.updateGlobalProgress();
	}


	onNavigation(event, target) {
		target.blur();
		const newId = String(this.dataAttr(target).get('goTo'));
		this.navigateTo(newId);
	}


	navigateTo(newId, scrollToTop = true) {
		if (!this.changingSection && newId !== this.currentId) {
			this.changingSection = true;
			const promises = [
				this.projectSections.switchTo(newId, scrollToTop),
				this.projectTabs.switchTo(newId),
				this.projectNav.switchTo(newId)
			];
			return Promise.all(promises).then(() => {
				this.currentId = newId;
				this.changingSection = false;
			});
		}
		return Promise.resolve();
	}


	onSubmit(event, target) {
		target.blur();
		this.api.execute('project/submit', {element: {projectId: this.id}}).then((response) => {
			if (response.isSuccess()) {
				const submitMessage = ('submitMessage' in response.output ? response.output.submitMessage : this.locale.get('project/submitted').replace('\n', '<br />'));
				const submitMessageDuration = ('submitMessageDuration' in response.output && response.output.submitMessageDuration > 0 ? response.output.submitMessageDuration : 10);
				this.notify({type: 'success', html: submitMessage, duration: submitMessageDuration});
				this.navigation.redirect(this.locale.format(this.previewUrl, {id: this.id}));
			} else {
				if (response.isNotValid()) {
					this.form.setValueAndErrors(response.output.element);
					const firstInvalidFieldElement = this.element.querySelector(this.classSelector(this.invalidClass));
					if (firstInvalidFieldElement) {
						const invalidSectionElement = firstInvalidFieldElement.closest(this.dataSelector(this.sectionSelector));
						const invalidSectionId = String(this.dataAttr(invalidSectionElement).get(this.sectionSelector));
						this.navigateTo(invalidSectionId, false).then(() => {
							TweenMax.to(window, this.scrollDuration, {
								scrollTo: {
									y: firstInvalidFieldElement,
									autoKill: false,
									offsetY: this.infoElement.getBoundingClientRect().height
								}
							});
						});
					}
				}
				if ('expired' in response.output && response.output.expired) {
					this.expire();
				}
				console.log('error', response);
			}
			return response;
		}).catch((error) => {
			console.log('submit error', error);
		});
	}


	onFieldChange(event, target) {
		const fieldComponent = event.detail.component;
		const fieldParams = fieldComponent.data.getAll();
		const value = fieldComponent.getValue();

		const values = {
			projectId: this.id,
			parentId: ('parentId' in fieldParams ? fieldParams.parentId : '0'),
			fieldId: fieldComponent.getName()
		};
		const options = {serialize: true};
		let upload = false;
		if ('hasFiles' in fieldComponent && 'getFiles' in fieldComponent && fieldComponent.hasFiles()) {
			upload = true;
			options.files = [];
			values.value = '';
			options.files.push({name: fieldComponent.getName(), file: fieldComponent.getFiles().item(0)});
			options.onProgress = fieldComponent.onProgress.bind(fieldComponent);
		} else {
			values.value = value;
		}
		this.api.execute('project/editField', {element: values}, options).then((response) => {
			if (response.isSuccess()) {
				const fieldId = String(response.output.fieldId);
				const parentId = String(response.output.parentId);
				const filled = response.output.filled;
				const targetId = parentId !== '0' ? parentId : fieldId;
				const newValue = response.output.value;
				fieldComponent.setValue(newValue);
				fieldComponent.resetErrors();
				this.charsCounters.updateByName(fieldId);
				if (this.fields.has(targetId)) {
					const field = this.fields.get(targetId);
					if (field.required) {
						if (field.filled !== filled) {
							const offset = filled ? 1 : -1;
							field.filled = filled;
							const section = this.sections.get(field.sectionId);
							this.filledCount += offset;
							section.filledCount += offset;
							this.updateGlobalProgress();
							this.updateTabProgress(field.sectionId);
						}
					}
				}

				this.dataStoredVisualFeedback(fieldComponent);
			} else {
				if (response.isNotValid()) {
					this.form.setValueAndErrors(response.output.element);
				}
				if (upload) {
					fieldComponent.resetUploadProcess();
				}

				if ('expired' in response.output && response.output.expired) {
					this.expire();
				}
			}
			return response;
		}).catch((error) => {
			console.log('error', error);
			if (upload) {
				fieldComponent.resetUploadProcess();
			}
		});
	}


	updateTabProgress(id) {
		const section = this.sections.get(id);
		this.projectTabs.updateProgress(id, section.requiredCount === 0 ? 1 : section.filledCount / section.requiredCount);
	}


	updateGlobalProgress() {
		if (this.progressAnimation) {
			this.progressAnimation.pause();
		}
		const newProgress = this.requiredCount === 0 ? 100 : Math.round(this.filledCount / this.requiredCount * 100);
		const update = () => {
			this.projectProgress.innerHTML = this.progressValue.value;
		};
		this.progressAnimation = TweenMax.to(
			this.progressValue,
			0.5,
			{value: newProgress, roundProps: 'value', onUpdate: update, onComplete: update}
		);
	}


	dataStoredVisualFeedback(component) {
		const element = component.getElement();
		const checkIcon = element.querySelector(this.dataSelector('checkIcon'));
		if (checkIcon) {
			const width = checkIcon.getBoundingClientRect().width;
			const timeline = new TimelineMax({
				onComplete: () => {
					checkIcon.style.removeProperty('clip');
					checkIcon.style.removeProperty('visibility');
					checkIcon.style.removeProperty('opacity');
				}
			});
			const value = {progress: 0};
			timeline
				.to(checkIcon, 0, {visibility: 'visible'}, 0)
				.to(value, this.checkAnimationDuration, {
					progress: width, onUpdate: () => {
						checkIcon.style.clip = 'rect(auto, ' + value.progress + 'px, auto, auto)';
					}
				}, 0)
				.to(checkIcon, 0.4, {opacity: 0}, '+=' + this.checkDuration);
			timeline.play();
		}
	}

}


export default Project;
