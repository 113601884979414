import ClientRenderedPageLoader from '../../../common/page/client-rendered-page-loader';


class ProjectsPageLoader extends ClientRenderedPageLoader {

	requestPage(request) {
		const requestParams = this.prepareRequestParams(request);
		return this.api.execute('project/projects',	{}).then((response) => {
			if (response.isSuccess()) {
				const content = this.template.render(
					'page/project/projects',
					'projects',
					requestParams,
					['page', 'page--projects'],
					response.output.projects,
					response.output.form,
					response.output.contestDescriptions,
					response.output.introText
				);
				return {content: content, title: requestParams.title};
			}
			return false;
		}).catch((error) => {
			console.log('catching error', error);
			return false;
		});
	}


	prepareRequestParams(request) {
		const requestParams = {};
		for (const name in request.params) {
			if (request.params.hasOwnProperty(name) && request.params[name] !== undefined) {
				requestParams[name] = request.params[name];
			}
		}
		return requestParams;
	}

}


export default ProjectsPageLoader;
