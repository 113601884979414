import Context from '../context/context';


class DialogContext extends Context {

	constructor({name, root, template, defaultTpl = 'dialog/dialog', showClass = 'active'}) {
		super({name: name, root: root, popOnKey: false});
		this.template = template;
		this.defaultTpl = defaultTpl;
		this.showClass = showClass;
		this.defaultOptions = {
			closeOnKey: false,
			closeButton: false,
			closeValue: false,
			template: defaultTpl,
			buttonSyncHandler: null
		};
		this.promise = null;
		this.resolveTrigger = null;
		this.closeValue = null;
		this.currentDialog = null;
	}


	init() {
		this.clickHandler = this.events.on(this.getElement(), this.dataSelector('dialogButtonValue'), 'click', this.onButtonClick.bind(this));
		// if (!this.contexts.hasContext(this)) {
		// 	this.contexts.addContext(this);
		// }
	}


	open({title = '', text = '', buttons = [], options = {}} = {}) {
		for (const button of buttons) {
			button.data = {dialogButtonValue: ('value' in button ? button.value : null)};
		}
		const dialog = Object.assign({}, this.defaultOptions, options, {
			title: title,
			text: text,
			buttons: buttons
		});
		this.currentDialog = dialog;

		this.closeValue = dialog.closeValue;
		if (this.resolveTrigger !== null) {
			this.resolveTrigger();
		}
		this.promise = new Promise((resolve) => {
			this.resolveTrigger = resolve;
		});
		this.popOnKey = dialog.closeOnKey;
		const element = this.getElement();
		element.innerHTML = this.template.render(dialog.template, dialog);
		if (this.contexts.getCurrentContext() !== this) {
			this.contexts.push(this.name);
		}
		return this.promise;
	}


	close() {
		if (this.contexts.getCurrentContext() === this) {
			return this.contexts.pop();
		}
		return Promise.resolve();
	}


	onButtonClick(event, target) {
		this.closeValue = this.dataAttr(target).get('dialogButtonValue');
		if (this.currentDialog.buttonSyncHandler) {
			this.currentDialog.buttonSyncHandler(event, target, this.closeValue);
		}
		this.close();
	}


	switchIn(otherContext) {
		return this.threeStateTransition(this.getElement()).add(this.showClass);
	}


	switchOut(otherContext) {
		this.resolveTrigger(this.closeValue);
		return this.threeStateTransition(this.getElement()).remove(this.showClass);
	}

}


export default DialogContext;
