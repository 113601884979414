export default function (entry) {
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/field/inputWrapper', this.html.element({
			tag: 'textarea',
			attributes: Object.assign({}, entry.attributes, {
				name: entry.inputName,
				id: entry.id
			}),
			classes: ['field__input', 'field__input--tags'],
			content: this.escape(Array.isArray(entry.default) ? entry.default.join(', ') : entry.default)
		}))}
	`;
}
