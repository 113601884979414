import Cookies from 'js-cookie';
import domMixin from '../../common/dom/dom-mixin';


class HighContrast extends domMixin() {

	constructor({
		cookieName = 'highContrast',
		cookieDayDuration = 30,
		enabledClass = 'highContrast',
		enableSelector = '[href="#enableHighContrast"]',
		disableSelector = '[href="#disableHighContrast"]',
	} = {}) {
		super();
		this.cookieName = cookieName;
		this.cookieDayDuration = cookieDayDuration;
		this.enabledClass = enabledClass;
		this.enableSelector = enableSelector;
		this.disableSelector = disableSelector;
	}


	init() {
		this.update(!!Cookies.get(this.cookieName));
		this.enableListener = this.events.on(document, this.enableSelector, 'click', this.onEnable.bind(this), {capture: true});
		this.disableListener = this.events.on(document, this.disableSelector, 'click', this.onDisable.bind(this), {capture: true});
	}


	destroy() {
		this.enableListener.destroy();
		this.disableListener.destroy();
	}


	onEnable(event) {
		event.preventDefault();
		this.update(true);
		Cookies.set(this.cookieName, 1, {expires: this.cookieDayDuration});
	}


	onDisable(event) {
		event.preventDefault();
		this.update(false);
		Cookies.remove(this.cookieName);
	}


	update(enabled) {
		this.classList(document.body).toggle(this.enabledClass, enabled);
	}

}


export default HighContrast;
