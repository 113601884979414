export default function (entry) {
	return `
		${this.render('form/field/inputWrapper', `
			<div ${this.html.classAttr('field__image')}>
				<div ${this.html.classAttr('field__imgPlaceholder')}>
					<div ${this.html.classAttr('field__imgPlaceholderLabel')}>
						${entry.label}
					</div>
				</div>
				${this.html.element({tag: 'img', classes: ['field__img'], data: {imagefileImg: null}, attributes: {alt: ''}})}
				${this.html.element({tag: 'img', classes: ['field__img', 'field__img--progress'], data: {imagefileImgProgress: null}, attributes: {alt: ''}})}
				<button ${this.html.classAttr('field__removeImage', 'button', 'button--removeImage')} ${this.html.dataAttr({removeFile: null})} type="button">
					<span ${this.html.classAttr('button__icon')}>${this.html.svgIcon('close')}</span>
				</button>
			</div>
			<div ${this.html.classAttr('field__fileInfo')} ${this.html.dataAttr({info: null})}>
			</div>

			<span ${this.html.classAttr('field__imageUpload', 'button', 'button--upload')}>
				${this.html.element({tag: 'span', classes: ['button__label'], content: this.text.get('project/imageUpload')})}
				${this.html.element({
					tag: 'input',
					attributes: Object.assign({}, entry.attributes, {
						type: 'file',
						name: entry.inputName,
						id: entry.id,
						'aria-label': this.text.get('project/upload')
					}),
					classes: ['field__input', 'field__input--file'],
					data: {fileInfo: entry.default}
				})}
			</span>
			<div ${this.html.classAttr('field__limitsInfo')}>${this.text.get('project/fileLimitsInfo', entry.data.limitsInfo)}</div>
		`)}
	`;
}
