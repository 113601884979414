import 'intersection-observer';
import svg4everybody from 'svg4everybody';
import picturefill from 'picturefill';
// import 'picturefill/src/plugins/mutation/pf.mutation';
import App from '../../common/app/app';


class LdiApp extends App {

	injectCurrentLinkManager(currentLinkManager) {
		this.currentLinkManager = currentLinkManager;
	}


	injectErrorListener(errorListener) {
		this.errorListener = errorListener;
	}


	injectNavigationTracker(tracker) {
		this.tracker = tracker;
		this.tracker.init();
	}

	injectHashScroller(hashScroller) {
		this.hashScroller = hashScroller;
	}


	injectPageSlots(pageSlots) {
		this.pageSlots = pageSlots;
	}


	injectHighContrast(highContrast) {
		this.highContrast = highContrast;
		highContrast.init();
	}


	injectLocaleDynamicTranslator(dynamicTranslator) {
		this.dynamicTranslator = dynamicTranslator;
	}


	init() {
		svg4everybody();
		picturefill();
		this.contexts.setDefaultContext('default', false);
		return super.init().then(() => {
			this.accountManager = this.getComponent('accountManager');
			return this.accountManager.on('ready').then(() => {
				this.loader = this.getComponent('appLoader');
			});
		});
	}


	execute() {
		const start = () => {
			if (this.pageSlots.get('overlay').getCurrentPage()) {
				this.contexts.push('overlay');
			} else {
				this.contexts.getContext('default').activate();
			}
			this.currentLinkManager.update();
			const hash = location.hash;
			if (hash.length) {
				this.events.trigger(document.body, 'history:hashchange', {hash: hash.substr(1)});
			}
		};

		this.pages.initCurrent().then(() => {
			if (this.loader) {
				this.loader.hide().then(start);
			} else {
				start();
			}
		});
	}

}

export default LdiApp;
