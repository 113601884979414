import 'matchmedia-polyfill';
import Context from '../../common/context/context';


class MenuContext extends Context {

	constructor({name, root, mediaQuery, popOnKey = 'esc', openClass = 'open', fixedClass = 'fixed', inClass = 'active'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.mediaQuery = mediaQuery;
		this.openClass = openClass;
		this.fixedClass = fixedClass;
		this.inClass = inClass;
		this.nextSibling = null;
		this.parentNode = null;
	}


	beforeSwitchIn(otherContext) {
		// Level
		this.raiseLevel();
		return otherContext;
	}


	switchIn(otherContext) {
		const element = this.getElement();
		this.classList(element).add(this.openClass);
		return this.threeStateTransition(element).add(this.inClass);
	}

	afterSwitchIn(otherContext) {
		this.resizeLisener = this.events.on(window, 'window:resize', this.onResize.bind(this));
		return otherContext;
	}


	switchOut(otherContext) {
		const element = this.getElement();
		this.classList(element).add(this.fixedClass);
		this.classList(element).remove(this.openClass);

		return this.threeStateTransition(element).remove(this.inClass).then(() => {
			this.classList(element).remove(this.fixedClass);
			// Level
			this.restoreLevel();
			return otherContext;
		});
	}


	afterSwitchOut(otherContext) {
		this.resizeLisener.destroy();
		return otherContext;
	}


	raiseLevel() {
		const element = this.getElement();
		this.nextSibling = element.nextSibling;
		this.parentNode = element.parentNode;
		element.parentNode.removeChild(element);
		document.body.appendChild(element);
		return this;
	}


	restoreLevel() {
		const element = this.getElement();
		document.body.removeChild(element);
		if (this.nextSibling) {
			this.parentNode.insertBefore(element, this.nextSibling);
		} else {
			this.parentNode.appendChild(element);
		}
		return this;
	}


	onResize(event) {
		if (!matchMedia(this.mediaQuery).matches) {
			if (this.contexts.getCurrentContext().getName() === 'menu') {
				this.contexts.pop();
			}
		}
	}
}


export default MenuContext;
