import baseFieldMixin from './base-field-mixin';
import interactiveFieldMixin from './interactive-field-mixin';
import fieldsGroupMixin from './fields-group-mixin';
import discoverFieldsMixin from './discover-fields-mixin';
import langSelectorMixin from './lang-selector-mixin';
import Form from './form';
import BaseField from './base-field';
import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';
import TextField from './text-field';
import SearchField from './search-field';
import PasswordField from './password-field';
import TextareaField from './textarea-field';
import SelectField from './select-field';
import CheckboxField from './checkbox-field';
import ThreestatecheckboxField from './threestatecheckbox-field';
import RadioField from './radio-field';
import HiddenField from './hidden-field';
import TagsField from './tags-field';
import FileField from './file-field';
import ImagefileField from './imagefile-field';
import DatetimeField from './datetime-field';
import GroupField from './group-field';
import ArrayField from './array-field';
import FieldResetAction from './field-reset-action';

import LangSelectorRadioField from './lang-selector-radio-field';


export default (di) => {
	di
		.setMixin({
			mixin: baseFieldMixin, name: 'baseFieldMixin', setters: {
				injectTemplate: di.lazyGet('template/template')
			}
		})

		.setMixin({mixin: interactiveFieldMixin, name: 'interactiveFieldMixin'})

		.setMixin({
			mixin: langSelectorMixin, name: 'langSelectorMixin', initCall: 'initLangSelector', setters: {
				injectLocaleText: di.lazyGet('locale/localeText'),
				injectApi: di.lazyGet('api/api')
			}
		})

		.setType({
			type: BaseField, name: 'BaseField', parent: 'PageComponent', mixins: ['baseFieldMixin'], params: {
				errorTpl: di.lazyValue('form/field/errorTpl', 'form/field/error')
			}
		})

		.setType({type: InteractiveField, name: 'InteractiveField', parent: 'BaseField', mixins: ['interactiveFieldMixin']})

		.setMixin({mixin: textFieldMixin, name: 'textFieldMixin'})

		.setMixin({mixin: fieldsGroupMixin, name: 'fieldsGroupMixin'})

		.setMixin({mixin: discoverFieldsMixin, name: 'discoverFieldsMixin'})

		.setType({type: TextField, name: 'TextField', parent: 'InteractiveField', mixins: ['textFieldMixin']})
		.setType({type: SearchField, name: 'SearchField', parent: 'TextField'})
		.setType({type: PasswordField, name: 'PasswordField', parent: 'InteractiveField', mixins: ['textFieldMixin']})
		.setType({type: TextareaField, name: 'TextareaField', parent: 'InteractiveField', mixins: ['textFieldMixin']})
		.setType({type: TagsField, name: 'TagsField', parent: 'InteractiveField', mixins: ['textFieldMixin']})
		.setType({type: SelectField, name: 'SelectField', parent: 'InteractiveField'})
		.setType({type: CheckboxField, name: 'CheckboxField', parent: 'InteractiveField'})
		.setType({type: ThreestatecheckboxField, name: 'ThreestatecheckboxField', parent: 'CheckboxField'})
		.setType({type: RadioField, name: 'RadioField', parent: 'InteractiveField'})
		.setType({type: DatetimeField, name: 'DatetimeField', parent: 'InteractiveField'})
		.setType({type: HiddenField, name: 'HiddenField', parent: 'InteractiveField'})
		.setType({type: FileField, name: 'FileField', parent: 'InteractiveField', mixins: ['localeMixin'], params: {
			baseMediaUrl: di.lazyValue('baseMediaUrl', '')
		}})
		.setType({
			type: ImagefileField, name: 'ImagefileField', parent: 'FileField', params: {
				baseThumbUrl: di.lazyValue('baseThumbUrl', '')
			}
		})

		.setType({type: LangSelectorRadioField, name: 'LangSelectorRadioField', parent: 'RadioField', mixins: ['langSelectorMixin']})


		.setType({type: Form, name: 'Form', mixins: ['fieldsGroupMixin', 'discoverFieldsMixin'], parent: 'PageComponent'})

		.setType({
			type: GroupField, name: 'GroupField', mixins: ['baseFieldMixin', 'fieldsGroupMixin', 'discoverFieldsMixin'], parent: 'PageComponent', params: {
				errorTpl: di.lazyValue('form/field/errorTpl', 'form/field/error')
			}
		})

		.setType({type: ArrayField, name: 'ArrayField', parent: 'GroupField'})

		.setType({type: FieldResetAction, name: 'FieldResetAction', parent: 'PageComponent'})
	;
};
