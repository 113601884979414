export default function (dialog) {
	return this.html.element({
		tag: 'div',
		classes: ['dialog'],
		content: this.html.element({tag: 'div', classes: ['dialog__container'], content:
			(dialog.title.length ? this.html.element({tag: 'h2', classes: ['dialog__title'], content: this.escape(dialog.title)}) : '') +
			(dialog.text.length ? this.html.element({tag: 'div', classes: ['dialog__text'], content: this.escape(dialog.text)}) : '') +
			(dialog.buttons.length ? this.render('dialog/buttons', dialog.buttons) : '')
		})
	});
}
