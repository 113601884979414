class Account {

	constructor() {
		this.loggedIn = false;
		this.info = {};
	}


	isLoggedIn() {
		return this.loggedIn;
	}


	get(name = null) {
		if (name === null) {
			return this.info;
		}
		if (name in this.info) {
			return this.info[name];
		}
		return null;
	}


	update(info = {}) {
		if (info === null) {
			info = {};
		}
		this.info = Object.assign({}, info);
		this.loggedIn = ('id' in this.info && this.info.id !== null);
		return this;
	}

}

export default Account;
