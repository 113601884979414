export default function(pageName, pageParams, pageClasses, projectId, project, sections, fields, form, backUrl, previewUrl) {
	this.wrap('page/wrapper', pageName, pageParams, pageClasses);
	let sectionId = null;
	let sectionIndex = -1;
	let firstSectionId = null;
	const sectionsMap = {};
	return `
		<div ${this.html.classAttr('project')} ${this.html.dataAttr({
			component: 'project',
			project: projectId,
			fields: fields,
			previewUrl: previewUrl,
			backUrl: backUrl,
			projectClosing: +project.test ? 0 : project.closingDiff,
			expireMessage: project.expireMessage
		})}>
			<div ${this.html.classAttr('project__info')} ${this.html.dataAttr({projectInfo: null})}>
				<div ${this.html.classAttr('project__progress')}>
					<span ${this.html.dataAttr({projectProgress: null})}>0</span>%
				</div>
				<div ${this.html.classAttr('project__tabs', 'projectTabs')} ${this.html.dataAttr({component: 'projectTabs'})}>
					<ul ${this.html.classAttr('projectTabs__items')}>
						${this.loop(sections, (section, index) => {
			sectionsMap[String(section.id)] = section;
			if (firstSectionId === null) {
				firstSectionId = String(section.id);
			}
			return this.render('project/tab', section, index, firstSectionId === String(section.id));
		})}
					</ul>
				</div>
			</div>
			${this.render('form/formOpen', form, 'sectionForm')}
				<div ${this.html.classAttr('project__sections')} ${this.html.dataAttr({component: 'projectSections'})}>
					${this.loop(form.fields, (field, name) => {
			const fieldSectionId = field.sectionId;
			let printOpen = false;
			let printClose = false;
			if (fieldSectionId !== sectionId) {
				printOpen = true;
				if (sectionIndex >= 0) {
					printClose = true;
				}
				sectionId = fieldSectionId;
				sectionIndex++;
			}
			return `
							${printClose ? '</div></div>' : ''}
							${printOpen ? `
								<div ${this.html.classAttr('project__section', 'projectSection', String(sectionId) === firstSectionId ? 'js-current' : '')} ${this.html.dataAttr({section: String(sectionId), index: sectionIndex})}>
									${this.cond(sectionsMap[sectionId].description.length, () => `
										<div ${this.html.classAttr('projectSection__description', 'ugc')}>
											${sectionsMap[sectionId].description}
										</div>
									`)}
									<div ${this.html.classAttr('form__fields', 'projectSection__fields')}>
							` : ''}
							${this.render('form/field', name, field)}
						`;
		})}
					</div>
						${this.render('form/actions', {
			submit: {
				align: 'start',
				actions: {
					submit: {
						attributes: {
							type: 'button'
						},
						data: {
							projectSubmit: projectId
						},
						label: this.text.get('project/submit')
					}
				}
			}
		})}
					</div>
				</div>
			${this.render('form/formClose')}
			<div ${this.html.classAttr('project__nav', 'projectNav')} ${this.html.dataAttr({component: 'projectNav'})}>
				<ul ${this.html.classAttr('projectNav__items')}>
					${this.loop(sections, (section, index) => {
			const currentId = String(section.id);
			const prev = index > 0 ? {title: sections[index - 1].title, index: index - 1} : null;
			const prevId = prev ? String(sections[prev.index].id) : null;
			const next = index < sections.length - 1 ? {title: sections[index + 1].title, index: index + 1} : null;
			const nextId = next ? String(sections[next.index].id) : null;
			return `
							<li ${this.html.classAttr('projectNav__item', index === 0 ? 'js-current' : '')} ${this.html.dataAttr({projectNavItem: currentId})}>
								<div ${this.html.classAttr('projectNav__actionWrapper')}>
									${prev ? `
										<button ${this.html.classAttr('projectNav__action', 'button', 'button--seamless')} type="button" ${this.html.dataAttr({goTo: prevId})}>
											<span ${this.html.classAttr('projectNav__actionIcon', 'button__icon')}>
												${this.html.svgIcon('left')}
											</span>
											<span ${this.html.classAttr('projectNav__actionLabel')}>
												<span ${this.html.classAttr('projectNav__actionIndex')}>${prev.index + 1}.</span>
												<span ${this.html.classAttr('projectNav__actionName')}>${this.escape(prev.title)}</span>
											</span>
										</button>
									` : ''}
								</div>
								<div ${this.html.classAttr('projectNav__actionWrapper', 'projectNav__actionWrapper--back')}>
									<a href="${this.escape(this.url.getUrl(backUrl))}" ${this.html.classAttr('projectNav__action', 'button', 'button--seamless')} type="button">
										<span ${this.html.classAttr('projectNav__actionLabel')}>
											${this.escape(this.text.get('project/back'))}
										</span>
									</a>
								</div>
								<div ${this.html.classAttr('projectNav__actionWrapper')}>
									${next ? `
										<button ${this.html.classAttr('projectNav__action', 'button', 'button--seamless')} type="button" ${this.html.dataAttr({goTo: nextId})}>
											<span ${this.html.classAttr('projectNav__actionLabel', 'button__label')}>
												<span ${this.html.classAttr('projectNav__actionIndex')}>${next.index + 1}.</span>
												<span ${this.html.classAttr('projectNav__actionName')}>${this.escape(next.title)}</span>
											</span>
											<span ${this.html.classAttr('projectNav__actionIcon', 'button__icon')}>
												${this.html.svgIcon('right')}
											</span>
										</button>
									` : ''}
								</div>
							</li>
						`;
		})}
				</ul>
			</div>

		</div>
	`;
}
