export default function (actions) {
	return `
		<div class="${this.html.classes('form__actions')}">
			<ul class="${this.html.classes('form__actionsItems')}">
				${this.loop(actions, (group, name, groups) => `
					<li class="${this.html.classes(...['form__actionsGroup', 'form__actionsGroup--' + name].concat(Object.keys(groups).length === 1 ? ['form__actionsGroup--full'] : []))}">
						<ul class="${this.html.classes(...['form__actionsGroupItems'].concat('align' in group ? ['form__actionsGroupItems--align' + this.string.ucFirst(group.align)] : []))}">
							${this.loop(group.actions || [], (action, actionName) => `
								<li class="${this.html.classes('form__actionItem', 'form__actionItem--' + actionName)}">
									${this.render('action', action)}
								</li>
							`)}
						</ul>
					</li>
				`)}
			</ul>
		</div>
	`;
}
