export default function(name, entry) {
	if (!('name' in entry)) {
		entry.name = name;
	}
	entry.inputName = this.id.create(entry.name + '_');
	entry.id = entry.inputName;
	if (!('default' in entry)) {
		entry.default = '';
	}
	if (!('type' in entry)) {
		entry.type = 'text';
	}
	if (!('disabled' in entry)) {
		entry.disabled = false;
	}
	if (!('required' in entry)) {
		entry.required = false;
	}
	if (!('attributes' in entry)) {
		entry.attributes = {};
	}

	if (entry.type === 'hidden') {
		return this.render('form/field/hidden', entry);
	}
	const classes = ['field', 'field--' + entry.type].concat('classes' in entry ? entry.classes : []);
	const data = Object.assign({component: entry.type + 'Field', field: entry.name, fieldType: entry.type}, ('data' in entry ? entry.data : {}));
	if (entry.type === 'group' || entry.type === 'array') {
		data.fieldsGroup = entry.name;
		if (entry.type === 'array') {
			entry.itemTemplate.type = 'group';
			data.itemTemplate = entry.itemTemplate;
			if ('sortable' in entry && entry.sortable) {
				data.sortable = true;
			}
		} else {
			if ('sortableItem' in entry && entry.sortableItem) {
				classes.push('field--sortableItem');
			}
		}
	}
	if ('actions' in entry) {
		classes.push('field--hasActions');
	}
	if (entry.disabled) {
		classes.push('field--disabled');
		entry.attributes.disabled = 'disabled';
	}
	classes.push(entry.required ? 'field--required' : 'field--optional');

	return `
		<div ${this.html.classAttr(classes)} ${this.html.dataAttr(data)}>
			<div ${this.html.classAttr('field__container')}>
				${this.render('form/field/preField', entry)}
				<div ${this.html.classAttr(['field__content', ('actions' in entry ? 'field__content--hasActions' : '')])}>
					${'sortableItem' in entry && entry.sortableItem ? this.render('form/sortableHandle') : ''}
					${this.render('form/field/' + entry.type, entry)}
					${'actions' in entry ? this.render('form/fieldActions', entry.actions, entry) : ''}
				</div>
				<div ${this.html.classAttr('field__extra')}>
					${this.render('form/field/errors')}
					${'data' in entry && 'charsCounterMax' in entry.data ? this.render('form/field/charsCounter', entry.data.charsCounterMax) : ''}
				</div>
				${this.render('form/field/postField', entry)}
			</div>
		</div>
	`;
}
