import FrontendAccount from './frontend-account';
import LdiAccountManager from './ldi-account-manager';


export default (di) => {
	di
		.setType({type: FrontendAccount, name: 'FrontendAccount', parent: 'Account'})
		.set('account/account', di.lazyNew('FrontendAccount'))
		.setType({
			type: LdiAccountManager, name: 'LdiAccountManager', parent: 'AccountManager', params: {
				registerUrl: di.lazyValue('account/registerUrl')
			}
		})
		;
};
