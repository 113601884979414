import PageComponent from '../../common/component/page-component';


class PrintBtn extends PageComponent {

	prepare(element, data) {
		this.listeners.click = this.events.on(element, 'click', this.onClick.bind(this), {capture: false});
	}


	onClick(event, target) {
		event.preventDefault();
		window.print();
		target.blur();
	}


}


export default PrintBtn;
