import domMixin from '../../common/dom/dom-mixin';


class CharsCounters extends domMixin() {

	constructor({negativeClass = 'negative'}) {
		super();
		this.negativeClass = negativeClass;
	}


	init(element) {
		this.countersByInput = new Map();
		this.countersByName = new Map();
		const charsCounters = element.querySelectorAll(this.dataSelector('charsCounter'));
		for (const charsCounter of charsCounters) {
			const maxValue = this.dataAttr(charsCounter).get('charsCounter');
			const charsCounterValue = charsCounter.querySelector(this.dataSelector('charsCounterValue'));
			const field = charsCounter.closest(this.dataSelector('field'));
			const name = String(this.dataAttr(field).get('field'));
			const type = this.dataAttr(field).get('fieldType');
			const inputSelector = type === 'textarea' ? 'textarea' : 'input';
			const input = field.querySelector(inputSelector);
			const counter = {
				element: charsCounter,
				valueElement: charsCounterValue,
				value: 0,
				input: input,
				maxValue: maxValue
			};
			this.countersByName.set(name, counter);
			this.countersByInput.set(input, counter);
			this.update(counter);
		}

		this.inputListener = this.events.on(element, 'input', 'input', this.onInput.bind(this));
		this.textareaListener = this.events.on(element, 'textarea', 'input', this.onInput.bind(this));
	}


	destroy() {
		this.inputListener.destroy();
		this.textareaListener.destroy();
		this.countersByName.clear();
		this.countersByInput.clear();
	}


	onInput(event, target) {
		this.updateByInput(target);
	}


	updateByInput(input) {
		if (this.countersByInput.has(input)) {
			this.update(this.countersByInput.get(input));
		}
	}


	updateByName(name) {
		name = String(name);
		if (this.countersByName.has(name)) {
			this.update(this.countersByName.get(name));
		}
	}


	update(counter) {
		const newValue = counter.input.value.length;
		const toggle = (counter.value < 0 && newValue >= 0) || (counter.value >= 0 && newValue < 0);
		counter.value = newValue;
		counter.valueElement.innerHTML = counter.maxValue - counter.value;
		if (toggle) {
			this.classList(counter.element).toggle(this.negativeClass, counter.value < 0);
		}
		return this;
	}

}


export default CharsCounters;
