module.exports={
	"projectsPageTitle": "Übersicht",
	"editPageTitle": "Projekt bearbeiten",
	"previewPageTitle": "Projekt Vorschau",

	"upload": "Auswählen",
	"imageUpload": "Bild auswählen",
	"delete": "Löschen",
	"back": "Zurück zur Übersicht",
	"submit": "Einreichen",
	"edit": "Bearbeiten",
	"preview": "Vorschau",
	"yes": "Ja",
	"no": "Nein",
	"cancel": "Abbrechen",
	"noProjects": "Sie haben noch kein Projekt angelegt.",
	"submitted": "Wir haben Ihre Bewerbung erhalten. Vielen Dank!\nWenn Sie sich unter www.land-der-ideen.de/bewerbung einloggen, können Sie Ihre Bewerbung weiterhin einsehen.\nNach der Entscheidung der Jury werden die Preisträger  auf www.land-der-ideen.de bekannt gegeben.\nWir wünschen Ihnen mit Ihrer Bewerbung viel Erfolg!\n\nIhr Projektteam „Ausgezeichnete Orte im Land der Ideen“",

	"fileLimitsInfo": "Formate: {formats}. Max Dateigröße: {maxSize}"
}
