export default function (entry) {
	if ('default' in entry && Array.isArray(entry.default)) {
		entry.fields = [];
		for (let i = 0, end = entry.default.length; i < end; i++) {
			// deep clone
			const groupEntry = JSON.parse(JSON.stringify(entry.itemTemplate));
			if ('sortable' in entry && entry.sortable) {
				groupEntry.sortableItem = true;
			}
			groupEntry.default = entry.default[i];
			entry.fields.push(groupEntry);
		}
	}
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/fields', 'fields' in entry ?  entry.fields : [], 'field')}
	`;
}
