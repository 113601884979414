import phpdate from 'phpdate-js';
import TemplateHelper from './template-helper';


class DateHelper extends TemplateHelper {

	// format a date using PHP date() format syntax
	format(format, value = null) {
        if (value === null || String(value).toLowerCase() === 'now') {
            value = new Date();
        } else if (!(value instanceof Date)) {
			value = new Date(value.replace(/\s/, 'T'));
		}
        return phpdate(format, value);
    }

}

export default DateHelper;
