import ClassToggler from './class-toggler';
// import Accordion from './accordion';
// import Tabs from './tabs';
// import ContextToggler from './context-toggler';
import Collapsable from './collapsable';
import Toggler from './toggler';
// import ContentSlider from './content-slider';
// import ContentSliderNavigation from './content-slider-navigation';


export default (di) => {
	di
		.setType({type: ClassToggler, name: 'ClassToggler', parent: 'PageComponent'})
		.setType({type: Collapsable, name: 'Collapsable', parent: 'PageComponent'})
		.setType({type: Toggler, name: 'Toggler', parent: 'PageComponent'})
	;
};
