import Account from './account';
import AccountManager from './account-manager';
import accountMixin from './account-mixin';


export default (di) => {
	di
		.setType({
			type: Account, name: 'Account', params: {
				events: di.lazyGet('events/events'),
				api: di.lazyGet('api/api')
			}
		})
		.setType({
			type: AccountManager, name: 'AccountManager', parent: 'PageComponent', mixins: [
				'templateMixin',
				'localeMixin',
				'apiMixin',
				'notificationsMixin'
			], params: {
				defaultUrl: di.lazyValue('account/defaultUrl', ''),
				loggedDefaultUrl: di.lazyValue('account/loggedDefaultUrl', '')
			}, setters: {
				injectAccount: di.lazyGet('account/account'),
				injectNavigation: di.lazyGet('navigation/navigation'),
				injectPages: di.lazyGet('page/pages')
			}
		})
		.set('account/account', di.lazyNew('Account'))

		.setMixin({
			mixin: accountMixin, name: 'accountMixin', setters: {
				injectAccount: di.lazyGet('account/account')
			}
		})
		;
};
