import ApiPage from '../api-page';


class ProjectsPage extends ApiPage {

	constructor({root, element, url = '', title = '', currentClass = 'current'}) {
		super({root: root, element: element, url: url, title: title});
		this.currentClass = currentClass;
	}


	onLoad() {
		const expireLinks = this.element.querySelectorAll(this.dataSelector('projectClosing'));
		this.expireLinks = new Map();
		for (const expireLink of expireLinks) {
			const closing = this.dataAttr(expireLink).get('projectClosing');
			if (closing > 0) {
				this.expireLinks.set(expireLink, closing);
			}
		}

		if (this.expireLinks.size) {
			this.beginTime = Date.now() / 1000;
			this.expireInterval = setInterval(this.onExpireInterval.bind(this), 500);
		} else {
			this.expireInterval = null;
		}
		return super.onLoad().then(() => {
			const form = this.getComponent('form');
			if (form) {
				this.form = form;
				this.submitListener = this.events.on(this.element, 'form:submit', this.onFormSubmit.bind(this));
				if (this.form !== null) {
					this.contestField = this.form.getField('contestId');
					this.currentId = String(this.contestField.getValue());
					this.descriptions = new Map();
					this.descriptionsElement = null;
					const elements = this.element.querySelectorAll(this.dataSelector('contestDescription'));
					for (const element of elements) {
						const id = String(this.dataAttr(element).get('contestDescription'));
						this.descriptions.set(id, element);
						if (!this.descriptionsElement) {
							this.descriptionsElement = element.parentNode;
						}
					}
					this.classList(this.descriptions.get(this.currentId)).add(this.currentClass);
					this.contestListener = this.events.on(this.contestField.getElement(), 'field:change', this.onContestChange.bind(this));
				}
			} else {
				this.form = null;
			}

			const introLinks = this.element.querySelectorAll(this.dataSelector('introText') + ' a');
			for (const link of introLinks) {
				link.setAttribute('target', '_blank');
				link.setAttribute('rel', 'noopener');
			}
		});
	}


	onUnload() {
		if (this.expireInterval !== null) {
			clearInterval(this.expireInterval);
		}
		if (this.submitListener) {
			this.submitListener.destroy();
		}
		if (this.contestListener) {
			this.contestListener.destroy();
			this.descriptions.clear();
		}
	}


	onExpireInterval() {
		const diff = (Date.now() / 1000) - this.beginTime;
		for (const [expireLink, closing] of this.expireLinks) {
			if (diff >= closing) {
				expireLink.parentNode.removeChild(expireLink);
				this.expireLinks.delete(expireLink);
			}
		}
		if (this.expireLinks.size === 0) {
			clearInterval(this.expireInterval);
			this.expireInterval = null;
		}
	}


	onContestChange(event) {
		this.contestChanged(this.contestField.getValue());
	}


	contestChanged(newId) {
		return new Promise((resolve) => {
			const current = this.descriptions.get(this.currentId);
			const next = this.descriptions.get(newId);
			const height = Math.max(current.getBoundingClientRect().height, next.getBoundingClientRect().height);
			this.descriptionsElement.style.height = height + 'px';
			this.classList(current).remove(this.currentClass);
			this.classList(next).add(this.currentClass);
			this.onTransitionEnd(next).then(() => {
				this.descriptionsElement.style.removeProperty('height');
				this.currentId = newId;
				resolve();
			});
		});
	}


	onFormSubmit(event) {
		this.submit('edit');
	}


	submit(redirectType) {
		const form = this.form;
		const values = form.getValue();
		form.disableSubmit();
		return this.api.execute('project/add', {element: values}).then((response) => {
			if (response.isSuccess()) {
				const redirectUrl = this.locale.format(this.params.editUrl, response.output.element);
				this.navigation.redirect(redirectUrl);
			} else {
				if (response.isNotValid()) {
					form.setValueAndErrors(response.output.element);
				}
			}
			return response;
		}).catch((error) => {
			console.log('submit error', error);
		}).then(() => {
			form.enableSubmit();
		});
	}



}


export default ProjectsPage;
