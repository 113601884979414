module.exports={
	"notValid": "Invalid input",

    "required": "Dies ist ein Pflichtfeld",
    "invalidEmail": "Ungültige E-Mail Addresse",
    "invalidUrl": "Diese URL ist ungültig",
    "invalidIds": "Ungültige ID Werte",
    "outOfSet": "Dieser Wert wird nicht unterstützt",
    "notInt": "Der Wert muss eine ganzzahlige Nummer sein",
    "notPosInt": "Der Wert muss eine ganzzahlige Nummer größer Null sein",
    "notZeroPosInt": "Der Wert muss eine ganzzahlige Nummer größer oder gleich Null sein",
    "minLen": "Der Eintrag sollte ein Minimum von {min} Zeichen beinhalten",
    "maxLen": "Der Eintrag sollte ein Maximum von {max} Zeichen beinhalten",
    "minMaxLen": "Die Länge des Wertes muss zwischen {min} und {max} liegen",
    "min": "Der Wert muss größer oder gleich {min} sein",
    "max": "Der Wert muss kleiner oder gleich {max} sein",
	"minMax": "Der Wert muss zwischen {min} und {max} liegen",
	"minDate": "Das Datum muss am oder nach dem {min} liegen",
	"maxDate": "Das Datum muss am oder vor dem {max} liegen",
    "invalidFormat": "Ungültiges Format",
    "notSameValue": "Bitte geben Sie exakt den vorherigen Wert ein",
	"elementDuplicated": "Es existiert bereits ein Element mit dem selben Wert",
	"invalidDate": "Ungültiges Datum",

    "checkRequired": "Sie müssen die Angabe bestätigen",
    "imageNotValid": "Das Bild scheint defekt zu sein",
    "uploadNotDetected": "Beim Hochladen ist ein Fehler aufgetreten",
    "uploadTypeNotAllowed": "Das Dateiformat ist nicht erlaubt",
    "uploadContentLengthNotSupported": "Diese Datei kann nicht hochgeladen werden",
    "uploadFileEmpty": "Die Datei ist defekt",
    "uploadFileTooBig": "Die Datei ist zu groß",
    "uploadFileSizeMismatch": "Beim Hochladen ist ein Fehler aufgetreten",
    "uploadServerError": "Es ist ein Fehler aufgereten",

    "forbidden": "Zugriff verweigert!",
    "generic": "Ein unerwarteter Fehler ist aufgetreten.<br />\n Bitte versuchen Sie es später erneut."
}
