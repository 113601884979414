module.exports={
	"loginTitle": "Login",
	"activationTitle": "Aktivierung Ihres Accounts",
	"forgotPasswordTitle": "Passwort vergessen",
	"changePasswordTitle": "Passwort ändern",
	"registerTitle": "Registrierung",
	"registerDescription": "Um eine Bewerbung einreichen zu können, müssen Sie sich zunächst registrieren:",
	"profileTitle": "Profil",

	"duplicated": "Es existiert bereits ein User mit derselben E-Mail Adresse",

	"duplicatedEmailError": "Es existiert bereits ein Account mit dieser E-Mail-Adresse",

    "invalidLogin": "E-mail Adresse und / oder Passwort ist falsch",

    "activationEmailSubject": "Aktivierungscode für Ihre Registrierung unter www.land-der-ideen.de",

	"activationEmailBody": "Sehr geehrte/r Bewerber/in,\nSie haben sich unter www.land-der-ideen.de registriert. Um Ihren Account freizuschalten und den Registrierungsprozess abzuschließen, geben Sie bitte folgenden Aktivierungscode ein:\n\n{activationCode}\n\nBitte beachten Sie, dass dieser Code ungültig wird, sobald Sie über die Funktion „Aktivierungscode noch einmal senden“ einen neuen Code anfordern.\n\nWenn Sie Fragen zum Bewerbungsprozess haben, stehen wir Ihnen selbstverständlich gerne zur Verfügung. Schreiben Sie an journalistenpreis@land-der-ideen.de.\n\nMit freundlichen Grüßen Ihr Projektteam\nIhr Projektteam „Medienpreis für digitale Aufklärung 2021“",

	"changePasswordEmailSubject": "Passwort ändern",

	"changePasswordEmailBody": "Sehr geehrte/r Bewerber/in,\ndas Passwort für Ihren Account auf www.land-der-ideen.de wurde zurückgesetzt.\nBitte verwenden Sie folgenden Code, um Ihr Passwort zu ändern:\n\n{changePasswordCode}\n\nWenn Sie Fragen zum Bewerbungsprozess haben, stehen wir Ihnen selbstverständlich gerne zur Verfügung. Schreiben Sie an orte@land-der-ideen.de oder rufen Sie uns an: 030 / 23 60 78 490 (Mo-Fr 9-18 Uhr).\n\nMit freundlichen Grüßen\nIhr Projektteam „Ausgezeichnete Orte im Land der Ideen“",

    "profileEdited": "Die Änderungen wurden gespeichert.",

    "profile": "Mein Profil",

	"register": "Registrieren",
	"newRegistration": "Neu registrieren",

    "activate": "Bestätigen",

	"login": "Anmelden",
	"toLogin": "Login",

	"name": "Nutzername",
	"email": "E-Mail-Adresse",
	"password": "Passwort",
	"confirmPassword": "Passwort wiederholen",
	"newPassword": "Neues Passwort",
	"confirmNewPassword": "Neues Passwort wiederholen",
	"favouriteLang": "Sprachauswahl",

	"activationCode": "Aktivierungscode",
	"changePasswordCode": "Code",

	"activation": "Account aktivieren",

	"forgotPassword": "Passwort vergessen",

	"changePassword": "Passwort ändern",

	"submitForgotPassword": "Passwort zurücksetzen",

	"sendAgainActivationCode": "Aktivierungscode noch einmal senden",

	"passwordChanged": "",
	"invalidChangePassword": "E-mail Adresse und / oder Code ist falsch",
	"changePasswordCodeSent": "Wir haben Ihnen einen Code per E-Mail gesendet. Bitte geben Sie diesen Code unten ein, um Ihr Passwort zu ändern.",
	"activationCodeSent": "Wir haben Ihnen einen Aktivierungscode per E-Mail gesendet. Bitte geben Sie unten Ihre Anmelde-Daten sowie diesen Code ein, um Ihren Account freizuschalten.",
	"newActivationCodeSent": "Wir haben Ihnen einen neuen Aktivierungscode per E-Mail gesendet."

}
