export default function (pageName, pageParams, pageClasses, project, backUrl) {
	this.wrap('page/wrapper', pageName, pageParams, pageClasses);
	return `

		<div ${this.html.classAttr('block__pageTitle')}>
			<h2>${this.escape(project.title)}</h2>
		</div>

		<div ${this.html.classAttr('projectView')}>
			<ul ${this.html.classAttr('projectView__sections')}>
				${this.loop(project.sections, (section) => `
					<li ${this.html.classAttr('projectView__section')}>
						<h3 ${this.html.classAttr('projectView__sectionTitle')}>${this.escape(section.title)}</h3>
						<ul ${this.html.classAttr('projectView__fields')}>
							${this.loop(section.fields, (field) => `
								<li ${this.html.classAttr('projectView__field', 'projectView__field--' + field.type)}>
									${this.cond(field.type === 'image', () => {
										const subfields = field.value;
										return `
											<div ${this.html.classAttr('projectView__imageWrapper')}>
												${this.loop(subfields, (subfield) => this.cond(subfield.type === 'imagefile' && 'path' in subfield.value, () => `
													<img ${this.html.classAttr('projectView__image')} src="${this.thumb.getUrl(subfield.value.path, 768)}" />
												`))}
											</div>
											<ul ${this.html.classAttr('projectView__imageFields')}>
												${this.loop(subfields, (subfield) => this.cond(subfield.type !== 'imagefile', () => `
												<li ${this.html.classAttr('projectView__imagefield', 'projectView__imagefield--' + field.type)}>
													<div ${this.html.classAttr('projectView__imagefieldLabel')}>
														${this.escape(subfield.label)}
													</div>
													<div ${this.html.classAttr('projectView__imagefieldValue')}>
														${this.render('project/fieldValue', subfield)}
													</div>
												</li>
												`))}
											</ul>
										`;
									}, () => `
										<div ${this.html.classAttr('projectView__fieldLabel')}>
											${this.escape(field.label)}
										</div>
										<div ${this.html.classAttr('projectView__fieldValue')}>
											${this.render('project/fieldValue', field)}
										</div>
									`)}
								</li>
							`)}
						</ul>
					</li>
				`)}
			</ul>
		</div>

		<div ${this.html.classAttr('pageActions')}>
			<button ${this.html.classAttr('iconButton', 'pageActions__iconBtn')} ${this.html.dataAttr({component: 'PrintBtn'})} type="button">
				${this.html.svgIcon('print')}
			</button>
		</div>

	`;
}
