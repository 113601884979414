import TemplateHelper from './template-helper';


class ThumbHelper extends TemplateHelper {

	constructor({baseMediaUrl, baseThumbUrl}) {
		super();
		this.baseThumbUrl = baseThumbUrl;
		this.baseMediaUrl = baseMediaUrl;
    }


	getUrl(path, width = 0, height = 0, quality = 90) {
		if (width === 0 && height === 0) {
			return this.baseMediaUrl + '/' + path;
		}
		const dotPos = path.lastIndexOf('.');
		return this.baseThumbUrl + '/' + path.substr(0, dotPos) + '.' + width + '-' + height + '-' + quality + path.substr(dotPos);
    }

}

export default ThumbHelper;
