import PageComponent from '../component/page-component';
import formConstants from './_constants';


class FieldResetAction extends PageComponent {

	constructor({
		root,
		element,
		nameAttribute = formConstants.nameAttribute
	}) {
		super({root: root, element: element});
		this.nameAttribute = nameAttribute;
	}


	prepare(element, data) {
		const id = this.dataAttr().get('for');
		this.field = this.components.queryComponent(this.root, this.dataSelector(this.nameAttribute, id));
		if (this.field) {
			this.listeners.click = this.events.on(this.element, 'click', this.onClick.bind(this));
		}
	}


	clear() {
		this.field = null;
	}


	onClick(event, target) {
		target.blur();
		if (this.field) {
			this.field.resetValue();
			this.events.trigger(this.element, 'field:change', {component: this.field});
		}
	}

}


export default FieldResetAction;
