import commonConfig from './common';

import accessibilityConfig from '../accessibility/_config';
import accountConfig from '../account/_config';
import animationConfig from '../animation/_config';
import apiConfig from '../api/_config';
import appConfig from '../app/_config';
import contextConfig from '../context/_config';
import menuConfig from '../menu/_config';
import pageConfig from '../page/_config';
import printConfig from '../print/_config';
import projectConfig from '../project/_config';
import searchConfig from '../search/_config';

import langs from '../locale/langs';
import templates from '../template/templates';

const config = (di) => {
	commonConfig(di);

	accessibilityConfig(di);
	accountConfig(di);
	animationConfig(di);
	apiConfig(di);
	appConfig(di);
	contextConfig(di);
	menuConfig(di);
	pageConfig(di);
	printConfig(di);
	projectConfig(di);
	searchConfig(di);

	const prefix = 'ldi-';
	const classPrefix = 'ldi-js-';

	di
		.setValue('dom/dataAttrPrefix', prefix)
		.setValue('dom/classPrefix', classPrefix)
		.setValue('dom/baseClassPrefix', prefix)
		;

	const bodyData = di.get('dom/dataAttrFactory')({element: document.body});
	const baseUrl = bodyData.get('baseUrl');
	const apiUrl = bodyData.get('apiUrl');
	const registerUrl = bodyData.get('registerUrl');

	di
		.setValue('baseUrl', baseUrl)
		.setValue('baseThumbUrl', bodyData.get('baseThumbUrl'))
		.setValue('baseMediaUrl', bodyData.get('baseMediaUrl'))
		.setValue('relPath', bodyData.get('relPath'))
		.setValue('projectUrls', bodyData.get('projectUrls'))
		.setValue('api/baseUrl', apiUrl)
		.setValue('account/registerUrl', registerUrl)
		.setValue('account/defaultUrl', bodyData.get('accountDefaultUrl'))
		.setValue('account/loggedDefaultUrl', bodyData.get('accountLoggedDefaultUrl'))

		// we keep the translated strings in a separated files
		.setValue('locale/langs', langs)
		.setValue('locale/defaultLanguage', 'de')

		.setValue('template/tpls', templates)

		.setParam('PageLoader', 'baseUrl', baseUrl)
		//.setSetter('PageLoader', 'injectRouter', di.lazyGet('router/router'))

		.setParam('LdiApp', 'requiredIconFiles', bodyData.get('iconFiles'))

		.addPostCreateCall('Template', (template) => {
			template.registerHelpers({
				text: di.newInstance('LocaleHelper'),
				html: di.newInstance('HtmlHelper'),
				date: di.newInstance('DateHelper'),
				id: di.newInstance('IdHelper'),
				string: di.newInstance('StringHelper'),
				url: di.newInstance('UrlHelper'),
				thumb: di.newInstance('ThumbHelper')
			});
		})

		.addPostCreateCall('PageComponentFactory', (factory) => {
			factory.setAliases({
				Search: 'LdiSearch',
				AccountManager: 'LdiAccountManager'
			});
		})

		.setValue('menu/mediaQuery', '(max-width: 768px)')

		;
};


export default config;
