import CheckboxField from './checkbox-field';


class ThreestatecheckboxField extends CheckboxField {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('input[type="checkbox"]');
		}
		return this.input;
	}


	writeValue(value) {
		value = parseInt(value, 10);
		const input = this.getInput();
		input.value = value;
		this.getInput().checked = !!value;
	}


	readValue() {
		return this.getInput().value;
	}


	onChange(event) {
		const value = parseInt(this.readValue(), 10);
		this.writeValue(value === 0 ? 1 : (value === 1 ? -1 : 0));
		super.onChange(event);
	}
}


export default ThreestatecheckboxField;
