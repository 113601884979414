import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';


class PasswordField extends textFieldMixin(InteractiveField) {

}


export default PasswordField;
