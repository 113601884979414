module.exports={
	"projectsPageTitle": "Overview",
	"editPageTitle": "Project description",
	"previewPageTitle": "Preview",

	"upload": "Upload",
	"imageUpload": "Upload Image",
	"delete": "Delete",
	"back": "Back to overview",
	"submit": "Submit",
	"edit": "Edit",
	"preview": "Preview",
	"yes": "Yes",
	"no": "No",
	"noProjects": "You have not created a project yet.",
	"submitted": "We have received your application. Thank you!\nIf you log in at www.land-der-ideen.de/bewerbung, you can see your application.\nAfter the decision of the jury the winners will be announced on www.land-der-ideen.de.\nBest of luck with your application!\n\nSincerely, the project team at \"Land der Ideen“",
	"fileLimitsInfo": "Formats: {formats}. Max Size: {maxSize}"
}
