import {getScrollLeft, getScrollTop} from '../../common/utils/get-scroll';
import {getViewportWidth, getViewportHeight} from '../../common/utils/size';
import PageComponent from '../component/page-component';


class ScrollAnimationController extends PageComponent {

	constructor({root, element}) {
		super({root: root, element: element});
		this.defaults.bounds = {
			begin: {
				progress: 0,
				top: 1
			},
			end: {
				progress: 1,
				bottom: 0
			}
		};
		this.defaults.direction = 'vertical';
		this.animationRect = null;
		this.animationOffsets = null;
		this.animation = null;
		this.viewportSize = null;
		this.vertical = true;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		this.bounds = data.bounds;
		this.direction = data.direction;
		const firstLetter = this.direction.substr(0, 1).toLowerCase();
		this.vertical = firstLetter === 'v' || firstLetter === 'y';

		if ('for' in data) {
			this.animation = this.components.queryComponent(this.root, this.dataSelector('id', data.for));
			if (this.animation) {
				this.animation.on('load').then(() => {
					this.listeners.resize = this.events.on(window, 'window:resize', this.onResize.bind(this), {passive: true});
					this.updateGeometry();
					this.listeners.scroll = this.events.on(window, 'window:scroll', this.onScroll.bind(this), {passive: true});
				});
			}
		}
	}


	clear() {
		this.animation = null;
	}


	onResize(event) {
		this.updateGeometry();
	}


	onScroll(event) {
		this.updatePosition(this.vertical ? getScrollTop() : getScrollLeft());
	}


	updateGeometry() {
		this.viewportSize = (this.vertical ? getViewportHeight() : getViewportWidth());
		const animationElement = this.animation.getElement();
		this.animationRect = animationElement.getBoundingClientRect();
		this.animationOffsets = {
			left: getScrollLeft(),
			top: getScrollTop()
		};

		this.updatePosition(this.vertical ? this.animationOffsets.top : this.animationOffsets.left);
	}


	updatePosition(scrollPosition) {
		const animationSize = (this.vertical ? this.animationRect.height : this.animationRect.width);
		let beginEdge = null;
		let endEdge = null;
		if (this.vertical) {
			if ('top' in this.bounds.begin) {
				beginEdge = this.viewportSize * this.bounds.begin.top + scrollPosition;
			} else if ('bottom' in this.bounds.begin) {
				beginEdge = this.viewportSize * this.bounds.begin.bottom + scrollPosition - animationSize;
			}
			if ('top' in this.bounds.end) {
				endEdge = this.viewportSize * this.bounds.end.top + scrollPosition;
			} else if ('bottom' in this.bounds.end) {
				endEdge = this.viewportSize * this.bounds.end.bottom + scrollPosition - animationSize;
			}
		} else {
			if ('left' in this.bounds.begin) {
				beginEdge = this.viewportSize * this.bounds.begin.left + scrollPosition;
			} else if ('right' in this.bounds.begin) {
				beginEdge = this.viewportSize * this.bounds.begin.right + scrollPosition - animationSize;
			}
			if ('left' in this.bounds.end) {
				endEdge = this.viewportSize * this.bounds.end.left + scrollPosition;
			} else if ('right' in this.bounds.end) {
				endEdge = this.viewportSize * this.bounds.end.right + scrollPosition - animationSize;
			}
		}

		if (beginEdge !== null && endEdge !== null) {
			const minEdge = Math.min(beginEdge, endEdge);
			const maxEdge = Math.max(beginEdge, endEdge);
			const position = (this.vertical ? this.animationRect.top + this.animationOffsets.top : this.animationRect.left + this.animationOffsets.left);
			if (position >= minEdge && position <= maxEdge) {
				const size = maxEdge - minEdge;
				let srcRatio = (position - minEdge) / size;
				if (beginEdge > endEdge) {
					srcRatio = 1 - srcRatio;
				}
				const destRatio = Math.abs(this.bounds.end.progress - this.bounds.begin.progress);
				const correctedRatio = srcRatio * destRatio;
				const progress = (this.bounds.end.progress > this.bounds.begin.progress ? this.bounds.begin.progress + correctedRatio : this.bounds.end - correctedRatio);
				// console.log('progress', progress);
				this.animation.setProgress(progress);
			}
		}
	}



}


export default ScrollAnimationController;
