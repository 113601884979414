import templateMixin from '../template/template-mixin';
import localeMixin from '../locale/locale-mixin';
import apiMixin from '../api/api-mixin';
import PageComponent from '../component/page-component';
import notificationsMixin from '../notifications/notifications-mixin';
import {ucFirst} from '../utils/string';
import Request from '../api/request';

class AccountManager extends templateMixin(
	localeMixin(apiMixin(notificationsMixin(PageComponent)))
) {
	constructor({
		root,
		element,
		currentClass = 'current',
		formDefs = [
			{name: 'login', def: 'account/login'},
			{name: 'forgotPassword', def: 'account/forgotPassword'},
			{name: 'changePassword', def: 'account/changePassword'},
		],
		defaultUrl = '',
		loggedDefaultUrl = '',
	} = {}) {
		super({element: element, root: root});
		this.currentClass = currentClass;
		this.formDefs = formDefs;
		this.loggedDefaultUrl = loggedDefaultUrl;
		this.defaultUrl = defaultUrl;
		this.pendingRequest = null;
		this.appLoader = null;
		this.pageLoader = null;
		this.pages = null;
		this.redirectEnabled = true;
	}

	injectAccount(account) {
		this.account = account;
	}

	getAppLoader() {
		return this.appLoader;
	}

	getPageLoader() {
		return this.pageLoader;
	}

	injectNavigation(navigation) {
		this.navigation = navigation;
	}

	injectPages(pages) {
		this.pages = pages;
	}

	prepare(element, data) {
		const requests = this.prepareInitRequests();
		this.openAsyncEvent('ready');
		this.api
			.execute('multi', {requests: requests})
			.then((response) => {
				if (response.isSuccess()) {
					const responses = response.output.responses;
					const bootResponse = responses.shift();
					if ('account' in bootResponse.output) {
						this.updateAccount(bootResponse.output.account);
						if (this.account.isLoggedIn()) {
							this.triggerLogin();
						}
					}

					if ('lang' in bootResponse.output) {
						this.locale.setDefaultLanguage(bootResponse.output.lang);
					}

					this.prepareForms(element, responses);
					// login is the default form
					this.current = 'login';
					this.classList(this.forms[this.current].block).add(this.currentClass);
					// listen to api calls, looking for responses that require an authenticated account
					this.events.on(this.root, 'api:complete', this.onApiCallComplete.bind(this));
					this.events.on(
						element,
						this.dataSelector('action', 'forgotPassword'),
						'click',
						this.onPasswordForgotClick.bind(this)
					);

					this.events.on(
						this.root,
						this.dataSelector('action', 'login'),
						'click',
						this.onLoginClick.bind(this),
						{capture: true}
					);
					this.events.on(
						this.root,
						this.dataSelector('action', 'logout'),
						'click',
						this.onLogoutClick.bind(this),
						{capture: true}
					);
				}
				this.closeAsyncEvent('ready');
			})
			.catch((error) => {
				this.closeAsyncEvent('ready');
			});
	}

	prepareInitRequests() {
		const requests = [{action: 'account/boot', params: {}}];
		for (const def of this.formDefs) {
			requests.push({action: 'getForm', params: {name: def.def}});
		}
		return requests;
	}

	prepareForms(element, responses) {
		this.forms = {};

		for (let i = 0, end = this.formDefs.length; i < end; i++) {
			const entry = this.formDefs[i];
			if (i < responses.length) {
				const def = responses[i].output.form;
				const item = {};
				this.forms[entry.name] = item;
				item.block = element.querySelector(this.dataSelector('accountBlock', entry.name));
				item.formElement = item.block.querySelector(this.dataSelector('accountForm'));
				item.message = item.block.querySelector(this.dataSelector('accountMessage'));
				item.formElement.innerHTML = this.template.render(entry.name, def);
				item.form = this.queryComponent(this.dataSelector('form', 'account/' + entry.name));
				item.listener = this.events.on(
					element,
					this.dataSelector('form', 'account/' + entry.name),
					'form:submit',
					this['on' + ucFirst(entry.name) + 'FormSubmit'].bind(this)
				);
			}
		}
		return this;
	}

	onLoginClick(event) {
		event.preventDefault();
		this.switchTo('login');
	}

	onLogoutClick(event) {
		event.preventDefault();
		this.resetPendingRequest();
		this.logout();
	}

	onPasswordForgotClick(event) {
		event.preventDefault();
		this.switchTo(
			'forgotPassword',
			{email: this.forms[this.current].form.getField('email').getValue()},
			this.getPendingRequest()
		);
	}

	onLoginFormSubmit(event) {
		const form = this.forms.login.form;
		const values = form.getValue();
		form.disableSubmit();
		this.api
			.execute('account/login', {element: values})
			.then((response) => {
				if (response.isSuccess()) {
					if (!response.isAuthenticated() && 'redirect' in response.output) {
						this.navigation.redirect(response.output.redirect);
						this.close();
					}
				} else {
					this.onLoginNotValid(form, response);
				}
				return response;
			})
			.catch((error) => {
				console.error(error);
			})
			.then(() => {
				form.enableSubmit();
			});
	}

	onForgotPasswordFormSubmit(event) {
		const form = this.forms.forgotPassword.form;
		const values = form.getValue();
		form.disableSubmit();
		this.api
			.execute('account/sendChangePasswordCode', {element: values})
			.then((response) => {
				if (response.isSuccess()) {
					this.onForgotPasswordValid(form, response);
				} else {
					this.onForgotPasswordNotValid(form, response);
				}
				return response;
			})
			.catch((error) => {
				console.log('error handler');
			})
			.then(() => {
				form.enableSubmit();
			});
	}

	onChangePasswordFormSubmit(event) {
		const form = this.forms.changePassword.form;
		const values = form.getValue();
		form.disableSubmit();
		this.api
			.execute('account/changePassword', {element: values})
			.then((response) => {
				if (response.isSuccess()) {
					this.onChangePasswordValid(form, response);
				} else {
					this.onChangePasswordNotValid(form, response);
				}
				return response;
			})
			.catch((error) => {
				console.log('error handler');
			})
			.then(() => {
				form.enableSubmit();
			});
	}

	onLoginValid(form, response) {
		this.loginValid(response);
	}

	onLoginNotValid(form, response) {
		if (response.isNotValid()) {
			form.setValueAndErrors(response.output.element);
		} else if (response.isNotAuthenticated()) {
			form.setValueAndErrors({
				fields: {
					password: {errors: [this.locale.get('account/invalidLogin')]},
				},
			});
		}
	}

	onForgotPasswordValid(form, response) {
		this.switchTo(
			'changePassword',
			{email: form.getField('email').getValue()},
			this.getPendingRequest(),
			this.locale.get('account/changePasswordCodeSent')
		);
	}

	onForgotPasswordNotValid(form, response) {
		if (response.isNotValid()) {
			form.setValueAndErrors(response.output.element);
		}
	}

	onChangePasswordValid(form, response) {
		this.switchTo(
			'login',
			{email: form.getField('email').getValue()},
			this.getPendingRequest(),
			this.locale.get('account/passwordChanged')
		);
	}

	onChangePasswordNotValid(form, response) {
		if (response.isNotValid()) {
			form.setValueAndErrors(response.output.element);
		} else if (response.isNotFound()) {
			form.setValueAndErrors({
				fields: {
					changePasswordCode: {
						errors: [this.locale.get('account/invalidChangePassword')],
					},
				},
			});
		}
	}

	onApiCallComplete(event) {
		if (event.defaultPrevented) {
			return;
		}
		const request = event.detail.request;
		const response = request.response;
		if ('output' in response && 'account' in response.output) {
			this.updateAccount(response.output.account);
		}
		if (response.isUnauthorized()) {
			event.preventDefault();
			if (this.pages) {
				this.pages.releaseLock();
			}
			const appLoader = this.getAppLoader();
			if (appLoader) {
				appLoader.hide();
			}
			const pageLoader = this.getPageLoader();
			if (pageLoader) {
				pageLoader.hide();
			}
			this.triggerLogout();
			this.switchTo('login', {}, request);
		} else if (response.isAuthenticated()) {
			this.loginValid(response);
		}
	}

	triggerLogin() {
		return this.triggerAuthenticationEvent('login');
	}

	triggerLogout() {
		return this.triggerAuthenticationEvent('logout');
	}

	triggerAuthenticationEvent(name) {
		this.events.trigger(this.root, 'account:' + name, {
			account: this.account,
		});
		return this;
	}

	isLoggedIn() {
		return this.account.isLoggedIn();
	}

	checkLogin() {
		if (this.account.isLoggedIn()) {
			return Promise.resolve();
		}
		return new Promise((resolve) => {
			this.switchTo('login', {}, resolve);
		});
	}

	loginValid(response) {
		this.updateAccount(response.output.account);
		this.triggerLogin();
		this.close().then(() => {
			if ('redirect' in response.output) {
				this.resetPendingRequest();
				if (response.output.redirect === true) {
					this.redirect();
				} else {
					this.navigation.redirect(response.output.redirect);
				}
			} else {
				this.processPendingRequest();
			}
		});
	}

	updateAccount(info) {
		this.account.update(info);
	}

	logout() {
		return this.api.execute('account/logout', {}).then((response) => {
			if (response.isSuccess()) {
				this.updateAccount({});
				this.triggerLogout();
			}
		});
	}

	close() {
		if (this.contexts.getCurrentContext().getName() === 'account') {
			this.redirectEnabled = false;
			return this.contexts.pop().then(() => {
				this.redirectEnabled = true;
			});
		}
		return Promise.resolve();
	}

	resetPendingRequest() {
		this.pendingRequest = null;
		return this;
	}

	getPendingRequest() {
		return this.pendingRequest;
	}

	setPendingRequest(request) {
		this.pendingRequest = request;
		return this;
	}

	processPendingRequest() {
		if (this.pendingRequest !== null) {
			if (this.pendingRequest instanceof Request) {
				this.pendingRequest.execute();
			} else {
				this.pendingRequest();
			}
			this.resetPendingRequest();
		} else if (this.redirectEnabled) {
			this.redirect();
		}
		return this;
	}

	redirect() {
		this.navigation.redirect(this.isLoggedIn() ? this.loggedDefaultUrl : this.defaultUrl);
	}

	switchTo(name, data = {}, pendingRequest = null, message = null) {
		if (name in this.forms) {
			this.forms[name].form.reset();
			for (const fieldName in data) {
				if (data.hasOwnProperty(fieldName) && this.forms[name].form.hasField(fieldName)) {
					this.forms[name].form.getField(fieldName).setValue(data[fieldName]);
				}
			}
			if (this.current !== name) {
				this.updateMessage(name, message);
				const parentNode = this.forms[this.current].block.parentNode;
				const height = Math.max(
					this.forms[this.current].block.getBoundingClientRect().height,
					this.forms[name].block.getBoundingClientRect().height
				);
				if (height > 0) {
					parentNode.style.height = height + 'px';
				}
				this.classList(this.forms[this.current].block).remove(this.currentClass);
				this.classList(this.forms[name].block).add(this.currentClass);
				window.scrollTo(0, 0);
				this.onTransitionEnd(this.forms[name].block).then(() => {
					this.current = name;
					parentNode.style.removeProperty('height');
				});
			}
			this.setPendingRequest(pendingRequest);
			if (!this.contexts.getCurrentContext().getName() !== 'account') {
				this.contexts.push('account');
			}
		}
		return this;
	}

	updateMessage(name, content) {
		if (this.forms[name].message) {
			this.forms[name].message.innerHTML =
				content === null ? '' : '<p>' + this.template.escape(content) + '</p>';
		} else if (content !== null && content !== '') {
			this.notify({type: 'info', text: content});
		}
	}
}

export default AccountManager;
