export default function (button, attributes = {}, data = {}, labelData = {}) {
	let buttonClasses = ['button'];
	if ('modifier' in button) {
		const modifiers = (Array.isArray(button.modifier) ? button.modifier : [button.modifier]);
		buttonClasses = buttonClasses.concat(modifiers.map((modifier) => 'button--' + modifier));
	}
	attributes = Object.assign({}, attributes);
	data = Object.assign({}, ('data' in button ? button.data : {}), data);
	if ('title' in attributes) {
		attributes.title = this.text.print(attributes.title);
	}
	const tagName = ('href' in attributes ? 'a' : 'button');
	if (tagName === 'button' && !('type' in attributes)) {
		attributes.type = 'button';
	}
	if (!('label' in button)) {
		buttonClasses.push('button--onlyIcon');
	} else if(!('preIcon' in button) && !('postIcon' in button)) {
		buttonClasses.push('button--onlyLabel');
	}
	return this.html.element({tag: tagName, classes: buttonClasses, attributes: attributes, data: data, content:
		('preIcon' in button ? this.html.element({tag: 'span', classes: ['button__icon', 'button__icon--pre'], content: this.html.svgIcon(button.preIcon)}) : '') +
		('label' in button ? this.html.element({tag: 'span', classes: ['button__label'], content: this.escape(this.text.print(button.label)), data: labelData}) : '') +
		('postIcon' in button ? this.html.element({tag: 'span', classes: ['button__icon', 'button__icon--post'], content: this.html.svgIcon(button.postIcon)}) : '')
	});
}
