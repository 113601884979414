import PrintBtn from './print-btn';


const config = (di) => {
	di
		.setType({type: PrintBtn, name: 'PrintBtn', parent: 'PageComponent', params: {
		}})
	;
};


export default config;
