import TemplateHelper from './template-helper';


class IdHelper extends TemplateHelper {

	constructor({prefix = 'id_'}) {
		super();
		this.counter = 1;
		this.prefix = prefix;
		this.lastId = null;
	}


	create(prefix = undefined) {
		prefix = (prefix === undefined ? this.prefix : prefix);
		this.lastId = prefix + (this.counter++);
		return this.lastId;
	}


	last() {
		return this.lastId;
	}

}

export default IdHelper;
