import Api from './api';
import responsePayloadMixin from './response-payload-mixin';
import apiMixin from './api-mixin';
import Request from './request';
import ApiListener from './api-listener';


export default (di) => {
	di
		.setType({type: Api, name: 'Api', params: {
				events: di.lazyGet('events/events'),
				requestFactory: di.lazyGet('api/requestFactory'),
				baseUrl: di.lazyValue('api/baseUrl', '')
			}
		})
		.setType({type: Request, name: 'Request'})
		.set('api/requestFactory', di.newFactory('Request'))
		.setMixin({mixin: responsePayloadMixin, name: 'responsePayloadMixin'})

		.setMixin({mixin: apiMixin, name: 'apiMixin', setters: {
				injectApi: di.lazyGet('api/api')
			}
		})
		.setType({type: ApiListener, name: 'ApiListener', mixins: ['localeMixin', 'dialogMixin', 'notificationsMixin'], params: {
			events: di.lazyGet('events/events')
			}
		})
		.set('api/api', di.lazyNew('Api'))
	;
};
