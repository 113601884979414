import PageComponent from '../component/page-component';
import fieldsGroupMixin from './fields-group-mixin';
import discoverFieldsMixin from './discover-fields-mixin';


class Form extends discoverFieldsMixin(fieldsGroupMixin(PageComponent)) {

	constructor({
		root,
		element,
		async = false,
		autoAddFields = true,
	}) {
		super({root: root, element: element, async: async});
		this.autoAddFields = autoAddFields;
		this.submitEnabled = true;
	}



	prepare(element, data) {
		this.listeners.submit = this.events.on(element, 'submit', this.onSubmit.bind(this));
		if (this.autoAddFields) {
			this.discoverFields(element);
		}
	}


	focus() {
		if (this.fields) {
			for (const field of this.fields.values()) {
				if (field.canFocus()) {
					field.focus();
					break;
				}
			}
		}
		return this;
	}


	disableSubmit() {
		this.submitEnabled = false;
		return this;
	}


	enableSubmit() {
		this.submitEnabled = true;
		return this;
	}


	onSubmit(event) {
		event.preventDefault();
		if (
			document.activeElement &&
			document.activeElement.tagName.toLowerCase() === 'button' &&
			document.activeElement.hasAttribute('type') &&
			document.activeElement.getAttribute('type') === 'submit'
		) {
			document.activeElement.blur();
		}
		if (this.submitEnabled) {
			this.events.trigger(this.element, 'form:submit', {wrapper: this});
		}
	}

}


export default Form;
