import InteractiveField from './interactive-field';


class RadioField extends InteractiveField {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelectorAll('input');
		}
		return this.input;
	}


	writeValue(value) {
		value = String(value);
        for (const input of this.getInput()) {
			input.checked = (input.value === value);
        }
	}


	readValue() {
		const checked = this.element.querySelector('input:checked');
        return (checked? checked.value : '');
	}


	setFocus() {
		this.getInput()[0].focus();
	}


	setBlur() {
		for (const input of this.getInput()) {
			input.blur();
        }
	}


	enableInput() {
		for (const input of this.getInput()) {
			input.disabled = false;
		}
	}


	disableInput() {
		for (const input of this.getInput()) {
			input.disabled = true;
		}
	}


}


export default RadioField;
