export default function(entry) {
	return this.html.element({
		tag: 'input',
		attributes: Object.assign({}, entry.attributes, {
			type: 'hidden',
			name: entry.inputName,
			value: entry.default,
			id: entry.id
		}),
		data: Object.assign({component: 'hiddenField', field: entry.name, fieldType: 'hidden'}, 'data' in entry ? entry.data : {})
	});
}
