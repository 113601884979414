import PageComponent from '../../common/component/page-component';


class AppLoader extends PageComponent {

    show(options = {}) {
        if (this.classList(this.element).contains('loading')) {
			return Promise.resolve(this.element);
        }
        const classes = ['loading'];
        if (options.cover) {
            classes.push('cover');
        }
		this.classList(this.element).add(...classes);
		return this.onTransitionEnd(this.element);
    }


    hide(options = {}) {
		this.classList(this.root).remove('noscroll');
		if (!this.classList(this.element).contains('loading')) {
			return Promise.resolve(this.element);
        }
		this.classList(this.element).remove('loading', 'cover');
		return this.onTransitionEnd(this.element);
    }

}

export default AppLoader;
