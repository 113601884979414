export default function (name, params, classes, form) {
	this.wrap('page/wrapper', name, params, classes);
	return `
	<div ${this.html.classAttr('block__pageTitle')}>
		<h2 ${this.html.dataAttr({dynamicTranslateId: 'account/registerTitle', dynamicTranslatePageTitle: 'account/registerTitle'})}>${this.text.get('account/registerTitle')}</h2>
	</div>
	<div ${this.html.classAttr('block__text')}>
		<p>
			Sollten Sie keinen Freischaltungs-Code erhalten, kontaktieren Sie uns bitte unter <a href="mailto:medienpreis@land-der-ideen.de">medienpreis@land-der-ideen.de</a>.
		</p>
	</div>
	<div ${this.html.classAttr('block__text')}>
		<p ${this.html.dataAttr({dynamicTranslateId: 'account/registerDescription'})}>${this.text.get('account/registerDescription')}</p>
	</div>

	<div ${this.html.classAttr('page__form')}>
		${this.render(
			'form/form',
			form,
			'user/register',
			['form--register'],
			{}
		)}
	</div>
	`;
}
