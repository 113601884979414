import autosize from 'autosize';
import InteractiveField from './interactive-field';
import textFieldMixin from './text-field-mixin';


class TagsField extends textFieldMixin(InteractiveField) {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('textarea');
			autosize(this.input);
		}
		return this.input;
	}


	writeValue(value) {
		if (!Array.isArray(value) && value.length && value.charAt(0) === '[' && value.charAt(value.length - 1) === ']') {
			value = JSON.parse(value);
		}
		if (Array.isArray(value)) {
			value = value.filter((item) => item.length);
			value = value.join(', ');
		}
		super.writeValue(value);
		autosize.update(this.getInput());
		return this;
	}


	readValue() {
		let value = super.readValue();
		if (!Array.isArray(value)) {
			value = value.split(/[\s\n\r\t]*,[\s\n\r\t]*/i).filter((item) => item.length);
		}
		return value;
	}

}


export default TagsField;
