export default function (entry) {
	if ('data' in entry && 'groupType' in entry.data && entry.data.groupType === 'image') {
		return this.render('form/field/groupImage', entry);
	}
	if ('default' in entry) {
		for (const name in entry.default) {
			if (entry.default.hasOwnProperty(name) && name in entry.fields) {
				entry.fields[name].default = entry.default[name];
			}
		}
	}
	return `
		${this.render('form/field/label', entry)}
		${this.render('form/fields', entry.fields, 'field')}
	`;
}
