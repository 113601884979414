import {getScrollTop} from 'get-scroll';
import {isObject} from '../utils/types';
import domMixin from '../dom/dom-mixin';
import contextsMixin from '../context/contexts-mixin';
import navigationMixin from '../navigation/navigation-mixin';
import componentsMixin from '../component/components-mixin';


class PageTransition extends navigationMixin(componentsMixin(contextsMixin(domMixin()))) {

	constructor({pageLoader}) {
		super();
		this.pageLoader = pageLoader;
	}


	injectSlots(slots) {
		this.slots = slots;
	}


	injectSectionSlots(sectionSlots) {
		this.sectionSlots = sectionSlots;
	}


	injectHistory(history) {
		this.history = history;
	}


	execute(request) {
		return Promise.resolve().then(() => {
			if (request.navigationType === 'link' || request.navigationType === 'redirect') {
				this.history.mergeState({title: document.title});
			}

			const type = request.navigationType;
			return Promise.resolve(request).then(() =>
				(type !== 'current' ? this.beginAnimation(request) : Promise.resolve(request))
			).then(() => {
				const currentDefaultPage = this.slots.get('default').getCurrentPage();
				if (currentDefaultPage) {
					request.refererUrl = currentDefaultPage.getUrl();
				}
				return this.pageLoader.load(request);
			}).then((response) => {
				if (response !== false) {
					if (type !== 'current') {
						if (type === 'link' || type === 'redirect') {
							this.storeScrollPos(request, response);
							this.updateHistory(request, response);
						} else {
							this.updateCurrentHistory(request, response);
						}
					} else if (isObject(response) && 'title' in response) {
						document.title = response.title;
					}
					this.sectionSlots.emptyPageBoundSlots();
					if ('sections' in response) {
						for (const sectionName in response.sections) {
							if (response.sections.hasOwnProperty(sectionName)) {
								this.sectionSlots.fillSection(sectionName, response.sections[sectionName]);
							}
						}
					}
					const pagePromises = [];
					for (const newPage of response.pages) {
						pagePromises.push(Promise.resolve().then(() => {
							const oldPage = newPage.getPageSlot().getCurrentPage();
							this.attachNewPage(newPage, oldPage, request, response);
							const promises = [newPage.onLoad()];
							if (oldPage) {
								promises.push(oldPage.onUnload());
							}
							return Promise.all(promises)
								.then(() => this.swapPages(newPage, oldPage, request, response))
								.then(() => {
									if (oldPage) {
										this.events.trigger(oldPage.getElement(), 'page:unload', {page: oldPage});
									}
									this.events.trigger(newPage.getElement(), 'page:load', {page: newPage});
									newPage.setAsCurrent();
								})
								;
						}));
					}
					return Promise.all(pagePromises).then(() => response);
				}
				return response;
			}).then((response) => {
				if ((response !== false)) {
					if (type !== 'current' && response !== false) {
						return Promise.resolve(response)
							.then(() => {
								this.commit(request, response);
								return this.endAnimation(request, response);
							})
							;
					}
					return response;
				}
				return false;
			});
		});
	}


	attachNewPage(newPage, oldPage, request, response) {
		newPage.attach();
	}


	swapPages(newPage, oldPage, request, response) {
		return Promise.resolve();
	}


	commit(request, response) {
		this
			.updateScrollPos(request, response)
			.storeScrollPos(request, response)
			.updateDocumentTitle(request, response)
			.raisePageChangeEvent(request, response)
			.raiseHashChangeEvent(request, response)
			;
	}


	raisePageChangeEvent(request, response) {
		this.events.trigger(document, 'history:pagechange', {url: location.href, title: document.title});
		return this;
	}


	raiseHashChangeEvent(request, response) {
		if (request.event.detail.hash !== null) {
			this.events.trigger(document, 'history:hashchange', {hash: request.event.detail.hash});
		}
		return this;
	}


	updateHistory(request, response) {
		let url = request.url;
		if (request.event.detail.hash !== null) {
			url += '#' + request.event.detail.hash;
		}

		const title = isObject(response) && 'title' in response ? response.title : null;
		this.history.push(url, {title: title}, title);
		return this;
	}


	updateCurrentHistory(request, response) {
		return this;
	}


	updateScrollPos(request, response) {
		window.scrollTo(0, (request.state && ('scrollTop' in request.state)) ? request.state.scrollTop : 0);
		return this;
	}


	storeScrollPos(request, response) {
		this.history.mergeState({scrollTop: getScrollTop()});
		return this;
	}


	updateDocumentTitle(request, response) {
		if (isObject(response) && 'title' in response) {
			document.title = response.title;
		} else if (request.navigationType === 'pop' && 'title' in request.state) {
			document.title = request.state.title;
		}
		return this;
	}


	beginAnimation(request) {
		return Promise.resolve(request);
	}


	endAnimation(request, response) {
		return Promise.resolve(response);
	}

}


export default PageTransition;
