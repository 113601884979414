import Context from '../../common/context/context';
import {trimUrl} from '../../common/utils/string';


class OverlayContext extends Context {

	constructor({name, root, popOnKey = 'esc', inClass = 'active', modifierSelector = 'overlayModifier'}) {
		super({name: name, root: root, popOnKey: popOnKey});
		this.inClass = inClass;
		this.modifierSelector = modifierSelector;
	}


	injectNavigation(navigation) {
		this.navigation = navigation;
	}


	injectSlots(slots) {
		this.slots = slots;
	}


	setEvents(events) {
		this.events.on(this.getElement(), 'click', this.onClick.bind(this));
		this.events.on(this.getElement(), this.dataSelector('overlayClose'), 'click', this.onClickClose.bind(this));
	}

	onClickClose(event) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();

		this.contexts.pop();
	}


	onClick(event) {
		const element = this.getElement();
		if (event.target === element || event.target === element.querySelector(this.dataSelector('pageSlot'))) {
			this.contexts.pop();
		}
	}


	switchIn(otherContext) {
		const element = this.getElement();
		const modifierNode = element.querySelector(this.dataSelector(this.modifierSelector));
		if (modifierNode) {
			const modifier = this.dataAttr(modifierNode).get(this.modifierSelector);
			this.classList(element).add(modifier);
		}
		return this.threeStateTransition(element).add(this.inClass);
	}


	switchOut(otherContext) {
		const element = this.getElement();
		const currentDefaultPage = this.slots.get('default').getCurrentPage();
		if (currentDefaultPage && currentDefaultPage.getUrl() !== trimUrl(location.href.split('?')[0])) {
			const url = currentDefaultPage.getUrl();
			const title = currentDefaultPage.getTitle();
			this.navigation.push(url, {title: title}, title);
			this.events.trigger(document, 'history:pagechange', {url: url, title: title});
		}
		return this.threeStateTransition(element).remove(this.inClass).then(() => {
			const modifierNode = element.querySelector(this.dataSelector(this.modifierSelector));
			if (modifierNode) {
				const modifier = this.dataAttr(modifierNode).get(this.modifierSelector);
				this.classList(element).remove(modifier);
			}
			this.slots.get('overlay').unsetCurrentPage();
			return otherContext;
		});
	}

}


export default OverlayContext;
