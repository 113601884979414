import accountConfig from '../../common/account/_config';
import animationConfig from '../../common/animation/_config';
import apiConfig from '../../common/api/_config';
import appConfig from '../../common/app/_config';
import componentConfig from '../../common/component/_config';
import contextConfig from '../../common/context/_config';
import cookieConfig from '../../common/cookie/_config';
import dialogConfig from '../../common/dialog/_config';
import diConfig from '../../common/di/_config';
import domConfig from '../../common/dom/_config';
import eventsConfig from '../../common/events/_config';
import formConfig from '../../common/form/_config';
import localeConfig from '../../common/locale/_config';
import mediaConfig from '../../common/media/_config';
import navigationConfig from '../../common/navigation/_config';
import notificationsConfig from '../../common/notifications/_config';
import pageConfig from '../../common/page/_config';
import pankowConfig from '../../common/pankow/_config';
import preloadConfig from '../../common/preload/_config';
import routerConfig from '../../common/router/_config';
import searchConfig from '../../common/search/_config';
import slideshowConfig from '../../common/slideshow/_config';
import templateConfig from '../../common/template/_config';
import widgetConfig from '../../common/widget/_config';

const config = (di) => {
	accountConfig(di);
	animationConfig(di);
	apiConfig(di);
	appConfig(di);
	componentConfig(di);
	contextConfig(di);
	cookieConfig(di);
	dialogConfig(di);
	diConfig(di);
	domConfig(di);
	eventsConfig(di);
	formConfig(di);
	localeConfig(di);
	mediaConfig(di);
	navigationConfig(di);
	notificationsConfig(di);
	pageConfig(di);
	pankowConfig(di);
	preloadConfig(di);
	routerConfig(di);
	searchConfig(di);
	slideshowConfig(di);
	templateConfig(di);
	widgetConfig(di);
};

export default config;
