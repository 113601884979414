export default function (field) {
	if (field.type === 'tags') {
		if (Array.isArray(field.value)) {
			return this.escape(field.value.join(', '));
		}
	} else if(field.type === 'file') {
		if (field.value && 'size' in field.value && field.value.size) {
			let originalName = field.value.originalName;
			if (originalName.length > 40) {
				originalName = originalName.substr(0, 37) + '...';
			}
			let out = `<p>
				<a ${this.html.classAttr('link')} href="${this.url.getMediaUrl(field.value.path)}" target="_blank">${this.escape(originalName)}</a><br />
				${this.escape(field.value.ext.toUpperCase())} ${this.escape(this.string.fileSize(field.value.size))}
			</p>`;
			if (field.value.mime.indexOf('audio/') === 0 || field.value.originalName.toLowerCase().substr(-4) === '.mp3') {
				out += `
				<audio ${this.html.classAttr('audioPlayer')} controls preload="metadata" src="${this.url.getMediaUrl(field.value.path)}">
				</audio>`;
			}
			return out;
		}
		return '';
	}
	return this.string.nl2br(this.escape(field.value));
}
