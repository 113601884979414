export default function tpl(tags, params, limit, selected) {
	return `
		<ul ${this.html.classAttr('tagsList__items', 'tagsList__items--' + (selected ? 'selected' : 'unselected'))} ${this.html.dataAttr({tagsList: selected ? 'selected' : 'unselected'})}>
			${this.loop(tags, (tag, index) => `
				<li ${this.html.classAttr('tagsList__item', (limit !== null && index >= limit ? 'js-hidden' : ''), (selected ? 'tagsList__item--selected' : ''))} ${this.html.dataAttr({tagItem: 1})}>
					${this.render('tag', Object.assign({}, tag, {
						selected: selected,
						label: tag.tag,
						postIcon: (selected ? 'close' : 'add'),
						classes: ['tag--colorSchemeInverted'],
						data: {tagId: tag.id}
					}))}
				</li>
			`)}
			${limit !== null && tags.length > limit ? `
				<li ${this.html.classAttr('tagsList__item')} ${this.html.dataAttr({toggleTags: 'more'})}>
					${this.render('tag', {
						selected: false,
						label: params.texts.moreLabel,
						postIcon: 'arrow-to-right',
						classes: ['tag--colorSchemeInverted']
					})}
				</li>

				<li ${this.html.classAttr('tagsList__item', 'js-hidden')} ${this.html.dataAttr({toggleTags: 'less'})}>
					${this.render('tag', {
						selected: false,
						label: params.texts.lessLabel,
						preIcon: 'arrow-to-left',
						classes: ['tag--colorSchemeInverted']
					})}
				</li>
			` : ''}
		</ul>
	`;
}
