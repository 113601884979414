import escape from './escape';
import TemplateHelper from './template-helper';
import {isObject, isString, isFunction} from '../utils/types';


class Template {

    constructor({tpls = {}}) {
		this.helpers = {};
		this.h = {};
		this.tpls = tpls;
		this.wrapperStack = [];

		this.formatters = {};
    }


	registerTpl(name, tpl) {
		this.tpls[name] = tpl;
	}


	registerTpls(tpls) {
		this.tpls = Object.assign(this.tpls, tpls);
		return this;
	}


	registerHelper(name, helper) {
		if (helper instanceof TemplateHelper) {
			helper.injectTemplate(this);
		}
		this.helpers[name] = helper;
		this.h[name] = helper;

		if (!(name in this)) {
			this[name] = helper;
		}
		return this;
	}


	registerHelpers(helpers) {
		for (const name in helpers) {
			if (helpers.hasOwnProperty(name)) {
				this.registerHelper(name, helpers[name]);
			}
		}
		return this;
	}


	registerFormatter(name, callback) {
		this.formatters[name] = callback;
		return this;
	}


	registerFormatters(formatters) {
		for (const name in formatters) {
			if (formatters.hasOwnProperty(name)) {
				this.registerFormatter(name, formatters[name]);
			}
		}
		return this;
	}


	format(name, value, ...params) {
		if (!(name in this.formatters)) {
			throw new Error('Formatter ' + name + ' not registered');
		}
		return this.formatters[name].call(this, value, ...params);
	}


	helper(name) {
		if (name in this.helpers) {
			return this.helpers[name];
		}
		throw new Error('Helper ' + name + ' not defined');
	}


    render(tpl, ...params) {
		tpl = this.getTpl(tpl);
		const wrapperLevel = this.wrapperStack.length;
		let output = tpl.bind(this)(...params);
		if (this.wrapperStack.length > wrapperLevel) {
			const wrapperEntry = this.wrapperStack.pop();
			const wrapperTpl = this.getTpl(wrapperEntry.tpl);
			const wrapperParams = wrapperEntry.params;
			wrapperParams.unshift(output);
			output = wrapperTpl.bind(this)(...wrapperParams);
		}
		return output;
	}


	wrap(tpl, ...params) {
		this.wrapperStack.push({tpl: tpl, params: params});
		return this;
	}


	getTpl(tpl) {
		if (isString(tpl)) {
			if (!(tpl in this.tpls)) {
				throw new Error('Template ' + tpl + ' not registered');
			}
			tpl = this.tpls[tpl];
		}
		if (!(tpl instanceof Function)) {
			throw new Error('The provided Tpl is not a function');
		}
		return tpl;
	}


    escape(value, options = {}) {
		return escape(value, options);
    }


	e(value, options = {}) {
		return escape(value, options);
	}


	loop(iterable, callback, separator = '') {
		let result = '';
		if (Array.isArray(iterable)) {
			result = iterable.map(callback, this).join(separator);
		} else if ((iterable instanceof Map) || (iterable instanceof Set)) {
			let first = true;
			for (const [index, value] of iterable) {
				result += (first ? '' : separator) + callback.call(this, value, index, iterable);
				first = false;
			}
		} else if (isObject(iterable)) {
			let first = true;
			for (const index in iterable) {
				if (iterable.hasOwnProperty(index)) {
					result += (first ? '' : separator) + callback.call(this, iterable[index], index, iterable);
				}
				first = false;
			}
		} else {
			console.error('not iterable', iterable);
			throw new Error('An iterable object is required by loop method');
		}
		return result;
	}


	map(iterable, callback, recursive = false) {
		if (Array.isArray(iterable)) {
			if (recursive) {
				const result = [];
				for (let i = 0, end = iterable.length; i < end; i++) {
					result[i] = this.isIterable(iterable[i]) ?
						this.map(iterable[i], callback, true) :
						callback.call(this, iterable[i], i, iterable)
					;
				}
				return result;
			}
			return iterable.map(callback, this);
		}
		if (iterable instanceof Map) {
			const result = new Map();
			for (const [index, value] of iterable) {
				result.set(index, (recursive && this.isIterable(value)) ?
					this.map(value, callback, true) :
					callback.call(this, value, index, iterable)
				);
			}
			return result;
		}
		if (iterable instanceof Set) {
			const result = new Set();
			for (const [index, value] of iterable) {
				result.add((recursive && this.isIterable(value)) ?
					this.map(value, callback, true) :
					callback.call(this, value, index, iterable)
				);
			}
			return result;
		}
		if (isObject(iterable)) {
			const result = {};
			for (const index in iterable) {
				if (iterable.hasOwnProperty(index)) {
					result[index] = (recursive && this.isIterable(iterable[index])) ?
						this.map(iterable[index], callback, true) :
						callback.call(this, iterable[index], index, iterable)
					;
				}
			}
			return result;
		}
		console.error('not iterable', iterable);
		throw new Error('An iterable object is required by loop method');
	}


	isIterable(obj) {
		return (Array.isArray(obj) || obj instanceof Map || obj instanceof Set || isObject(obj));
	}


	cond(condition, thenCallback, elseCallback = '') {
		return (condition ?
			(isFunction(thenCallback) ? thenCallback.call(this) : thenCallback) :
			(isFunction(elseCallback) ? elseCallback.call(this) : elseCallback)
		);
	}

}

export default Template;
