import PageComponent from '../component/page-component';


class Toggler extends PageComponent {

	constructor({
		root,
		element,
		eventType = 'click',
		toggledClass = 'toggled',
		preventDefault = true,
		blurOnToggle = true,
		forAttribute = 'id',
		targetAction = 'toggle',
		dynamicTarget = false,
		beforeToggleEvent = 'toggler:beforetoggle',
		toggleEvent = 'toggler:toggle',
		enabledByCss = false,
		cssEnabledProperty = 'togglerEnabled'
	}) {
		super({root: root, element: element});
		this.defaults.eventType = eventType;
		this.defaults.toggledClass = toggledClass;
		this.defaults.preventDefault = preventDefault;
		this.defaults.blurOnToggle = blurOnToggle;
		this.defaults.forAttribute = forAttribute;
		this.defaults.targetAction = targetAction;
		this.defaults.dynamicTarget = dynamicTarget;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.defaults.enabledByCss = enabledByCss;
		this.defaults.cssEnabledProperty = cssEnabledProperty;
		this.targetSelector = null;
		this.targets = [];
		this.toggled = null; // will be a boolean after prepare()
		this.busy = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		// console.log(this, 'our context');
		// if the for attribute is not available, it fallbacks to the href anchor value
		if ('for' in data) {
			this.targetSelector = this.dataSelector(data.forAttribute, data.for);
		} else if (this.element.hasAttribute('href')) {
			const href = this.element.getAttribute('href');
			if (href.indexOf('#') === 0) {
				this.targetSelector = href;
			}
		}

		this.toggled = this.classList(this.element).contains(data.toggledClass);

		if (this.targetSelector && !data.dynamicTarget) {
			this.targets = this.components.queryComponents(this.root, this.targetSelector);
		}
		this.listeners.toggle = this.events.on(this.element, data.eventType, this.onToggle.bind(this));
	}


	onToggle(event) {
		const data = this.dataAttr().getAll();
		if (data.preventDefault) {
			event.preventDefault();
		}
		if (data.blurOnToggle) {
			this.element.blur();
		}
		if (!data.enabledByCss || this.cssData().get(data.cssEnabledProperty, true)) {
			this.toggle();
		}
	}


	toggle(value = null) {
		const data = this.dataAttr().getAll();
		const originalValue = value;
		return Promise.resolve().then(() => {
			if (this.busy) {
				return false;
			}
			this.busy = true;
			value = (value === null ? !this.toggled : !!value);
			const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, toggled: this.toggled, intention: value});
			if (beforeEvent.defaultPrevented) {
				this.busy = false;
				return false;
			}
			this.classList(this.element).toggle(data.toggledClass, value);
			this.toggled = value;
			if (this.targetSelector) {
				if (data.dynamicTarget) {
					this.targets = this.components.queryComponents(this.root, this.targetSelector);
				}
				const promises = this.targets.map((target) => target[data.targetAction](originalValue));
				if (promises.length) {
					return Promise.all(promises).then(() => true);
				}
			}
			return true;
		}).then((complete) => {
			if (complete) {
				this.busy = false;
				this.events.trigger(this.element, data.toggleEvent, {component: this, toggled: this.toggled});
			}
		});
	}


	isBusy() {
		return this.busy;
	}


	isToggled() {
		return this.toggled;
	}


	getToggledComponents() {
		if (this.targetSelector && this.dataAttr().get('dynamicTarget')) {
			this.targets = this.components.queryComponents(this.root, this.targetSelector);
		}

		return this.targets;
	}

}

export default Toggler;
