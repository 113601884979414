export default function (entry) {
	return `
	${this.render('form/field/label', entry)}
	${this.render('form/field/inputWrapper', this.html.element({
		tag: 'input',
		attributes: Object.assign({}, entry.attributes, {
			type: 'password',
			name: entry.inputName,
			id: entry.id
		}),
		classes: ['field__input', 'field__input--password']
	}))}
`;
}
