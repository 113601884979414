import {TweenMax} from 'gsap';
import 'gsap/ScrollToPlugin';
import {getScrollTop} from '../utils/get-scroll';
import {waitFrame} from '../utils/wait';
import getTransitionDuration from '../utils/get-transition-duration';

import PageComponent from '../component/page-component';


class Collapsable extends PageComponent {

	constructor({
		root,
		element,
		collapsedClass = 'collapsed',
		wrapperAttribute = 'collapsableWrapper',
		removeNegativeMargins = true,
		useCssTransition = true,
		scroll = false,
		scrollDuration = 0.5,
		beforeToggleEvent = 'content:beforeresize',
		toggleEvent = 'content:resize'
	}) {
		super({root: root, element: element});
		this.defaults.collapsedClass = collapsedClass;
		this.defaults.wrapperAttribute = wrapperAttribute;
		this.defaults.removeNegativeMargins = removeNegativeMargins;
		this.defaults.useCssTransition = useCssTransition;
		this.defaults.scroll = scroll;
		this.defaults.scrollDuration = scrollDuration;
		this.defaults.beforeToggleEvent = beforeToggleEvent;
		this.defaults.toggleEvent = toggleEvent;
		this.collapsed = null; // will change to a boolean after prepare()
		this.wrapper = null;
		this.busy = false;
	}


	prepare() {
		const data = this.dataAttr().getAll();
		if (data.wrapperAttribute in data) {
			this.wrapper = this.root.querySelector(this.dataSelector('id', data.wrapperAttribute));
		}
		if (!this.wrapper) {
			this.wrapper = this.element.parentNode;
		}

		this.collapsed = this.classList(this.element).contains(data.collapsedClass);
	}


	collapse() {
		return this.toggle(true);
	}


	expand() {
		return this.toggle(false);
	}


	toggle(value = null) {
		if (this.busy) {
			return Promise.resolve();
		}
		this.busy = true;
		const data = this.dataAttr().getAll();
		value = value === null ? !this.collapsed : !!value;
		const beforeEvent = this.events.trigger(this.element, data.beforeToggleEvent, {component: this, collapsed: this.collapsed, intention: value});
		if (beforeEvent.defaultPrevented) {
			this.busy = false;
			return Promise.resolve();
		}
		const diff = this.getWrapperHeightDiff(value, data.removeNegativeMargins);
		let promise;
		if (data.useCssTransition) {
			promise = waitFrame().then(() => {
				this.wrapper.style.height = diff.currentHeight + 'px';
				return waitFrame();
			}).then(() => {
				this.wrapper.style.height = diff.newHeight + 'px';
				this.classList(this.element).toggle(data.collapsedClass, value);
				this.collapsed = value;
				if (this.collapsed) {
					let scroll = data.scroll;
					if (scroll) {
						if (scroll === true) {
							scroll = this.content.getBoundingClientRect().top;
						}
						scroll += getScrollTop();
						TweenMax.to(window, data.scrollDuration, {scrollTo: {y: scroll, autoKill: true}});
					}
				}
				return this.onTransitionEnd(this.wrapper);
			});
		} else {
			promise = new Promise((resolve) => {
				TweenMax.fromTo(
					this.wrapper,
					this.getDuration(),
					{height: diff.currentHeight + 'px'},
					{transition: 'none', height: diff.newHeight + 'px', onComplete: () => resolve()}
				);
				this.classList(this.element).toggle(data.collapsedClass, value);
				this.collapsed = value;
			});
		}
		return promise.then(() => {
			this.wrapper.style.removeProperty('height');
			this.wrapper.style.removeProperty('transition');
			this.events.trigger(this.element, data.toggleEvent, {component: this, collapsed: this.collapsed});
			this.busy = false;
		});
	}


	isBusy() {
		return this.busy;
	}


	isCollapsed() {
		return this.collapsed;
	}


	getNodeHeight(element, removeNegativeMargins = false) {
		let height = element.getBoundingClientRect().height;
		if (removeNegativeMargins) {
			const style = getComputedStyle(element);
			const topMargin = parseFloat(style.marginTop);
			if (!isNaN(topMargin) && topMargin < 0) {
				height += topMargin;
			}
			const bottomMargin = parseFloat(style.marginBottom);
			if (!isNaN(bottomMargin) && bottomMargin < 0) {
				height += bottomMargin;
			}
		}
		return height;
	}


	getWrapperHeightDiff(value, removeNegativeMargins = null) {
		if (removeNegativeMargins === null) {
			removeNegativeMargins = this.dataAttr().get('removeNegativeMargins');
		}
		const currentWrapperHeight = this.getNodeHeight(this.wrapper, removeNegativeMargins);
		const contentHeight = this.getNodeHeight(this.element, removeNegativeMargins);
		const newWrapperHeight = Math.max(0, currentWrapperHeight + (value ? -contentHeight : contentHeight));
		return {currentHeight: currentWrapperHeight, newHeight: newWrapperHeight};
	}


	getDuration(element = null, seconds = true) {
		if (!element) {
			element = this.wrapper;
		}

		return getTransitionDuration(element, null, true, seconds);
	}


	enableCssTransition() {
		this.dataAttr().set('useCssTransition', true);
	}


	disableCssTransition() {
		this.dataAttr().set('useCssTransition', false);
	}

}


export default Collapsable;
