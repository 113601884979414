import InteractiveField from './interactive-field';


class CheckboxField extends InteractiveField {

	getInput() {
		if (!this.input) {
			this.input = this.element.querySelector('input[type="checkbox"]');
		}
		return this.input;
	}


	writeValue(value) {
		value = parseInt(value, 10);
		this.getInput().checked = !!value;
	}


	readValue() {
		return this.getInput().checked ? 1 : 0;
	}


	setFocus() {
		this.getInput().focus();
	}


	setBlur() {
		this.getInput().blur();
	}


	enableInput() {
		this.getInput().disabled = false;
	}


	disableInput() {
		this.getInput().disabled = true;
	}


}


export default CheckboxField;
