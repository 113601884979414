export default function (actions, entry) {
	return `
		<div ${this.html.classAttr(['field__actions', 'field__actions--' + entry.type])} ${this.html.dataAttr({fieldActions: null})}>
			<ul ${this.html.classAttr('field__actionsItems')}>
				${this.loop(actions || [], (action, actionName) => `
					<li ${this.html.classAttr('field__actionItem', 'field__actionItem--' + actionName)}>
						${this.render('action', action)}
					</li>
				`)}
			</ul>
		</div>
	`;
}
