import PageComponent from '../component/page-component';


class TagsVisibilityToggler extends PageComponent {

	constructor({element, root, hiddenClass = 'hidden', tagSelector = 'tagItem'}) {
		super({element: element, root: root});
		this.hiddenClass = hiddenClass;
		this.tagSelector = tagSelector;
	}


	prepare(element, data) {
		this.limit = data.get('limit');
		this.listeners.toggle = this.events.on(element, this.dataSelector('toggleTags'), 'click', this.onToggle.bind(this));
		return Promise.resolve();
	}


	getLimit() {
		return this.limit;
	}


	onToggle(event, target) {
		const action = this.dataAttr(target).get('toggleTags');
		const parent = target.parentNode;
		let tags;
		if (action === 'more') {
			tags = parent.querySelectorAll(this.classSelector(this.hiddenClass) + this.dataSelector(this.tagSelector));
		} else {
			tags = parent.querySelectorAll(this.dataSelector(this.tagSelector) + ':nth-child(n + ' + (this.limit + 1) + ')');
		}
		for (const tag of tags) {
			this.classList(tag).toggle(this.hiddenClass);
		}
		const togglers = parent.querySelectorAll(this.dataSelector('toggleTags'));
		for (const toggle of togglers) {
			this.classList(toggle).toggle(this.hiddenClass);
		}
	}


}


export default TagsVisibilityToggler;
