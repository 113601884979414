export default function tpl(items) {
	return `
		${this.cond(items.length > 1, `
			<nav ${this.html.classAttr('pagination')}>
				<ul ${this.html.classAttr('pagination__list')}>
					${this.loop(items, (item) => `
						${this.cond(item.isPrev || item.isNext, `
							<li ${this.html.classAttr('pagination__listElem', 'pagination__listElem--arrow')}>
								<button type="button" ${this.html.classAttr('pagination__tile', 'button')} ${this.html.dataAttr({toPage: item.page})}>
									<span ${this.html.classAttr('button__icon', item.isPrev ? 'button__icon--flipH' : '')}>
										${this.html.svgIcon('arrow-to-right')}
									</span>
								</button>
							</li>
						`, this.cond(item.isSeparator, `
							<li ${this.html.classAttr('pagination__listElem')}>
								<span ${this.html.classAttr('pagination__tileSpacer', 'button')}>
									<span ${this.html.classAttr('button__label')}>…</span>
								</span>
							</li>
						`, `
							<li ${this.html.classAttr('pagination__listElem', item.isCurrent ? 'pagination__listElem--current' : '')}>
								<${item.isCurrent ? 'span' : 'button'} ${this.html.classAttr('pagination__tile', 'button', item.isCurrent ? 'pagination__tile--current' : '')} ${!item.isCurrent ? this.html.dataAttr('toPage', item.page) : ''}>
									<span ${this.html.classAttr('button__label')}>${this.escape(item.page)}</span>
								</${item.isCurrent ? 'span' : 'button'}>
							</li>
						`))}
					`)}
				</ul>
			</nav>
		`)}
	`;
/*
</ul>
*/
}
